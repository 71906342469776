import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

@inject('userStore')
class DrugSubstanceModalTestEntry extends Component {
  constructor(props){
    super(props);
    this.state = {
      ds:null,
      drug_substance_test:{id:null, hash_id:null, bom_product_id:null, test_date:new Date(), sys_test_id:null, reported_result:'', is_complete:null},
      sys_test:null,
      testResults:[]
    }
  }

  componentDidMount(){
    if (this.props.drug_substance_test){
      this.setState({
        drug_substance_test:this.props.drug_substance_test,
        testResults:this.props.drug_substance_test.drug_substance_test_result_list
      }, ()=>{this.loadTest(this.state.drug_substance_test.sys_test_id);});// system test id = 1 - blood test results
    } else {
      let updated_obj = Object.assign({}, this.state.drug_substance_test);
      updated_obj.bom_product_id = this.props.ds.id;
      updated_obj.sys_test_id = 4;
      this.setState({
        ds:this.props.ds,
        drug_substance_test:updated_obj
      }, ()=>{this.loadTest(4);});// system test id = 1 - blood test results
    }
  }

  loadTest = (sys_test_id) => {
    connectionGet('tests/' + sys_test_id, null, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          sys_test:res.data.sys_test
        });
      }
    })
    .catch((error)=>{console.log('error')});
  }

  addTestResult = (isComplete) => {
    let drug_substance_test = this.state.drug_substance_test;
    let testResults = this.state.testResults;
    let ds = this.state.ds;
    if (drug_substance_test.test_date){
      drug_substance_test.test_date = moment(drug_substance_test.test_date).format('YYYY-MM-DD');
    }
    if (isComplete === true){
      drug_substance_test.is_complete = 1;
    }
    if (!drug_substance_test.hash_id){
      connectionPost('drug-substance-test-results/' + ds.hash_id, {drug_substance_test:drug_substance_test, testResults:testResults}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.props.modalBackgroundClicked();
          alert('Test Results Saved')
        }
      })
      .catch((error)=>{console.log(error)});
    } else {
      connectionPut('drug-substance-test-results/' + ds.hash_id + '/' + drug_substance_test.hash_id, {drug_substance_test:drug_substance_test, testResults:testResults, is_complete:isComplete}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.props.modalBackgroundClicked();
          alert('Test Results Saved')
        }
      })
      .catch((error)=>{console.log(error);});
    }
  }

  handleDateChange = (date) => {
    let updated_obj = Object.assign({}, this.state.drug_substance_test);
    updated_obj.test_date = date;
    this.setState({
      drug_substance_test:updated_obj
    });
  }

  handleInputChange = (e, sys_test_question) => {
    let value = e.target.value;
    if (sys_test_question){
      let updated_obj = Object.assign([], this.state.testResults);
      let question = updated_obj.find(su => su.sys_test_question_id === sys_test_question.id);
      if (!updated_obj.includes(question)){
        updated_obj.push({sys_test_question_id:sys_test_question.id, reported_result:value});
      } else {
        question[e.target.name] = value;
      }
      this.setState({testResults:updated_obj});
    } else {
      let updated_obj = Object.assign({}, this.state.drug_substance_test);
      updated_obj[e.target.name] = value;
      this.setState({drug_substance_test:updated_obj});
    }
  }

  handlePassFailChange = (e) => {
    let updated_obj = Object.assign([], this.state.testResults);
    var question_value = e.target.value;
    var question_id = parseInt(e.target.name);
    let question = updated_obj.find(su => su.sys_test_question_id === question_id);
    if (!updated_obj.includes(question)){
      updated_obj.push({sys_test_question_id:question_id, result:question_value});
      question = updated_obj.find(su => su.sys_test_question_id === question_id);
    } else {
      question.result = question_value;
    }
    this.setState({testResults:updated_obj});
  }

  render() {
    if (!this.state.ds || !this.state.sys_test) return <div>Loading</div>;
    const {modalBackgroundClicked} = this.props;
    const {sys_test, drug_substance_test, testResults, ds} = this.state;
    return (
      <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title"><strong>{ds.lot_number}</strong> :: Enter {sys_test.name} Results</h4>
              <button type="button" className="close" onClick={modalBackgroundClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
          {drug_substance_test ?
            <React.Fragment>
            <form className="card">
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-6">
                    <label>Test Date:</label>
                    <br/>
                    <DatePicker
                      selected={drug_substance_test.test_date ? moment(drug_substance_test.test_date).toDate() : null}
                      onChange={this.handleDateChange}
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                {sys_test ?
                  <div className="row">
                    <div className="form-group col-12">
                      <label>{sys_test.name}</label>
                      <div className="row p-1 bg-light b-1">
                        <div className="col-4">
                          <strong>Test</strong>
                        </div>
                        <div className="col-4">
                          <strong>Acceptance Criteria/Reported Result</strong>
                        </div>
                        <div className="col-4">
                          <strong>Pass/Fail</strong>
                        </div>
                      </div>
                      {sys_test.children ? sys_test.children.map((question, q_index)=>
                        <div key={q_index} className="row mb-10 p-2 b-1 form">
                          <div className="col-4">
                            {question.question_text}
                          </div>
                          <div className="col-4">
                            <span dangerouslySetInnerHTML={{ __html: question.acceptance_criteria}}/>
                            <br/>
                            {question.question_type === 'GROWTH' ?
                              <div className="form-group">
                                <select className="form-control" name="reported_result" value={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).reported_result : ''} onChange={(e)=>this.handleInputChange(e, question)}>
                                  <option value=''>Select</option>
                                  <option value='GROWTH'>Growth</option>
                                  <option value='NOGROWTH'>No Growth</option>
                                </select>
                              </div>
                            : question.question_type === 'SCI' ?
                              <div className="form-group">
                                <InputMask required className="form-control" mask="9.9E+99" maskChar=" " name="reported_result" onChange={(e)=>this.handleInputChange(e, question)} value={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).reported_result : ''}/>
                              </div>
                            :
                              <div className="form-group">
                                <input type="number" step=".1" className="form-control form-control-sm" name="reported_result" value={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).reported_result : ''} onChange={(e)=>this.handleInputChange(e, question)}/>
                              </div>
                            }
                          </div>
                          <div className="col-4">
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name={question.id} id={q_index} value={'PASS'} onChange={this.handlePassFailChange} checked={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).result === 'PASS':false}/>
                              <label className="form-check-label" htmlFor={q_index}>
                                Pass
                              </label>
                            </div>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name={question.id} id={q_index} value={'FAIL'} onChange={this.handlePassFailChange} checked={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).result === 'FAIL':false}/>
                              <label className="form-check-label" htmlFor={q_index}>
                                Fail
                              </label>
                            </div>
                            {testResults && testResults.find(su => su.sys_test_question_id === question.id) && testResults.find(su => su.sys_test_question_id === question.id).result === 'FAIL' ?
                            <div className="form-group">
                              <InputMask required className="form-control" placeholder='OOS' mask="OOS-99-999" maskChar=" " name="oos_number" onChange={(e)=>this.handleInputChange(e, question)} value={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).oos_number : ''}/>
                            </div>
                            : null}
                          </div>
                        </div>
                      ): null}
                    </div>
                  </div>
                : null}
              </div>
            </form>
            </React.Fragment>
          :null}
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={modalBackgroundClicked}>
                Close
              </button>
              {drug_substance_test.is_complete === 1 ?
              <span className="text-info">This test is marked as complete</span>
              :
              <React.Fragment>
                <button type="button" onClick={()=>this.addTestResult(null)} className="btn btn-primary">Save</button>
                <button type="button" onClick={()=>this.addTestResult(true)} className="btn btn-primary">Save & Completed Entry</button>
              </React.Fragment>
               }
          </div>
      </React.Fragment>
    )
  }
}

export default DrugSubstanceModalTestEntry;
//
//
// <form className="card form-type-combine">
//   <div className="form-groups-attached">
//     <div className="row">
//       <div className="form-group col-12">
//         <label>Batch Status</label>
//         <input className="form-control" type="text" name="status" onChange={this.handleInputChange} value={selectedDonor.status}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Rebiotix Item #</label>
//         <input className="form-control" type="text" name="rebiotix_number" onChange={this.handleInputChange} value={selectedDonor.rebiotix_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>PEG Saline #</label>
//         <input className="form-control" type="text" name="peg_saline_number" onChange={this.handleInputChange} value={selectedDonor.peg_saline_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Bag Lot #</label>
//         <input className="form-control" type="text" name="bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Filter Bag Lot #</label>
//         <input className="form-control" type="text" name="filter_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.filter_bag_lot_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Closure Bag Lot #</label>
//         <input className="form-control" type="text" name="closure_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.closure_bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Doses Produced</label>
//         <input className="form-control" type="text" name="doses_produced" onChange={this.handleInputChange} value={selectedDonor.doses_produced}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Manufacture Date</label>
//         <input className="form-control" type="text" name="manufacture_date" onChange={this.handleInputChange} value={selectedDonor.manufacture_date}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Expiration Date</label>
//         <input className="form-control" type="text" name="expiration_date" onChange={this.handleInputChange} value={selectedDonor.expiration_date}/>
//       </div>
//     </div>
//   </div>
// </form>
