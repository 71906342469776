import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DonorQuickview from './DonorQuickview';
import DonorModalAdd from './DonorModalAdd';
import DonorModalAP from './DonorModalAP';
import DonorModalDetail from './DonorModalDetail';
import DonorModalBloodTest from './DonorModalBloodTest';
import DonatingStatusDisplay from './DonatingStatusDisplay';
import DonorPrint from './DonorPrint';
import Modal from 'react-bootstrap4-modal';
import {Spinner} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};


@inject('userStore')
class Donors extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        modalVisible:false,
        quickviewComponent:null,
        qualificationStatusFilter:'all',
        donatingFilter:'all',
        modalComponent:<DonorModalAdd donor={{donor_id:13008, donor_status:'PENDING', donation_status:'PENDING', img:''}} modalBackgroundClicked={this.modalBackgroundClicked}/>,
        donor:{},
        donors:[],
        donorsOriginal:[],
        sys_donor_status_list:[],
        sys_donation_status_list:[],
        donorIdSearch:'',
        loading:true
      }
  }

  componentDidMount(){
    this.loadDonors();
  }

  loadDonors = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('donors', null, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            donors:res.data.donors,
            donorsOriginal:res.data.donors,
            sys_donor_status_list:res.data.sys_donor_status_list,
            sys_donation_status_list:res.data.sys_donation_status_list
          })
        }
      })
      .catch((error)=>{console.log('error')});
    });
  }

  modalBackgroundClicked = () => {
    this.setState({
      modalVisible:false,
      modalComponent:null
    })
  }

  openAPModal = (donor) => {
    this.setState({
      modalVisible:true,
      modalComponent:<DonorModalAP donor={donor} modalBackgroundClicked={this.modalBackgroundClicked}/>
    })
  }

  openDetailModal = (donor) => {
    this.setState({
      modalVisible:true,
      modalComponent:<DonorModalDetail donor_hash_id={donor.hash_id} modalBackgroundClicked={this.modalBackgroundClicked} openBloodTestModalHandler={this.openBloodTestModal} loadDonors={this.loadDonors}/>
    })
  }

  openPrintModal = (donor) => {
    this.setState({
      modalVisible:true,
      modalComponent:<DonorPrint donor={donor} modalBackgroundClicked={this.modalBackgroundClicked}/>
    })
  }

  openBloodTestModal = (donor, donorDraw) => {
    this.setState({
      modalVisible:true,
      modalComponent:<DonorModalBloodTest donor={donor} modalBackgroundClicked={this.modalBackgroundClicked} addHandler={this.addTestResult} donorDraw={donorDraw}/>
    })
  }

  donatingFilter = (filter) => {
    var filteredList = [];
    switch (filter) {
      case 'all':
        if (this.state.qualificationStatusFilter !== 'all'){
          filteredList = this.state.donorsOriginal.filter(donor => donor.sys_donor_status === this.state.qualificationStatusFilter);
        } else {
          filteredList = this.state.donorsOriginal;
        }
        break;
      default:
        if (this.state.qualificationStatusFilter !== 'all'){
          filteredList = this.state.donorsOriginal.filter(donor => donor.sys_donor_status === this.state.qualificationStatusFilter && donor.sys_donation_status === filter);
        } else {
          filteredList = this.state.donorsOriginal.filter(donor => donor.sys_donation_status === filter);
        }
        break;
    }
    this.setState({
      donors:filteredList,
      donatingFilter:filter
    })
  }

  statusFilter = (filter) => {
    var filteredList = [];
    switch (filter) {
      case 'all':
        if (this.state.donatingFilter !== 'all'){
          filteredList = this.state.donorsOriginal.filter(donor => donor.sys_donation_status === this.state.donatingFilter);
        } else {
          filteredList = this.state.donorsOriginal;
        }
        break;
      default:
        if (this.state.donatingFilter !== 'all'){
          filteredList = this.state.donorsOriginal.filter(donor => donor.sys_donor_status === filter && donor.sys_donation_status === this.state.donatingFilter);
        } else {
          filteredList = this.state.donorsOriginal.filter(donor => donor.sys_donor_status === filter);
        }
        break;
    }
    this.setState({
      donors:filteredList,
      qualificationStatusFilter:filter
    })
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  toggleFold = () => {
      this.setState({
          folded:!this.state.folded
      });
  }

  addTestResult = (donorHashId, donorDraw, testResults) => {
    let draw_date = null;
    if (donorDraw.draw_date){
      donorDraw.draw_date = moment(donorDraw.draw_date).format('YYYY-MM-DD');
    }
    if (!donorDraw.hash_id){
      connectionPost('donors/' + donorHashId + '/draw-results', {donorDraw:donorDraw, testResults:testResults}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          if (res.data.hold_message){
            toast.warning(res.data.hold_message, toastParams)
          }
          this.modalBackgroundClicked();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPut('donors/' + donorHashId + '/draw-results/' + donorDraw.hash_id, {donorDraw:donorDraw, testResults:testResults}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.modalBackgroundClicked();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  handleInputChange = (e) => {
    var filteredList = [];
    filteredList = this.state.donorsOriginal.filter(donor => donor.rbx_donor_id.includes(e.target.value));
    this.setState({
      donors:filteredList,
      donorIdSearch:e.target.value
    })

  }

  resetFilters = (e) => {
    e.preventDefault();
    this.setState({
      donatingFilter:'all',
      qualificationStatusFilter:'all',
      donorIdSearch:'',
      donors:this.state.donorsOriginal
    })
  }

  render() {
    const {donorIdSearch, loading} = this.state;
    return (
      <React.Fragment>
        <Modal visible={this.state.modalVisible} onClickBackdrop={this.modalBackgroundClicked} dialogClassName="modal-lg">
          {this.state.modalComponent}
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
          pauseOnHover={false}
        />
        <aside className="aside aside-expand-md">
          <PerfectScrollbar>
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Donors</h2>
              <div className="btn-group-vertical">
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDonors}><i className="fa fa-refresh"/> Refresh</button>
              </div>
              <hr/>
              <p><small className="text-uppercase">Search</small></p>
              <div className="form-group">
                <input className="form-control" type="text" placeholder="Donor ID" value={donorIdSearch} onChange={this.handleInputChange}/>
              </div>
              <p><small className="text-uppercase">Qualification Status</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.statusFilter("all")}>
                  <input type="radio" name="donor_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.qualificationStatusFilter === 'all'}/>
                  <label className="custom-control-label">All Statuses</label>
                </div>
                {this.state.sys_donor_status_list.map((status, status_index)=>
                  <div key={status_index} className="custom-control custom-radio" onClick={()=>this.statusFilter(status.name)}>
                    <input type="radio" name="donor_filter" value={status.name} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.qualificationStatusFilter === status.name}/>
                    <label className="custom-control-label">{status.name}</label>
                  </div>
                )}
              </React.Fragment>
              </div>
              <hr/>
              <p><small className="text-uppercase">Donating Status</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.donatingFilter("all")}>
                  <input type="radio" name="donation_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.donatingFilter === 'all'}/>
                  <label className="custom-control-label">All Statuses</label>
                </div>
                {this.state.sys_donation_status_list.map((donation_status, donation_status_index)=>
                  <div key={donation_status_index} className="custom-control custom-radio" onClick={()=>this.donatingFilter(donation_status.name)}>
                    <input type="radio" name="donation_filter" value={donation_status.name} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.donatingFilter === donation_status.name}/>
                    <label className="custom-control-label">{donation_status.name}</label>
                  </div>
                )}
              </React.Fragment>
              </div>
              <hr/>
              <div className="btn-group pull-right">
                <button className="btn btn-sm btn-secondary" type="button" onClick={this.resetFilters}>Reset</button>
              </div>
            </form>
          </div>

          <button className="aside-toggler"></button>
          <br/><br/><br/>
          </PerfectScrollbar>
        </aside>
        {loading ?
          <div className="main-content">
            <div className="card card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          </div>
        :
        <div className="main-content">
        {this.state.donors.map((donor, index)=>
          <div key={index} className="card b-1 hover-shadow-2 mb-20">
            <div className="card-body cursor-pointer pt-0" onClick={()=>this.openDetailModal(donor)}>
              <div className="media row bb-1 pb-0">
                <div className="media-body">
                  <div className="mb-2">
                    <span className="fs-24 pr-16">{donor.rbx_donor_id}</span>
                    <span className="badge badge-success text-uppercase no-radius ls-1 opacity-75">{donor.sys_donor_status}</span>
                    <DonatingStatusDisplay status={donor.sys_donation_status} status_id={donor.sys_donation_status_id}/>
                  </div>
                </div>
                <div className="media-right text-right d-none d-md-block">
                  <span className="text-fade"><i className="fa fa-child pr-1"></i> Join Date: {moment(donor.create_timestamp).format('LLLL')}</span>
                </div>
              </div>
              <div className="card row">
                <div className="row no-gutters py-2">
                  <div className="col-sm-6 col-lg-3">
                    <div className="card-body br-1 border-light">
                      <div className="flexbox mb-1">
                        <span className="fa fa-calendar-o fs-40"></span>
                        <span className="fs-40 fw-100">{donor.weekly_count ? donor.weekly_count : 0}</span>
                      </div>
                      <div className="text-right">Weekly Donations</div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 hidden-down">
                    <div className="card-body br-1 border-light">
                      <div className="flexbox mb-1">
                        <span className="fa fa-calendar fs-40"></span>
                        <span className="fs-40 fw-100">{donor.monthly_count ? donor.monthly_count : 0}</span>
                      </div>
                      <div className="text-right">Monthly Donations</div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 d-none d-lg-block">
                    <div className="card-body br-1 border-light">
                      <div className="flexbox mb-1">
                        <span>
                          <i className="fa fa-balance-scale fs-20"></i><br/>
                          Underweight %
                        </span>
                        <span className={donor.weight_percentage && donor.weight_percentage[0].percentage > 50 ? "text-danger fs-40" : "text-success fs-40"}>{donor.weight_percentage ? Math.round(donor.weight_percentage[0].percentage) : 0}%</span>
                      </div>
                      <div className="progress">
                        <div className={donor.weight_percentage && donor.weight_percentage[0].percentage > 50 ? "progress-bar bg-danger" : "progress-bar bg-success"} role="progressbar" style={{width:Math.round(donor.weight_percentage[0].percentage) + '%', height: 4+'px'}} aria-valuenow="6" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 d-none d-lg-block">
                    <div className="card-body">
                      <div className="flexbox mb-1">
                        <span>
                          <i className="fa fa-star fs-20"></i><br/>
                          Formation %
                        </span>
                        <span className={donor.formation_percentage && donor.formation_percentage[0].percentage < 50 ? "text-danger fs-40" : "text-success fs-40"}>{donor.formation_percentage ? Math.round(donor.formation_percentage[0].percentage) : 0}%</span>
                      </div>
                      <div className="progress">
                        <div className={donor.formation_percentage && donor.formation_percentage[0].percentage < 50 ? "progress-bar bg-danger" : "progress-bar bg-success"} role="progressbar" style={{width:Math.round(donor.formation_percentage[0].percentage) + '%', height: 4+'px'}} aria-valuenow="86" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer className="card-footer flexbox align-items-center">
              <div>
                <strong>Last Donation: </strong>
                <span>{donor.last_donation ? moment(donor.last_donation[0].donation_date).format('ddd MM-DD-YYYY HH:mm A') : 'N/A'}</span>
              </div>
              <div>
                <span className={"fa fa-tint fs-20 " + donor.donor_group.color + " mr-10"}></span>
                <strong>Current Draw Date: </strong>
                <span>{donor.donor_group.draw_dates.current_draw ? moment(donor.donor_group.draw_dates.current_draw.draw_date).format('MM-DD-YYYY') : 'N/A'}</span>
              </div>
              <div className="btn-group">
                <button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown">Actions</button>
                <div className="dropdown-menu">
                  <button className="dropdown-item" onClick={()=>this.openDetailModal(donor)}>Donor Details</button>
                  {this.props.userStore.hasPermission('donors.donors', 2) ?
                    <React.Fragment>
                      {donor.donor_group && donor.donor_group.draw_dates && donor.donor_group.draw_dates.current_draw ? <button className="dropdown-item" onClick={()=>this.openBloodTestModal(donor, null)}>Enter Blood Test Results</button> : <button className="dropdown-item disabled">No Draw Date Set</button>}
                      <button className="dropdown-item" onClick={()=>this.openPrintModal(donor)}>Print ID</button>
                    </React.Fragment>
                  : null}
                </div>
              </div>
            </footer>
          </div>
        )}
        </div>
        }
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Donors;
// <button className="dropdown-item" onClick={()=>this.openAPModal(donor)}>Accounts Payable</button>
