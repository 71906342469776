import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

@inject('userStore')
class DonorModalBloodTest extends Component {
  constructor(props){
    super(props);
    this.state = {
      donor:null,
      donorDraw:{id:null, hash_id:null, donor_id:null, donor_group_id:null, donor_group_draw_id:null, draw_date:new Date(), sys_test_id:null, fairview_req_number:'', reported_result:''},
      sys_test:null,
      testResults:[]
    }
  }

  componentDidMount(){
    if (this.props.donorDraw){
      this.setState({
        donor:this.props.donor,
        donorDraw:this.props.donorDraw,
        testResults:this.props.donorDraw.donor_draw_result_list
      }, ()=>{this.fetchDonorDetails(this.state.donor.hash_id);});// system test id = 1 - blood test results
    } else {
      let updated_obj = Object.assign({}, this.state.donorDraw);
      updated_obj.donor_id = this.props.donor.id;
      updated_obj.donor_group_id = this.props.donor.donor_group_id;
      if (this.props.donor.donor_group.draw_dates.current_draw){
        updated_obj.donor_group_draw_id = this.props.donor.donor_group.draw_dates.current_draw.id;
        updated_obj.draw_date = this.props.donor.donor_group.draw_dates.current_draw.draw_date;
        updated_obj.sys_test_id = 1
      }
      this.setState({
        donor:this.props.donor,
        donorDraw:updated_obj
      }, ()=>{this.fetchDonorDetails(this.state.donor.hash_id);}
      );// system test id = 1 - blood test results

    }
  }

  fetchDonorDetails = (donor_hash_id) => {
    connectionGet('donors/' + donor_hash_id, {get_details:1}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          donor:res.data.donor
        },()=>{this.loadTest(1);})
      }
    })
    .catch((error)=>{console.log('error')});
  }

  loadTest = (sys_test_id) => {
    connectionGet('tests/' + sys_test_id, null, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          sys_test:res.data.sys_test
        });
        for (const test of res.data.sys_test.children){
          if ('Immunization' in test.acceptance_criteria_rule){
            // loop donor immunization to determine whether a test should be auto PASS
            for (const immunization of this.state.donor.immunization_list){
              if (immunization.immunization_type === test.acceptance_criteria_rule.Immunization.immunization_type){
                let updated_obj = Object.assign([], this.state.testResults);
                var question_value = 'PASS';
                var question_id = test.id;
                let question = updated_obj.find(su => su.sys_test_question_id === question_id);
                if (!updated_obj.includes(question)){
                  updated_obj.push({sys_test_question_id:question_id, result:question_value, reported_result:test.acceptance_criteria_rule.Immunization.value});
                  question = updated_obj.find(su => su.sys_test_question_id === question_id);
                  question.result = question_value;
                }
                this.setState({
                  testResults:updated_obj
                })
              }
            }
          }
        }
      }
    })
    .catch((error)=>{console.log(error)});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.donor.hash_id, this.state.donorDraw, this.state.testResults);
  }

  handleDateChange = (date) => {
    let updated_obj = Object.assign({}, this.state.donorDraw);
    updated_obj.draw_date = date;
    this.setState({
      donorDraw:updated_obj
    });
  }

  handleInputChange = (e, sys_test_question) => {
    const value = e.target.value;
    if (sys_test_question){
      let updated_obj = Object.assign([], this.state.testResults);
      let question = updated_obj.find(su => su.sys_test_question_id === sys_test_question.id);
      if (!updated_obj.includes(question)){
        updated_obj.push({sys_test_question_id:sys_test_question.id, reported_result:value});
      } else {
        question.reported_result = value;
      }
      this.setState({testResults:updated_obj});
    } else {
      let updated_obj = Object.assign({}, this.state.donorDraw);
      updated_obj[e.target.name] = value;
      this.setState({donorDraw:updated_obj});
    }
  }

  handlePassFailChange = (e, acceptance_criteria_rule) => {
    let updated_obj = Object.assign([], this.state.testResults);
    var question_value = e.target.value;
    var question_id = parseInt(e.target.name);
    let question = updated_obj.find(su => su.sys_test_question_id === question_id);
    if (!updated_obj.includes(question)){
      updated_obj.push({sys_test_question_id:question_id, result:question_value});
      question = updated_obj.find(su => su.sys_test_question_id === question_id);
    } else {
      question.result = question_value;
    }
    if (acceptance_criteria_rule && question_value in acceptance_criteria_rule){
      if ('action' in acceptance_criteria_rule[question_value] && acceptance_criteria_rule[question_value]['action'] === 'prepopulate'){
        question.reported_result = acceptance_criteria_rule[question_value]['value'];
      } else {
        // attempt to focus on the
        question.reported_result = '';
        alert('Enter a reported result');
      }
    }
    this.setState({testResults:updated_obj});
  }

  render() {
    if (!this.state.donor) return <div>Loading</div>;
    const {modalBackgroundClicked} = this.props;
    const {donor, sys_test, donorDraw, testResults} = this.state;
    return (
      <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title"><strong>Donor - {donor.rbx_donor_id}</strong> :: Enter Blood Test Results</h4>
              <button type="button" className="close" onClick={modalBackgroundClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
          {donor ?
            <React.Fragment>
            <form className="card form-type-combine" id="donor-blood-test-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-6">
                    <label className="required">Fairview Req #:</label>
                    <input className="form-control" required type="text" name="fairview_req_number" onChange={(e)=>this.handleInputChange(e, null)} value={donorDraw.fairview_req_number}/>
                  </div>
                  <div className="form-group col-6">
                    <label>Collection Date:</label>
                    <br/>
                    <DatePicker
                      selected={moment(donorDraw.draw_date).toDate()}
                      onChange={this.handleDateChange}
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                {sys_test ?
                  <div className="row">
                    <div className="form-group col-12">
                      <label>{sys_test.name}</label>
                      <div className="row p-1 bg-light b-1">
                        <div className="col-2">
                          <strong>Test Code</strong>
                        </div>
                        <div className="col-4">
                          <strong>Test</strong>
                        </div>
                        <div className="col-4">
                          <strong>Acceptance Criteria/Reported Result</strong>
                        </div>
                        <div className="col-2">
                          <strong>Pass/Fail</strong>
                        </div>
                      </div>
                      {sys_test.children ? sys_test.children.map((question, q_index)=>
                        <div key={q_index} className="row mb-10 p-2 b-1 form">
                          <div className="col-2">
                            {question.test_code}
                          </div>
                          <div className="col-4">
                            {question.question_text}
                          </div>
                          <div className="col-4">
                            {question.acceptance_criteria}
                            <br/>
                            <div className="form-group">
                              <input type="text" className="form-control form-control-sm" name="reported_result" value={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).reported_result : ''} onChange={(e)=>this.handleInputChange(e, question)}/>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name={question.id} id={q_index} value={'PASS'} onChange={(e)=>this.handlePassFailChange(e, question.acceptance_criteria_rule)} checked={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).result === 'PASS':false}/>
                              <label className="form-check-label" htmlFor={q_index}>
                                Pass
                              </label>
                            </div>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" name={question.id} id={q_index} value={'FAIL'} onChange={(e)=>this.handlePassFailChange(e, question.acceptance_criteria_rule)} checked={testResults && testResults.find(su => su.sys_test_question_id === question.id) ? testResults.find(su => su.sys_test_question_id === question.id).result === 'FAIL':false}/>
                              <label className="form-check-label" htmlFor={q_index}>
                                Fail
                              </label>
                            </div>
                          </div>
                        </div>
                      ): null}
                    </div>
                  </div>
                : null}
              </div>
            </form>
            </React.Fragment>
          :null}
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={modalBackgroundClicked}>
                Close
              </button>
              <button type="submit" form="donor-blood-test-form"  className="btn btn-primary">Save</button>
          </div>
      </React.Fragment>
    )
  }
}

export default DonorModalBloodTest;
//
//
// <form className="card form-type-combine">
//   <div className="form-groups-attached">
//     <div className="row">
//       <div className="form-group col-12">
//         <label>Batch Status</label>
//         <input className="form-control" type="text" name="status" onChange={this.handleInputChange} value={selectedDonor.status}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Rebiotix Item #</label>
//         <input className="form-control" type="text" name="rebiotix_number" onChange={this.handleInputChange} value={selectedDonor.rebiotix_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>PEG Saline #</label>
//         <input className="form-control" type="text" name="peg_saline_number" onChange={this.handleInputChange} value={selectedDonor.peg_saline_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Bag Lot #</label>
//         <input className="form-control" type="text" name="bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Filter Bag Lot #</label>
//         <input className="form-control" type="text" name="filter_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.filter_bag_lot_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Closure Bag Lot #</label>
//         <input className="form-control" type="text" name="closure_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.closure_bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Doses Produced</label>
//         <input className="form-control" type="text" name="doses_produced" onChange={this.handleInputChange} value={selectedDonor.doses_produced}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Manufacture Date</label>
//         <input className="form-control" type="text" name="manufacture_date" onChange={this.handleInputChange} value={selectedDonor.manufacture_date}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Expiration Date</label>
//         <input className="form-control" type="text" name="expiration_date" onChange={this.handleInputChange} value={selectedDonor.expiration_date}/>
//       </div>
//     </div>
//   </div>
// </form>
