import React, { Component } from 'react';
import RecipeQuickview from './RecipeQuickview';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Recipes extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'all',
        recipe:null,
        recipes:[],
        recipesOriginal:[],
        searchName:'',
        sys_bom_type_list:[],
        sys_status_list:[]
      }
  }

  componentDidMount(){
    this.loadRecipes();
  }

  loadRecipes = () => {
    connectionGet('recipes', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          recipes:res.data.recipe_list,
          recipesOriginal:res.data.recipe_list,
          sys_status_list:res.data.sys_status_list,
          sys_bom_type_list:res.data.sys_bom_type_list,
          manufacture_item_list:res.data.manufacture_item_list
        })
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  addRecipe = (recipe) => {
    if (recipe.hash_id){
      connectionPut('recipes/' + recipe.hash_id, {recipe:recipe}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadRecipes();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('recipes', {recipe:recipe}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadRecipes();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (recipe) => {
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<RecipeQuickview addHandler={this.addRecipe} closeHandler={this.closeHandler} recipe={recipe} sys_bom_type_list={this.state.sys_bom_type_list} manufacture_item_list={this.state.manufacture_item_list} sys_status_list={this.state.sys_status_list}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }


  render() {
    const {searchName, recipes} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>BOM Recipes</h2>
              <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add Recipe</button>
              <hr/>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="10%">Material#</th>
                  <th width="20%">Name</th>
                  <th width="20%">Type</th>
                  <th width="10%">Manufactured?</th>
                  <th width="25%">Manufacture Items</th>
                  <th width="15%">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {recipes ? recipes.map((recipe, index)=>
                <tr key={index}>
                  <td>{recipe.material_number}</td>
                  <td>{recipe.name}</td>
                  <td>{recipe.sys_bom_type}</td>
                  <td>{recipe.is_manufactured===1 ? 'Y' : 'N'}</td>
                  <td>
                    <ul className="list-iconic">
                    {recipe.children && recipe.children.length > 0 ?
                      recipe.children.map((children, children_index)=>
                      <li key={children_index}><i className="icon ti-check"></i>{children.name}</li>
                      )
                    : null}
                    </ul>
                  </td>
                  <td>{recipe.sys_status}</td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(recipe)}><i className="ti-pencil"></i></a>
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(recipe)}><i className="ti-menu-alt"></i></a>
                  </td>
                </tr>
                ) : <tr><td colSpan="5">No Recipes Found</td></tr>
              }
              </tbody>
            </table>
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Recipes;
