import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class UserQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      user:{id:null, first_name:'', last_name:'', email:'', password:'', sys_status_id:1, role_list:[]}
    }
  }

  componentDidMount(){
    if (this.props.user !== null){
      this.setState({
        user:this.props.user
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.user);
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.user);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'role_list'){
      var role_value = parseInt(e.target.value);
      let sys_user_role = this.props.sys_user_role_list.find(su => su.sys_user_role_id === role_value);
      if (e.target.checked && !updated_obj.role_list.includes(sys_user_role)){
        updated_obj.role_list.push(sys_user_role)
      } else {
        // var index = updated_obj.role_list.indexOf(su => su.sys_user_role_id === role_value);
        var index = updated_obj.role_list.map(function(e) { return e.sys_user_role_id; }).indexOf(role_value);
        console.log(index);
        if (index !== -1) updated_obj.role_list.splice(index, 1);
      }
    } else {
      updated_obj[e.target.name] = value;
    }
    this.setState({user:updated_obj});
  }

  render() {
    if (!this.state.user) return <div>Loading</div>;
    const {closeHandler, sys_user_role_list, sys_status_list} = this.props;
    const {user} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!user.id ? 'Add User' : user.full_name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="user-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">First Name</label>
                    <input required className="form-control" type="text" name="first_name" onChange={this.handleInputChange} value={user.first_name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Last Name</label>
                    <input required className="form-control" type="text" name="last_name" onChange={this.handleInputChange} value={user.last_name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Email</label>
                    <input required className="form-control" type="email" name="email" onChange={this.handleInputChange} value={user.email}/>
                  </div>
                </div>
                <div className="row">
                {!user.id ?
                  <div className="form-group col-12">
                    <label className="required">Password</label>
                    <input required className="form-control" type="password" name="password" onChange={this.handleInputChange} value={user.password}/>
                  </div>
                :
                  <div className="form-group col-12">
                    <label>New Password</label>
                    <input className="form-control" type="password" name="password" onChange={this.handleInputChange} value={user.password}/>
                    <small className="form-text">Leave blank unless you want to change the password.</small>
                  </div>
                }
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">User Status</label>
                    <select required className="form-control" name="sys_status_id" onChange={this.handleInputChange} value={user.sys_status_id}>
                    {sys_status_list.map((sys_status, status_index)=>
                      <option value={sys_status.id}>{sys_status.name}</option>
                    )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <h5>Roles</h5>
                    {sys_user_role_list.map((sys_user_role, sur_index)=>{
                      let checked=false;
                      if (user.role_list.find(su => su.sys_user_role_id === sys_user_role.id)){
                        checked=true;
                      } else {
                        checked=false;
                      }
                      console.log(checked);
                      return (
                        <div className="form-check" key={sur_index}>
                          <input className="form-check-input" type="checkbox" name="role_list" onChange={this.handleInputChange} value={sys_user_role.id} id={sur_index} checked={checked}/>
                          <label className="form-check-label" htmlFor={sur_index}>{sys_user_role.name}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="user-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default UserQuickview;
