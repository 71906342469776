import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { connectionPost } from '../../utils/connection';
import ReactJson from 'react-json-view'

@inject('userStore')
class Default extends Component {

  render() {
    return (
      <React.Fragment>
        <main className="main-container">
          <header className="header bg-ui-general">
            <div className="header-info">
              <h1 className="header-title">
                <strong>Welcome</strong> Back
                <small>Please select an option on the right.</small>
              </h1>
            </div>
          </header>
        </main>
      </React.Fragment>
    );
  }
}

export default Default;
