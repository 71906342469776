import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import DatePicker from "react-datepicker";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datepicker/dist/react-datepicker.css";

@inject('userStore')
class DonorModalDetail extends Component {
  constructor(props){
    super(props);
    this.state = {
      donor:null,
      donorActivity:{qualification_result:'',hold_number:'', reevaluation_date:new Date()},
      immunization:{},
      dhs_list:[]
    }
  }

  componentDidMount(){
    this.fetchDonorDetails(this.props.donor_hash_id);
  }

  componentWillReceiveProps(nextProps){
    this.fetchDonorDetails(nextProps.donor_hash_id);
  }

  fetchDonorDetails = (donor_hash_id) => {
    connectionGet('donors/' + donor_hash_id, {get_details:'1'}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          donor:res.data.donor,
          dhs_list:res.data.dhs_list
        })
      }
    })
    .catch((error)=>{console.log('error')});
  }

  handleHoldChange = (e) => {
    let temp = Object.assign({}, this.state.donorActivity);
    const value = e.target.value;
    temp[e.target.name] = value;
    this.setState({donorActivity:temp});
  };

  handleHoldDateChange = date => {
    let temp = Object.assign({}, this.state.donorActivity);
    temp.reevaluation_date = date;
    this.setState({
      donorActivity:temp
    });
  };

  handleChange = date => {
    let temp_immunization = Object.assign({}, this.state.immunization);
    temp_immunization.next_due_date = date;
    this.setState({
      immunization:temp_immunization
    });
  };

  saveImmunization = () => {
    let next_due_date = null;
    if (this.state.immunization.next_due_date){
      next_due_date = moment(this.state.immunization.next_due_date).format('YYYY-MM-DD');
    }
    connectionPost('donors/' + this.state.donor.hash_id + '/immunization', {immunization:this.state.immunization, next_due_date:next_due_date}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        let temp_donor = Object.assign({}, this.state.donor);
        temp_donor.immunization_list = res.data.immunization_list;
        this.setState({
          donor:temp_donor,
          immunization:null
        })
      }
    })
    .catch((error)=>{console.log('error')});
  }

  saveQualification = () => {
    let reevaluation_date = null;
    if (this.state.donorActivity.reevaluation_date){
      reevaluation_date = moment(this.state.donorActivity.reevaluation_date).format('YYYY-MM-DD');
    }
    connectionPost('donors/' + this.state.donor.hash_id + '/qualification-results', {hold_number:this.state.donorActivity.hold_number, reevaluation_date:reevaluation_date, qualification_result:this.state.donorActivity.qualification_result}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        alert('saved!');
        this.setState({donorActivity:{qualification_result:'',hold_number:'', reevaluation_date:new Date()}})
        this.props.loadDonors();
      }
    })
    .catch((error)=>{console.log('error')});
  }

  handleImmunizationChange = (e) => {
    let temp_immunization = Object.assign({}, this.state.immunization);
    const value = e.target.value;
    temp_immunization[e.target.name] = value;
    this.setState({immunization:temp_immunization});
  }

  render() {
    const columns = [{
      dataField: 'rbx_lot_number',
      text: 'Lot Number'
    }, {
      dataField: 'rbx_donor_id',
      text: 'Donor ID',
      sort: true
    }, {
      dataField: 'donation_date',
      text: 'Donation Date',
      sort: true
    }, {
      dataField: 'expiration_date',
      text: 'Expiration Date',
      sort: true
    }, {
      dataField: 'weight',
      text: 'Weight'
    }, {
      dataField: 'status.sys_dhs_status',
      text: 'Status'
    }, {
      dataField: 'pooled_sample_number',
      text: 'Assigned PS',
      sort: true
    }];
    const {modalBackgroundClicked} = this.props;
    const {donor, dhs_list, donation_list, immunization, donorActivity} = this.state;
    return (
      <React.Fragment>
        {donor ?
          <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title">Donor Detail - {donor.rbx_donor_id}</h4>
              <button type="button" className="close" onClick={modalBackgroundClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#dhs-history">DHS History</a>
              </li>
              {this.props.userStore.hasPermission('donors.donors', 2) ?
                <React.Fragment>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#immunization-records">Immunization</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#test-results">Test Results</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#donor-qualification">Donor Qualification</a>
                </li>
                </React.Fragment>
              : null}
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#details">Details</a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade active show" id="dhs-history">
                <div className="card p-10">
                  <BootstrapTable keyField='id' data={dhs_list} columns={columns} pagination={paginationFactory()} striped hover headerClasses="thead-light"/>
                </div>
              </div>
              <div className="tab-pane fade" id="immunization-records">
                <form className="card form-type-combine">
                  <div className="form-groups-attached">
                    <div className="row">
                      <div className="form-group col-4">
                        <label>Immunization Type</label>
                        <select className="form-control" name="immunization_type" value={immunization && immunization.immunization_type ? immunization.immunization_type : ''} onChange={this.handleImmunizationChange}>
                          <option value="">Select</option>
                          <option value="HEP-A">HEP-A</option>
                          <option value="HEP-B">HEP-B</option>
                        </select>
                      </div>
                      <div className="form-group col-4">
                        <label>Immunized?</label>
                        <select className="form-control" name="is_immunized" value={immunization && immunization.is_immunized ? immunization.is_immunized : ''} onChange={this.handleImmunizationChange}>
                          <option value="">Select</option>
                          <option value="1">Immunized</option>
                          <option value="0">Not Immunized</option>
                        </select>
                      </div>
                      {immunization && immunization.immunization_type && immunization.immunization_type === 'HEP-B' && immunization.is_immunized && immunization.is_immunized === '1' ?
                      <React.Fragment>
                        <div className="form-group col-2">
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="is_in_process" id="complete" value={0} checked={immunization && immunization.is_in_process === "0" ? true : false} onChange={this.handleImmunizationChange}/>
                            <label className="form-check-label" htmlFor="complete">
                              Complete
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="radio" name="is_in_process" id="in-process" value={1} checked={immunization && immunization.is_in_process === "1" ? true : false} onChange={this.handleImmunizationChange}/>
                            <label className="form-check-label" htmlFor="in-process">
                              In-Process
                            </label>
                          </div>
                        </div>
                        {immunization.is_in_process && immunization.is_in_process === "1" ?
                          <div className="form-group col-2">
                            <label>Next Dose Due</label>
                            <DatePicker
                              selected={moment(immunization.next_due_date).toDate()}
                              onChange={this.handleChange}
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                            />
                          </div>
                        : null}
                      </React.Fragment>
                      : null}
                    </div>
                  </div>
                  <footer className="card-footer text-right">
                    <button className="btn btn-secondary" type="reset" onClick={()=>this.setState({immunization:null})}>Cancel</button>
                    <button className="btn btn-primary" type="reset" onClick={this.saveImmunization}>Submit</button>
                  </footer>
                </form>
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <td>Type</td>
                      <td>Immunized</td>
                      <td>In Process</td>
                      <td>Next Dose Due</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {donor && donor.immunization_list ? donor.immunization_list.map((immunization, im_index)=>
                      <tr key={im_index}>
                        <td>{immunization.immunization_type}</td>
                        <td>{immunization.is_immunized === "1" ? <span className="badge badge-success">IMMUNIZED</span> : <span className="badge badge-danger">NOT IMMUNIZED</span>}</td>
                        <td>{immunization.immunization_type === 'HEP-B' ? immunization.is_in_process && immunization.is_in_process === "1" ? <span className="badge badge-warning">IN PROCESS</span> :<span className="badge badge-success">COMPLETE</span> : null }</td>
                        <td>{immunization.immunization_type === 'HEP-B' ? immunization.is_in_process && immunization.is_in_process === "1" ? immunization.next_due_date : null : null }</td>
                        <td><a className="table-action" href="#" onClick={()=>this.setState({immunization:immunization})}><i className="ti-pencil"></i></a></td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
              <div className="tab-pane fade" id="donor-qualification">
                <form className="card form-type-combine">
                  <div className="form-groups-attached">
                    <div className="row">
                      <div className="form-group col-5">
                        <label>Qualification Results</label>
                        <select className="form-control" name="qualification_result" value={donorActivity.qualification_result} onChange={this.handleHoldChange}>
                          <option value="">Select</option>
                          <option value={1}>Pass</option>
                          <option value={2}>Pass with Hold</option>
                          <option value={0}>Fail</option>
                        </select>
                      </div>
                      {donorActivity.qualification_result && donorActivity.qualification_result === "2" ?
                      <React.Fragment>
                        <div className="form-group col-5">
                          <label>Hold #</label>
                          <input type="text" name="hold_number" className="form-control" value={donorActivity.hold_number} onChange={this.handleHoldChange}/>
                        </div>
                        <div className="form-group col-2">
                          <label>Re-evaluation Date</label>
                          <DatePicker
                            selected={moment(donorActivity.reevaluation_date).toDate()}
                            onChange={this.handleHoldDateChange}
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                      </React.Fragment>
                      : null}
                    </div>
                  </div>
                  <footer className="card-footer text-right">
                    <button className="btn btn-secondary" type="reset" onClick={()=>this.setState({donorActivity:{hold_number:'', reevaluation_date:new Date()}})}>Cancel</button>
                    <button className="btn btn-primary" type="reset" onClick={this.saveQualification}>Submit</button>
                  </footer>
                </form>

              </div>
              <div className="tab-pane fade" id="activity">
                <ol className="timeline timeline-activity timeline-point-sm timeline-content-right w-100 py-20 pr-20">
                  <li className="timeline-block">
                    <div className="timeline-point">
                      <span className="badge badge-ring badge-secondary"></span>
                    </div>
                    <div className="timeline-content">
                      <time dateTime="2018">Just now</time>
                      <p>On vacation for a week, won't be back til June</p>
                    </div>
                  </li>

                  <li className="timeline-block">
                    <div className="timeline-point">
                      <span className="badge badge-ring badge-secondary"></span>
                    </div>
                    <div className="timeline-content">
                      <time dateTime="2018">42 mins ago</time>
                      <p>Called <a href="#">donor 13001</a> to take a survey.</p>
                    </div>
                  </li>

                  <li className="timeline-block">
                    <div className="timeline-point">
                      <span className="badge badge-ring badge-secondary"></span>
                    </div>
                    <div className="timeline-content">
                      <time dateTime="2018">1 hour ago</time>
                      <p>Moved to Blue Group</p>
                    </div>
                  </li>

                  <li className="timeline-block">
                    <div className="timeline-point">
                      <span className="badge badge-ring badge-secondary"></span>
                    </div>
                    <div className="timeline-content">
                      <time dateTime="2018">2 hours ago</time>
                      <p>Norovirus.</p>
                    </div>
                  </li>

                  <li className="timeline-block">
                    <div className="timeline-point">
                      <span className="badge badge-ring badge-secondary"></span>
                    </div>
                    <div className="timeline-content">
                      <time dateTime="2018">5 hours ago</time>
                      <p>Moving back home</p>
                    </div>
                  </li>
                </ol>
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="add note..."/>
                  <span className="input-group-append">
                    <button className="btn btn-light" type="button">Save</button>
                  </span>
                </div>
              </div>
              <div className="tab-pane fade" id="test-results">
                {donor.donor_draw_list ? donor.donor_draw_list.map((donorDraw, dd_index)=>
                  <div key={dd_index} className="card card-bordered">
                    <header className="card-header">
                      <h4 className="card-title">{donorDraw.sys_test.name}</h4>
                    </header>
                    <div className="card-content">
                      <div className="card-body">
                        <div className="row bb-1 pb-1 mb-3">
                          <div className="col-6">
                            <h5>Fairview Requisition Number: {donorDraw.fairview_req_number}</h5>
                          </div>
                          <div className="col-6">
                            <h5>Collection Date: {moment(donorDraw.draw_date).format('MM/DD/YYYY')}</h5>
                          </div>
                        </div>
                        <dl className="row">
                        {donorDraw.donor_draw_result_list ? donorDraw.donor_draw_result_list.map((result, r_index)=>
                          <React.Fragment key={r_index}>
                            <div className="col-6">{result.question_text}</div>
                            <div className="col-4 text-right">{result.reported_result}</div>
                            <div className="col-2 pull-right">{result.result === 'PASS' ? <span className="badge badge-success pull-right">{result.result}</span> : <span className="badge badge-danger pull-right  ">{result.result}</span>}</div>
                          </React.Fragment>
                        ):null}
                        </dl>
                      </div>
                    </div>
                    <div className="card-footer">
                      <div className="btn-group pull-right">
                        <button className="btn btn-default"><i className="fa fa-print"></i> Print</button>
                        <button className="btn btn-default" onClick={()=>this.props.openBloodTestModalHandler(donor, donorDraw)}><i className="fa fa-edit"></i> Edit</button>
                        <button className="btn btn-default"><i className="fa fa-lock"></i> Lock</button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="tab-pane fade" id="details">
                <h4>Donor Details</h4>
                <dl className="row">
                  <dt className="col-2">Donor ID:</dt>
                  <dd className="col-10">{donor.rbx_donor_id}</dd>
                  <dt className="col-2">Join Date:</dt>
                  <dd className="col-10">{moment(donor.create_date).format('LLLL')}</dd>
                  <dt className="col-2">Donor Status:</dt>
                  <dd className="col-10">{donor.sys_donor_status}</dd>
                  <dt className="col-2">Donor Donating Status:</dt>
                  <dd className="col-10">{donor.sys_donation_status}</dd>
                  <dt className="col-2">Last Donation:</dt>
                  <dd className="col-10">{donor.last_donation ? moment(donor.last_donation[0].donation_date).format('LLLL'):null}</dd>
                  <dt className="col-2">Total Donations:</dt>
                  <dd className="col-10">45</dd>
                  <dt className="col-2">Underweight %:</dt>
                  <dd className="col-10">{donor.weight_percentage ? Math.round(donor.weight_percentage[0].percentage) : 0}%</dd>
                  <dt className="col-2">Formation %:</dt>
                  <dd className="col-10">{donor.formation_percentage ? Math.round(donor.formation_percentage[0].percentage) : 0}%</dd>
                </dl>
              </div>
            </div>
          </div>
          </React.Fragment>
          : <div className="modal-body"><div className="alert alert-info">Loading Donor Information</div></div>}
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={modalBackgroundClicked}>
                Close
              </button>
          </div>
      </React.Fragment>
    )
  }
}

export default DonorModalDetail;
//
//
// <form className="card form-type-combine">
//   <div className="form-groups-attached">
//     <div className="row">
//       <div className="form-group col-12">
//         <label>Batch Status</label>
//         <input className="form-control" type="text" name="status" onChange={this.handleInputChange} value={selectedDonor.status}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Rebiotix Item #</label>
//         <input className="form-control" type="text" name="rebiotix_number" onChange={this.handleInputChange} value={selectedDonor.rebiotix_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>PEG Saline #</label>
//         <input className="form-control" type="text" name="peg_saline_number" onChange={this.handleInputChange} value={selectedDonor.peg_saline_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Bag Lot #</label>
//         <input className="form-control" type="text" name="bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Filter Bag Lot #</label>
//         <input className="form-control" type="text" name="filter_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.filter_bag_lot_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Closure Bag Lot #</label>
//         <input className="form-control" type="text" name="closure_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.closure_bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Doses Produced</label>
//         <input className="form-control" type="text" name="doses_produced" onChange={this.handleInputChange} value={selectedDonor.doses_produced}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Manufacture Date</label>
//         <input className="form-control" type="text" name="manufacture_date" onChange={this.handleInputChange} value={selectedDonor.manufacture_date}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Expiration Date</label>
//         <input className="form-control" type="text" name="expiration_date" onChange={this.handleInputChange} value={selectedDonor.expiration_date}/>
//       </div>
//     </div>
//   </div>
// </form>
