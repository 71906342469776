import React, { Component } from 'react';

class DonatingStatusDisplay extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const {status, status_id} = this.props;
    switch (status_id) {
      case 1:
        return (<span className="badge badge-yellow text-uppercase no-radius ls-1 opacity-75">{status}</span>)
        break;
      case 2:
        return (<span className="badge badge-warning text-uppercase no-radius ls-1 opacity-75">{status}</span>)
        break;
      case 3:
        return (<span className="badge badge-success text-uppercase no-radius ls-1 opacity-75">{status}</span>)
        break;
      case 4:
        return (<span className="badge badge-danger text-uppercase no-radius ls-1 opacity-75">{status}</span>)
        break;
      case 5:
        return (<span className="badge badge-dark text-uppercase no-radius ls-1 opacity-75">{status}</span>)
        break;
      default:
        return (<span className="badge badge-success text-uppercase no-radius ls-1 opacity-75">{status}</span>)
    }
  }
}
export default DonatingStatusDisplay;
