import React, { Component } from 'react';

class DataTable extends Component {
    constructor(props){
      super(props);
    }

    getKeys = () => {
      return Object.keys(this.props.data[0]);
    }

    getHeader = () => {
      var keys = this.getKeys();
      return keys.map((key, index)=>{
        return <th key={key}>{key.toUpperCase()}</th>
      })
    }

    getRowsData = () => {
      var items = this.props.data;
      var keys = this.getKeys();
      return items.map((row, index)=>{
        return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
      })
    }

    render() {
      if (this.props.data === null || this.props.data.length < 1) return <div className="alert alert-warning">No Data</div>;
      return (
        <div className="mt-20">
          <small>Data Preview</small>
          <table className="table table-bordered table-striped table-condensed">
            <thead>
              <tr>{this.getHeader()}</tr>
            </thead>
            <tbody>
              {this.getRowsData()}
            </tbody>
          </table>
        </div>);
    }
}

const RenderRow = (props) =>{
  return props.keys.map((key, index)=>{
    return <td key={props.data[key]}>{props.data[key]}</td>
  })
}


export default DataTable;
