import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class RecipeQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      recipe:{id:null, name:'', sys_bom_type_id:1, sys_status_id:1, description:'', material_number:'', quantity:0, unit:'', quantity_rule:'', sequence:0, is_manufactured:false, children:[], is_extended:1}
    }
  }

  componentDidMount(){
    if (this.props.recipe !== null){
      this.setState({
        recipe:this.props.recipe,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.recipe);
  }

  handleSubmitAdd = () => {
    this.props.addHandler(this.state.recipe);
    this.setState({
      recipe:{id:null, name:'', sys_bom_type_id:1, sys_status_id:1, description:'', material_number:'', quantity:0, unit:'', quantity_rule:'', sequence:0, is_manufactured:false, children:[], is_extended:1}
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.recipe);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'children'){
      var child_value = parseInt(e.target.value);
      let manufacture_item = this.props.manufacture_item_list.find(su => su.id === child_value);
      if (e.target.checked && !updated_obj.children.includes(manufacture_item)){
        updated_obj.children.push(manufacture_item)
      } else {
        // var index = updated_obj.role_list.indexOf(su => su.sys_user_role_id === role_value);
        var index = updated_obj.children.map(function(e) { return e.id; }).indexOf(child_value);
        if (index !== -1) updated_obj.children.splice(index, 1);
      }
    } else {
      updated_obj[e.target.name] = value;
    }
    this.setState({recipe:updated_obj});
  }

  render() {
    if (!this.state.recipe) return <div>Loading</div>;
    const {closeHandler, manufacture_item_list, sys_bom_type_list, sys_status_list} = this.props;
    const {recipe} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!recipe.id ? 'Add Recipe' : recipe.name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="recipe-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Name</label>
                    <input required className="form-control" type="text" name="name" onChange={this.handleInputChange} value={recipe.name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Description</label>
                    <input required className="form-control" type="text" name="description" onChange={this.handleInputChange} value={recipe.description}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Material Number</label>
                    <input required className="form-control" type="text" name="material_number" onChange={this.handleInputChange} value={recipe.material_number}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">BOM Type</label>
                    <select required className="form-control" name="sys_bom_type_id" onChange={this.handleInputChange} value={recipe.sys_bom_type_id}>
                    {sys_bom_type_list.map((bom_type, bom_type_index)=>
                      <option key={bom_type_index} value={bom_type.id}>{bom_type.name}</option>
                    )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-3">
                    <label>Quantity</label>
                    <input className="form-control" type="text" name="quantity" onChange={this.handleInputChange} value={recipe.quantity}/>
                  </div>
                  <div className="form-group col-6">
                    <label>Units</label>
                    <input className="form-control" type="text" name="unit" onChange={this.handleInputChange} value={recipe.unit}/>
                  </div>
                  <div className="form-group col-3">
                    <label>Sequence</label>
                    <input className="form-control" type="text" name="sequence" onChange={this.handleInputChange} value={recipe.sequence}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="is_manufactured" onChange={this.handleInputChange} value={recipe.is_manufactured} id={'is_manufactured'} checked={recipe.is_manufactured}/>
                      <label className="form-check-label" htmlFor={'is_manufactured'}>Is Manufactured?</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="is_extended" onChange={this.handleInputChange} value={recipe.is_extended} id={'is_extended'} checked={recipe.is_extended}/>
                      <label className="form-check-label" htmlFor={'is_extended'}>Require Lot Number and Expiration?</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Quantity Calculation Rule</label>
                    <select required className="form-control" name="quantity_rule" onChange={this.handleInputChange} value={recipe.quantity_rule}>
                      <option value={'NONE'}>NONE</option>
                      <option value={'MULTIPLY'}>MULTIPLY</option>
                    </select>
                  </div>
                </div>
                {recipe.id ?
                <div className="row">
                  <div className="form-group col-12">
                    <h5>Manufacture Items</h5>
                    {manufacture_item_list ? manufacture_item_list.map((manufacture_item, man_index)=>{
                      if (manufacture_item.id !== recipe.id){
                        let checked=false;
                        console.log(recipe.children);
                        if (recipe && recipe.children && recipe.children.find(su => su.id === manufacture_item.id)){
                          checked=true;
                        } else {
                          checked=false;
                        }
                        return (
                          <div className="form-check" key={man_index}>
                            <input className="form-check-input" type="checkbox" name="children" onChange={this.handleInputChange} value={manufacture_item.id} id={man_index} checked={checked}/>
                            <label className="form-check-label" htmlFor={man_index}>{manufacture_item.name}</label>
                          </div>
                        );
                      }
                    }) : null}
                  </div>
                </div>
                : null}
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Active</label>
                    <select required className="form-control" name="sys_status_id" onChange={this.handleInputChange} value={recipe.sys_status_id}>
                    {sys_status_list.map((status, status_index)=>
                      <option key={status_index} value={status.id}>{status.name}</option>
                    )}
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="recipe-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-success" onClick={this.handleSubmitAdd}><i className="fa fa-fw fa-save"></i> Save & Add New</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default RecipeQuickview;
