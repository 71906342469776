import { observable, computed, action } from "mobx";
import { connectionPost } from '../utils/connection';
import { create, persist } from 'mobx-persist';

class UserStore {
  @persist @observable id = 0;
  @persist @observable token = '';
  @persist @observable hash_id = '';
  @persist @observable first_name = '';
  @persist @observable last_name = '';
  @persist @observable full_name = '';
  @persist @observable email = '';
  @persist @observable password = '';
  @persist @observable password_verify = '';
  @persist @observable sys_status_id = 0;
  @persist @observable is_admin = 0;
  @persist @observable status = '';
  @persist @observable db_version = '';
  @persist('object') @observable permissions = {};
  @persist('list') @observable role_list = [];

  @computed get is_authenticated() {
  	if (this.token !== ''){
  		return this.token;
  	} else {
  		return false;
  	}
  }

	@action get_token(email, password){
    return connectionPost('token', {}, {username: email,password: password}
      ).then((res) => {
        if (res && res.data.token){
          // should return: user, role list,
          this.db_version = res.data.db_version;
          this.permissions = res.data.user.permissions;
          this.id = res.data.user.id;
          this.token = res.data.token;
          this.hash_id = res.data.user.hash_id;
          this.first_name = res.data.user.first_name;
          this.last_name = res.data.user.last_name;
          this.full_name = res.data.user.full_name;
          this.email = res.data.user.email;
          this.sys_status_id = res.data.user.sys_status_id;
          this.status = res.data.user.status;
          this.is_admin = res.data.user.is_admin
          this.role_list = res.data.user.role_list;
        } else {
          console.log("Incorrect log in credentials");
        }
      }).catch((error) => {
        console.log("login error ", error);
        return error;
      });
    }

    @action resetPassword(email, password, password_new){
      return connectionPost('reset-password', {user:{email:email, password:password, password_new:password_new}}, {auth: {username: email,password: ''}
        }).then((res) => {
          if (res.data.token){
            // should return: user, role list,
            this.db_version = res.data.db_version;
            this.permissions = res.data.user.permissions;
            this.id = res.data.user.id;
            this.token = res.data.token;
            this.hash_id = res.data.user.hash_id;
            this.first_name = res.data.user.first_name;
            this.last_name = res.data.user.last_name;
            this.full_name = res.data.user.full_name;
            this.email = res.data.user.email;
            this.sys_status_id = res.data.user.sys_status_id;
            this.status = res.data.user.status;
            this.is_admin = res.data.user.is_admin;
            this.role_list = res.data.user.role_list;
          }
          return res;
        }).catch((error) => {
          console.log("Reset error ", error);
          return error;
        });
      }


    @action logout(){
      this.id = 0;
      this.token = '';
      this.hash_id = '';
      this.first_name = '';
      this.last_name = '';
      this.full_name = '';
      this.email = '';
      this.sys_status_id = 0;
      this.status = '';
      this.is_admin = 0;
      this.role_list = [];
      this.permissions = {};
    }

    hasPermission(fx, level){
      if (fx === 'default' || fx === 'error'){
        return true;
      } else if (this.permissions[fx] && this.permissions[fx] >= level){
        return true;
      }
      return false;
    }
}

export default new UserStore();
