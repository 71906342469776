import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { inject } from 'mobx-react';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

@inject('userStore')
class DrugSubstanceModalTestResults extends Component {
  constructor(props){
    super(props);
    this.state = {
      ds:null
    }
  }

  componentDidMount(){
    if (this.props.ds){
      this.loadDs(this.props.ds);
    }
  }

  loadDs = (ds) => {
    connectionGet('boms/' + ds.bom_product_recipe_id + '/' + ds.hash_id, null, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          ds:res.data.kit
        });
      }
    })
    .catch((error)=>{console.log('error')});
  }

  setCOA = (e, test_result) => {
    if (e.target.checked){
      test_result.include_coa = 1;
    } else {
      test_result.include_coa = 0;
    }
    connectionPut('drug-substance-test-result/' + test_result.hash_id, {drug_substance_test_result:test_result}, {username: this.props.userStore.token})
    .then((res)=>{
      alert(res.data.message);
      this.loadDs(this.state.ds);
    })
    .catch((error)=>{console.log('error')});
  }


  render() {
    if (!this.state.ds) return <div>Loading</div>;
    const {modalBackgroundClicked} = this.props;
    const {ds} = this.state;
    return (
      <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title"><strong>{ds.lot_number}</strong> :: Test Results</h4>
              <button type="button" className="close" onClick={modalBackgroundClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
          {ds.test_list.length > 0 ?
            ds.test_list.map((test, t_index)=>
              <div key={t_index} className="card card-bordered">
                <div className="card-header bg-light">
                  <h4 className="card-title">{moment(test.test_date).format('YYYY-MM-DD')}</h4>
                </div>
                <div className="card-body">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Test</th>
                        <th>Reported Result</th>
                        <th>Pass/Fail</th>
                        <th>Include in COA?</th>
                      </tr>
                    </thead>
                    <tbody>
                  {test.drug_substance_test_result_list.map((result, r_index)=>
                    <tr key={r_index}>
                      <td>{result.question_text}</td>
                      <td>{result.reported_result}</td>
                      <td>{result.result === 'PASS' ? <span className="badge badge-success">{result.result}</span> : <React.Fragment><span className="badge badge-danger">{result.result}</span><br/><small><strong>{result.oos_number}</strong></small></React.Fragment>}</td>
                      <td><input type="checkbox" onChange={(e)=>this.setCOA(e, result)} checked={result.include_coa}/></td>
                    </tr>
                  )}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          : <div className="alert alert-info">No Test Results</div>}
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={modalBackgroundClicked}>
                Close
              </button>
          </div>
      </React.Fragment>
    )
  }
}

export default DrugSubstanceModalTestResults;
//
//
// <form className="card form-type-combine">
//   <div className="form-groups-attached">
//     <div className="row">
//       <div className="form-group col-12">
//         <label>Batch Status</label>
//         <input className="form-control" type="text" name="status" onChange={this.handleInputChange} value={selectedDonor.status}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Rebiotix Item #</label>
//         <input className="form-control" type="text" name="rebiotix_number" onChange={this.handleInputChange} value={selectedDonor.rebiotix_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>PEG Saline #</label>
//         <input className="form-control" type="text" name="peg_saline_number" onChange={this.handleInputChange} value={selectedDonor.peg_saline_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Bag Lot #</label>
//         <input className="form-control" type="text" name="bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Filter Bag Lot #</label>
//         <input className="form-control" type="text" name="filter_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.filter_bag_lot_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Closure Bag Lot #</label>
//         <input className="form-control" type="text" name="closure_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.closure_bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Doses Produced</label>
//         <input className="form-control" type="text" name="doses_produced" onChange={this.handleInputChange} value={selectedDonor.doses_produced}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Manufacture Date</label>
//         <input className="form-control" type="text" name="manufacture_date" onChange={this.handleInputChange} value={selectedDonor.manufacture_date}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Expiration Date</label>
//         <input className="form-control" type="text" name="expiration_date" onChange={this.handleInputChange} value={selectedDonor.expiration_date}/>
//       </div>
//     </div>
//   </div>
// </form>
