import React, { Component } from 'react';
import StorageQuickview from './StorageQuickview';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

class StorageRow extends Component {
  render(){
    const {storage, indent, openHandler} = this.props;
    return (
      <React.Fragment>
        <div className={indent === 0 ? "card card-body card-bordered col-lg-12" : null}>
          <div className="row">
            <div className="col-lg-10">
            {indent === 0 ?
              <React.Fragment><strong>{storage.name}</strong><small className="ml-1">{storage.sys_bom_storage_type}</small> <small>{storage.description}</small> </React.Fragment>
            : indent === 1 ?
              <React.Fragment><strong className="ml-2">{storage.name}</strong><small className="ml-1">{storage.sys_bom_storage_type}</small> <small>{storage.description}</small> </React.Fragment>
            : indent === 2 ?
              <React.Fragment><strong className="ml-4">{storage.name}</strong><small className="ml-1">{storage.sys_bom_storage_type}</small> <small>{storage.description}</small> </React.Fragment>
            :
              <strong>{storage.name}</strong>
            }
            </div>
            <div className="col-lg-2">
              <div className="btn-group pull-right">
              {storage.sys_bom_storage_type_id !== 3 ?
                <a class="hover-primary" href="#" onClick={()=>openHandler(null, storage.id)}><i class="ti-plus"></i></a>
              : null}
                <a class="ml-2 hover-primary" href="#" onClick={()=>openHandler(storage, storage.id)}><i class="ti-pencil"></i></a>
              </div>
            </div>
          </div>
          {storage.children.length > 0 ?
            storage.children.map((child, child_index)=>
              <StorageRow key={child_index} storage={child} indent={indent+1} openHandler={openHandler}/>
            )
          : null}
        </div>
      </React.Fragment>
    );
  }
}

@inject('userStore')
class Storage extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'all',
        storage:null,
        storageList:[],
        storageListOriginal:[],
        searchName:'',
        sys_bom_storage_type_list:[]
      }
  }

  componentDidMount(){
    this.loadStorage();
  }

  loadStorage = () => {
    connectionGet('storage', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          storageList:res.data.storage_list,
          storageListOriginal:res.data.storage_list,
          sys_bom_storage_type_list:res.data.sys_bom_storage_type_list
        })
      }
    })
    .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
  }

  addStorage = (storage) => {
    if (storage.hash_id){
      connectionPut('storage/' + storage.hash_id, {storage:storage}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadStorage();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('storage', {storage:storage}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadStorage();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (storage, parent_id) => {
    console.log(storage)
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<StorageQuickview addHandler={this.addStorage} parent_id={parent_id} closeHandler={this.closeHandler} storage={storage} sys_bom_storage_type_list={this.state.sys_bom_storage_type_list}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  render() {
    const {searchName, storageList} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Storage</h2>
              <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null, null)}><i className="fa fa-plus"/> Add Storage</button>
              <hr/>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card card-body">
            {storageList.map((storage, storage_index)=>
              <StorageRow storage={storage} indent={0} openHandler={this.openHandler}/>
            )}
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Storage;
