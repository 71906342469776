import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class StatusQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      status:{id:null, name:''}
    }
  }

  componentDidMount(){
    if (this.props.status !== null){
      this.setState({
        status:this.props.status
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.status);
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.status);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'sys_donation_status_list'){
      let donation_id = parseInt(e.target.value);
      let sys_donation_status = this.props.donationStatusList.find(su => su.id === donation_id);
      if (e.target.checked && !updated_obj.sys_donation_status_list.includes(sys_donation_status)){
        updated_obj.sys_donation_status_list.push(sys_donation_status)
      } else {
        // var index = updated_obj.role_list.indexOf(su => su.sys_user_role_id === role_value);
        var index = updated_obj.sys_donation_status_list.map(function(e) { return e.id; }).indexOf(donation_id);
        if (index !== -1) updated_obj.sys_donation_status_list.splice(index, 1);
      }
    } else {
      updated_obj[e.target.name] = value;
    }
    this.setState({status:updated_obj});
  }

  render() {
    if (!this.state.status) return <div>Loading</div>;
    const {closeHandler, donationStatusList} = this.props;
    const {status} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!status.id ? 'Add Status' : status.name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="donor-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Status Name</label>
                    <input required className="form-control" type="text" name="name" onChange={this.handleInputChange} value={status.name}/>
                  </div>
                </div>
              {this.props.statusType === 'Qualification'?
                <React.Fragment>
                  <div className="row">
                    <div className="form-group col-12 pt-10">
                      <label>Donor Donating Status</label>
                      {donationStatusList.map((donationStatus, index)=>
                        <div className="form-check" key={index}>
                          <input className="form-check-input" type="checkbox" name="sys_donation_status_list" onChange={this.handleInputChange} value={donationStatus.id} id={index} checked={status.sys_donation_status_list && status.sys_donation_status_list.filter(e => e.id === donationStatus.id).length > 0}/>
                          <label className="form-check-label" htmlFor={index}>{donationStatus.name}</label>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              : null}
              {this.props.statusType === 'Dose'?
                <React.Fragment>
                  <div className="row">
                    <div className="form-group col-12 pt-10">
                      <label>Study#/Protocol<small>Separate values with a comma</small></label>
                      <input required className="form-control" type="text" name="sub_status_list" onChange={this.handleInputChange} value={status.sub_status_list}/>
                    </div>
                  </div>
                </React.Fragment>
              : null}
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="donor-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default StatusQuickview;
