import React, { Component } from 'react';
import UserQuickview from './UserQuickview';
import Modal from 'react-bootstrap4-modal';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Users extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'all',
        user:null,
        users:[],
        usersOriginal:[],
        sys_user_role_list:[],
        sys_status_list:[],
        searchName:'',
        loading:true
      }
  }

  componentDidMount(){
    this.loadUsers();
  }

  loadUsers = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('users', {param:'1'}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            users:res.data.users,
            usersOriginal:res.data.users,
            sys_user_role_list:res.data.sys_user_role_list,
            sys_status_list:res.data.sys_status_list
          })
        }
      })
      .catch((error)=>{alert(error);}
      );
    })
  }

  addUser = (user) => {
    if (user.hash_id){
      connectionPut('users/' + user.hash_id, {user:user}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, quickviewComponent:null}, ()=>this.loadUsers());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('users', {user:user}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, quickviewComponent:null}, ()=>this.loadUsers());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  statusFilter = (filter) => {
    var filteredList = [];
    switch (filter) {
      case 'all':
        filteredList = this.state.usersOriginal;
        break;
      default:
        filteredList = this.state.usersOriginal.filter(user => user.status === filter);
        break;
    }
    this.setState({
      users:filteredList,
      statusFilter:filter
    })
  }

  searchUsers = (e) => {
    var filteredList = [];
    let filter = e.target.value;
    if (filter.length > 2){
      filteredList = this.state.usersOriginal.filter(user => user.full_name.toLowerCase().includes(filter) || user.email.toLowerCase().includes(filter));
      this.setState({
        users:filteredList
      });
    } else {
      this.setState({
        users:this.state.usersOriginal
      });
    }
    this.setState({
      searchName:e.target.value
    });
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (user) => {
    console.log(user);
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<UserQuickview addHandler={this.addUser} closeHandler={this.closeHandler} user={user} sys_user_role_list={this.state.sys_user_role_list} sys_status_list={this.state.sys_status_list}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  render() {
    const {searchName, loading} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Users</h2>
              <div className="btn-group-vertical">
                <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add User</button>
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadUsers}><i className="fa fa-refresh"/> Refresh</button>
              </div>
              <hr/>
              <p><small className="text-uppercase">User Status</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.statusFilter("all")}>
                  <input type="radio" name="donor_filter" value="all" className="custom-control-input" checked={this.state.statusFilter === 'all'}/>
                  <label className="custom-control-label">All Statuses</label>
                </div>
                {this.state.sys_status_list.map((status, status_index)=>
                  <div key={status_index} className="custom-control custom-radio" onClick={()=>this.statusFilter(status.name)}>
                    <input type="radio" name="donor_filter" value={status.name} className="custom-control-input" checked={this.state.statusFilter === status.name}/>
                    <label className="custom-control-label">{status.name}</label>
                  </div>
                )}
              </React.Fragment>
              </div>
              <hr/>
              <p><small className="text-uppercase">Search</small></p>
              <div className="form-group">
                <input className="form-control" type="text" placeholder="First name, Last name, Email" onChange={this.searchUsers} value={searchName}/>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
            :
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="20%">Name</th>
                  <th width="20%">Email</th>
                  <th width="35%">Roles</th>
                  <th width="15%">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
          {this.state.users.map((user, index)=>
                <tr key={index}>
                  <td>{user.full_name}</td>
                  <td>{user.email}</td>
                  <td>
                    <ul className="list-iconic">
                    {user.role_list && user.role_list.length > 0 ?
                      user.role_list.map((role, role_index)=>
                      <li key={role_index}><i className="icon ti-check"></i>{role.sys_user_role}</li>
                      )
                    : null}
                    </ul>
                  </td>
                  <td>{user.status}</td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(user)}><i className="ti-pencil"></i></a>
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(user)}><i className="ti-menu-alt"></i></a>
                  </td>
                </tr>
          )}
              </tbody>
            </table>
            }
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Users;
