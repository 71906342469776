import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { connectionPost } from '../../utils/connection';
import ReactJson from 'react-json-view'

@inject('userStore')
class Audit extends Component {
  constructor(props){
    super(props);
    this.state = {
      json:{}
    }
  }

  componentDidMount(){
    this.GetTransactions(this.props.match.params.className, this.props.match.params.idValue, this.props.match.params.primaryKey);
  }

  componentWillReceiveProps(nextProps){
    this.GetTransactions(nextProps.match.params.className, nextProps.match.params.idValue, nextProps.match.params.primaryKey);
  }

  GetTransactions = (className, idValue, primaryKey) => {
    connectionPost('txn', {class:className, id:idValue, primary_key:primaryKey}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          json:res.data
        })
      } else {
        alert(res.data.message);
      }
    })
    .catch((error)=>{alert(error.response.statusText);});
  }

  render() {
    return (
      <React.Fragment>
        <main className="main-container">
          <div className="p-30">
            <h2>Audit Trail</h2>
            <ReactJson src={this.state.json ? this.state.json : {}} />
          </div>
          <footer className="site-footer">
            <div className="row">
              <div className="col-md-6">
                <p className="text-center text-md-left">Copyright © 2020 <a href="https://rebiotix.com">Rebiotix</a>. All rights reserved.</p>
              </div>
              <div className="col-md-6">
                <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
                  <li className="nav-item">
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </main>
      </React.Fragment>
    );
  }
}

export default Audit;
