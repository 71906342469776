import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { inject } from 'mobx-react';
import moment from 'moment';
import { connectionGet, connectionDelete } from '../../utils/connection';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../timepicker.css';

@inject('userStore')
class DrugSubstanceModal extends Component {
  // Accounts Payable
  constructor(props){
    super(props);
    this.state = {
      drug_substance:{batch_record_number:'', ds_lot_number:'', manufacture_date:null, expiration_date:null, dhs_id:null, peg_id:null, kit_id:null, dose_list:[], bom_product_recipe_id:8, pooled_sample_number:''},
      recipe:null,
      dose:{id:null, dhs_weight:'', peg_weight:'', eva_bag_weight:'', dose_number:'', bom_storage_id:'', bom_storage:null, is_scrap:null},
      is_edit:false,
      dhs_list:[],
      kit_list:[],
      peg_list:[],
      storage_list:[],
      loading_options:true
    }
  }

  loadOptions = () => {
    connectionGet('bom-options', {is_edit:this.state.drug_substance.id}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          dhs_list:res.data.dhs_list,
          kit_list:res.data.kit_list,
          peg_list:res.data.peg_list,
          storage_list:res.data.storage_list,
          loading_options:false
        })
      } else {
        alert(res.data.message)
      }
    })
    .catch((error)=>{console.log(error.response.statusText);});
  }

  componentDidMount(){
    if (this.props.drug_substance === null){
      this.setState({
        is_edit:false,
        recipe:this.props.recipe
      }, ()=>this.loadOptions())
    } else {
      this.setState({
        is_edit:true,
        drug_substance:this.props.drug_substance,
        recipe:this.props.recipe
      }, ()=>this.loadOptions())
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.drug_substance === null){
      this.setState({
        is_edit:false,
        recipe:nextProps.recipe
      }, ()=>this.loadOptions())
    } else {
      this.setState({
        is_edit:true,
        drug_substance:nextProps.drug_substance,
        recipe:nextProps.recipe
      }, ()=>this.loadOptions())
    }
  }

  handleDateChange = (date) => {
    let updated_obj = Object.assign({}, this.state.drug_substance);
    updated_obj.manufacture_date = date;
    updated_obj.expiration_date = moment(date).add(18, 'M');
    this.setState({drug_substance:updated_obj});
  }

  handleDoseInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.dose);
    const value = e.target.value;
    updated_obj[e.target.name] = value;
    if (e.target.name === 'bom_storage_id'){
      // set the bom_storage object
      let index = this.state.storage_list.findIndex(su => su.id === parseInt(value));
      if (index !== -1){
        updated_obj.bom_storage = this.state.storage_list[index];
      }
    }
    this.setState({dose:updated_obj});
  }

  editDose = (dose, dose_index) => {
    let tmp_dose = Object.assign({}, this.state.drug_substance.dose_list[dose_index]);
    if (tmp_dose){
      this.setState({
        dose:tmp_dose
      });
    } else {
      alert('Dose not found');
    }
  }

  deleteDose = (dose_index) => {
    if (window.confirm('Are you sure you want to delete this dose?')){
      let tmp = Object.assign({}, this.state.drug_substance);
      if (this.state.is_edit){
        let dose = tmp.dose_list[dose_index];
        tmp.dose_list.splice(dose_index, 1);
        if (dose){
          connectionDelete('doses/' + dose.hash_id, {}, {username: this.props.userStore.token})
          .then((res)=>{
            alert(res.data.message);
            this.setState({
              drug_substance:tmp
            });
          })
          .catch((error)=>{console.log(error.response.statusText);});
        } else {
          alert('Cannot find Dose');
        }
      } else {
        tmp.dose_list.splice(dose_index, 1);
        this.setState({
          drug_substance:tmp
        });
      }
    }
  }

  addDose = (is_scrap) => {
    let updated_obj = Object.assign({}, this.state.drug_substance);
    let updated_dose = Object.assign({}, this.state.dose);
    if (updated_dose.dose_number){
      // update the dose
      let index = updated_obj.dose_list.findIndex(su => su.dose_number === updated_dose.dose_number);
      updated_obj.dose_list[index] = updated_dose;
        // remove if (index !== -1) updated_obj.dose_list.splice(index, 1);
    } else {
      // insert new
      let dose_count = updated_obj.dose_list.length + 1;
      let dose_string = '';
      if (dose_count < 10){
        dose_string = dose_count.toString().padStart(2, '0');
      } else {
        dose_string = dose_count.toString();
      }
      updated_dose.dose_number = updated_obj.batch_record_number + '-' + dose_string;
      updated_dose.is_scrap = is_scrap;
      updated_obj.dose_list.push(updated_dose);
    }
    this.setState({drug_substance:updated_obj,dose:{id:null, dhs_weight:'', peg_weight:'', eva_bag_weight:'', dose_number:'', is_scrap:null}});
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.drug_substance);
    const value = e.target.value;
    if (e.target.name === 'dhs_id'){
      // try to get the dhs lot number for setting batch
      let index = this.state.dhs_list.findIndex(x => x.id === parseInt(value));
      if (index > -1){
        let dhs = this.state.dhs_list[index];
        updated_obj.batch_record_number = dhs.rbx_donor_id + '-B' + dhs.rbx_dhs_lot;
        updated_obj.pooled_sample_number = dhs.pooled_sample_number;
      }
      updated_obj[e.target.name] = value;
    } else {
      updated_obj[e.target.name] = value;
    }
    this.setState({drug_substance:updated_obj});
  }


  render() {
    if (!this.state.recipe) return <div>Loading</div>;
    const {closeHandler, addHandler, loading} = this.props;
    const {recipe, drug_substance, dose, is_edit, dhs_list, peg_list, kit_list, loading_options, storage_list} = this.state;
    const isInvalid = drug_substance.manufacture_date == null || drug_substance.expiration_date === null || drug_substance.dhs_id === null || drug_substance.peg_id === null || drug_substance.kit_id === null || drug_substance.dose_list.length <= 0 || loading;
    const isInvalidDose = dose.dhs_weight === '' || dose.peg_weight === '' || dose.eva_bag_weight === '' || dose.storage_freezer === '' || dose.storage_shelf === '' || dose.storage_rack === '';
    return (
      <React.Fragment>
          <div className="modal-header">
            <h4 className="modal-title">{recipe.name}</h4>
            <button type="button" className="close" onClick={closeHandler} aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <div className="form-type-combine">
                  <div className="bg-lighter p-20 b-1 mb-3">
                    <div className="row">
                      <div className="col-12 form-groups-attached">
                        <h6 className="text-light fw-300">{is_edit ? "Edit Batch Information" : "New Batch Information"}</h6>
                        <div className="row">
                          <div className="form-group col-sm-4">
                            <label>DHS Lot Number</label>
                            {drug_substance.id ?
                            <p className="form-control-plaintext">{drug_substance.rbx_lot_number ? drug_substance.rbx_lot_number : ''}</p>
                            :
                            <select name="dhs_id" value={drug_substance.dhs_id} onChange={this.handleInputChange} required className="form-control">
                            {loading_options ? <option>Loading...</option>:
                            <React.Fragment>
                              <option value={null}>Select</option>
                            {dhs_list.length > 0 ? dhs_list.map((dhs, dhs_index)=>
                              <option key={dhs_index} value={dhs.id}>{dhs.rbx_lot_number}</option>
                            ) : null}
                            </React.Fragment>
                            }
                            </select>
                            }
                          </div>
                          <div className="form-group col-sm-4">
                            <label>Pooled Sample #</label>
                            <p className="form-control-plaintext">{drug_substance.pooled_sample_number ? drug_substance.pooled_sample_number : ''}</p>
                          </div>
                          <div className="form-group col-sm-4">
                            <label>Batch Number</label>
                            <p className="form-control-plaintext">{drug_substance.batch_record_number ? drug_substance.batch_record_number : 'calculated field'}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-6">
                            <label>Manufacture Date</label>
                            <div className="d-block">
                            <DatePicker
                              selected={drug_substance.manufacture_date ? moment(drug_substance.manufacture_date).toDate() : null}
                              onChange={(date)=>this.handleDateChange(date)}
                              className="form-control"
                              dateFormat="yyyy-MM-dd"
                            />
                            </div>
                          </div>
                          <div className="form-group col-sm-6">
                            <label>Expiration Date</label>
                            <p className="form-control-plaintext">{drug_substance.expiration_date ? moment(drug_substance.expiration_date).format('YYYY-MM-DD') : 'calculated field'}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-6">
                            <label>PEG/Saline Lot Number</label>
                            <select name="peg_id" value={drug_substance.peg_id} onChange={this.handleInputChange} required className="form-control">
                            {loading_options ? <option>Loading...</option>:
                            <React.Fragment>
                              <option value={null}>Select</option>
                            {peg_list.length > 0 ? peg_list.map((peg, peg_index)=>
                              <option key={peg_index} value={peg.id}>{peg.lot_number}</option>
                            ) : null}
                            </React.Fragment>
                            }
                            </select>
                          </div>
                          <div className="form-group col-sm-6">
                            <label>Manufacturing Kit Lot Number</label>
                            <select name="kit_id" value={drug_substance.kit_id} onChange={this.handleInputChange} required className="form-control">
                            {loading_options ? <option>Loading...</option>:
                            <React.Fragment>
                              <option value={null}>Select</option>
                            {kit_list.length > 0 ? kit_list.map((kit, kit_index)=>
                              <option key={kit_index} value={kit.id}>{kit.lot_number}</option>
                            ) : null}
                            </React.Fragment>
                            }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 form-groups-attached">
                        <h6 className="text-light fw-300">Dose Information</h6>
                        <div className="row">
                          <div className="form-group col-3">
                            <label>DHS Weight</label>
                            <input className="form-control" type="number" step="1" name="dhs_weight" onChange={this.handleDoseInputChange} value={dose.dhs_weight}/>
                          </div>
                          <div className="form-group col-3">
                            <label>PEG Weight</label>
                            <input className="form-control" type="number" step="1" name="peg_weight" onChange={this.handleDoseInputChange} value={dose.peg_weight}/>
                          </div>
                          <div className="form-group col-3">
                            <label>Filled EVA Weight</label>
                            <input className="form-control" type="number" step="1" name="eva_bag_weight" onChange={this.handleDoseInputChange} value={dose.eva_bag_weight}/>
                          </div>
                          <div className="form-group col-3">
                            <label>Freezer Location</label>
                            <select name="bom_storage_id" value={dose.bom_storage_id} onChange={this.handleDoseInputChange} required className="form-control">
                            {loading_options ? <option>Loading...</option>:
                            <React.Fragment>
                              <option value={null}>Select</option>
                            {storage_list.length > 0 ? storage_list.map((storage, storage_index)=>
                              <option key={storage_index} value={storage.id}>{storage.full_name}</option>
                            ) : null}
                            </React.Fragment>
                            }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <div className="btn-group">
                        <button type="button" className="btn btn-sm btn-secondary" onClick={()=>this.setState({dose:{id:null, dhs_weight:'', peg_weight:'', eva_bag_weight:'', dose_number:'', bom_storage_id:'', is_scrap:null}})}>
                          Cancel
                        </button>
                        <button type="button" className="btn btn-sm btn-warning" onClick={()=>this.addDose(true)}><i className="icon fa fa-plus"></i> Scrap Dose</button>
                        <button type="button" disabled={isInvalidDose} className={isInvalidDose ? "btn btn-sm btn-primary disabled" : "btn btn-sm btn-primary"} onClick={()=>this.addDose(null)}><i className={isInvalidDose ? "icon fa fa-plus" : "icon fa fa-save"}></i> Dose {isInvalidDose ? " to List" : null}</button>
                      </div>
                    </div>
                    <h6 className="text-light fw-300">Doses</h6>
                    <table className="table table-bordered table-sm table-striped">
                      <thead>
                        <tr>
                          <td>Dose Number</td>
                          <td>DHS Weight</td>
                          <td>PEG/Saline Weight</td>
                          <td>EVA Filled Bag Weight</td>
                          <td>Storage</td>
                          <td>Actions</td>
                        </tr>
                      </thead>
                      <tbody>
                        {drug_substance.dose_list && drug_substance.dose_list.length > 0 ? drug_substance.dose_list.map((dose, dose_index)=>{
                          if (dose.is_scrap){
                            return (<tr key={dose_index}>
                              <td>{dose.dose_number}</td>
                              <td colSpan="4">SCRAP DOSE</td>
                              <td><button className="btn btn-xs" onClick={()=>this.deleteDose(dose_index)}>Delete</button></td>
                            </tr>);
                          } else {
                            console.log(dose);
                            return (
                              <tr key={dose_index}>
                                <td>{dose.dose_number}</td>
                                <td>{dose.dhs_weight}</td>
                                <td>{dose.peg_weight}</td>
                                <td>{dose.eva_bag_weight}</td>
                                <td>{dose.bom_storage && dose.bom_storage.full_name ? dose.bom_storage.full_name : 'Freezer Not Set'}</td>
                                <td><div className="btn-group"><button className="btn btn-xs" onClick={()=>this.editDose(dose, dose_index)}>Edit</button><button className="btn btn-xs" onClick={()=>this.deleteDose(dose_index)}>Delete</button></div></td>
                              </tr>
                            )
                          }
                        }
                      ) : <tr><td colSpan="6">No Doses</td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-secondary" onClick={closeHandler}>
                Close
              </button>
              <button type="button" disabled={isInvalid} className={isInvalid ? "disabled btn btn-sm btn-primary" : "btn btn-sm btn-primary"} onClick={()=>addHandler(this.state.drug_substance)}>Save Batch</button>
            </div>
          </div>
      </React.Fragment>
    )
  }
}

export default DrugSubstanceModal;


// <div className="form-group col-3">
//   <label>{recipe.unit} Number</label>
//   <input className="form-control col-4" type="number" name="planned_sub_size" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.planned_sub_size : 0} placeholder={recipe.unit}/>
// </div>
// <div className="form-group col-3">
//   <label>Item Number</label>
//   <input className="form-control" type="text" name="item_number" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.material_number : ''} placeholder='Item Number'/>
// </div>
// <div className="form-group col-3">
//   <label>Lot Number</label>
//   <input className="form-control" type="text" name="lot_number" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.lot_number : ''} placeholder='Lot Number'/>
// </div>
// <div className="form-group col-3">
//   <label>Expiration Date</label>
//   <p className="form-control-plaintext">{ps.expiration_date ? ps.expiration_date : 'Expiration Set Automatically'}</p>
// </div>
