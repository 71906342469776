import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

Font.register({
  family: "Cousine",
  src: require('../../img/Cousine-Regular.ttf')
});

Font.register({
  family: "Cousine-Bold",
  src: require('../../img/Cousine-Bold.ttf')
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    flexGrow:0,
    fontFamily : "Cousine",
    justifyContent: 'flex-start'
  },
  pageArea:{
    flexDirection: 'row'
  },
  sectionRow: {
    marginLeft: 10,
    marginRight:10,
    flexDirection: 'row',
    flexGrow: 0,
  },
  section: {
    marginLeft: 10,
    marginRight:10,
    marginTop:10,
    paddingBottom: 0,
    flexGrow: 0,
    flexDirection: 'column'
  },
  signSection: {
    flexGrow: 0,
    flexDirection: 'row',
    width:'50%'
  },
  signSectionCol: {
    flexDirection:'row'
  },
  p: {
    fontSize:10
  },
  heading: {
    fontSize:25,
    marginTop:25+'px'
  },
  address: {
    fontSize:15,
    marginTop:35+'px'
  },
  address2: {
    fontSize:10,
    marginTop:5+'px'
  },
  tableNoBorder: {
    display: "table",
    width: "auto",
    marginBottom:'10px'
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom:'10px'
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableRowBg: {
    backgroundColor:'#e5e5e5',
  },
  tableCol:{
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColWidth10: {
    width: "10%",
  },
  tableColWidth20: {
    width: "20%",
  },
  tableColWidth30: {
    width: "30%",
  },
  tableColWidth40: {
    width: "40%",
  },
  tableColWidth50: {
    width: "50%",
  },
  tableColWidth60: {
    width: "60%",
  },
  tableColWidth70: {
    width: "70%",
  },
  tableColWidth90: {
    width: "90%",
  },
  tableColWidth100: {
    width: "100%",
  },
  tableColEnd: {
    borderRightWidth:1
  },
  tableCellFS20:{
    fontSize:20
  },
  tableCellFS8:{
    fontSize:8
  },
  tableCellTitle: {
    textTransform:'uppercase',
    fontFamily:'Cousine-Bold',
    padding: 2,
    marginTop: 5,
    fontSize: 10
  },
  tableCell: {
    textAlign:"left",
    textTransform:'uppercase',
    padding: 2,
    marginTop: 5,
    fontSize: 10,
    fontFamily : "Cousine"
  },
  tableCellAlignCenter: {
    textAlign:"center"
  },
  tableCellAlignRight:{
    textAlign:'right'
  },
  tableCellUpper: {
    textTransform:"uppercase"
  }
});

@inject('userStore')
class COAPrint extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false
      }
  }

  componentDidMount(){
  }

  render() {
    return (
      <div style={{height:1000+'px'}}>
      <PDFViewer width={100+'%'} height={100+'%'}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCellTitle, styles.tableCellFS20, styles.tableCellAlignCenter]}>MBR5001-01</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth70, {padding:0, margin:0}]}>
                    <View style={styles.tableNoBorder}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColWidth100}>
                          <Text style={[styles.tableCellTitle, styles.tableCellFS20]}>PRODUCT: Microbiota Suspension</Text>
                        </View>
                      </View>
                      <View style={[styles.tableRow, {marginTop:0, paddingTop:0}]}>
                        <View style={styles.tableColWidth100}>
                          <Text style={[styles.tableCell, styles.tableCellFS8, {padding:0, margin:0}]}>(Ratio of 3g PEG3350/Saline Solution per 1g Donor Human Stool, 150g per dose)</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={[styles.tableRow, styles.tableRowBg]}>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={styles.tableCellTitle}>Document Number: 7516</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={styles.tableCellTitle}>REV.: 018</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth50]}>
                    <Text style={styles.tableCellTitle}>Effective Date: 26JUN19</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCol, styles.tableColWidth100]}>
                    <Text style={styles.tableCell}>Batch Number:</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={[styles.section, {marginTop:0}]}>
              <Text style={styles.tableCellTitle}>Expected Dose Yield</Text>
            </View>
            <View style={styles.section}>
              <View style={[{paddingLeft:2, paddingRight:2, paddingTop:5, paddingBottom:5, borderRightWidth:1, borderBottomWidth:1}, styles.table, styles.tableRowBg]}>
                <View style={[styles.tableRow, styles.tableColWidth100, {justifyContent:'flex-start'}]}>
                  <View style={[styles.tableColWidth20, styles.tableRow, {width:'15%', justifyContent:'flex-start', marginLeft:8}]}>
                    <View style={{flexDirection:'row'}}>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', borderRightWidth:0, borderLeftWidth:0, width:'20px', height:'25px'}}></View>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                      <Text style={[styles.p, {marginTop:15}]}>.</Text>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                      <Text style={[styles.p, {marginTop:15}]}>g</Text>
                    </View>
                  </View>
                  <Text style={[styles.tableCellAlignCenter, {width:'5%', marginLeft:3}]}>{"←"}</Text>
                  <View style={[styles.tableColWidth80, styles.tableRow, {justifyContent:'flex-start'}]}>
                    <Text style={[styles.p, {fontSize:9}]}>Weight donor human stool container while still in plastic bag and enter here.</Text>
                  </View>
                </View>
                <View style={[styles.tableRow, styles.tableColWidth100, {justifyContent:'flex-start'}]}>
                  <View style={[styles.tableColWidth20, styles.tableRow, {width:'15%', justifyContent:'flex-start', marginLeft:0}]}>
                    <View style={{flexDirection:'row'}}>
                      <Text style={[styles.p, {fontSize:15, width:'10px'}]}>-</Text>
                      <Text style={[styles.p, {fontSize:15, width:'19px', textAlign:'center'}]}>0</Text>
                      <Text style={[styles.p, {fontSize:15, width:'19px', textAlign:'center'}]}>7</Text>
                      <Text style={[styles.p, {fontSize:15, width:'19px', textAlign:'center'}]}>3</Text>
                      <Text style={[styles.p, {marginTop:5, width:'9px', textAlign:'center'}]}>.</Text>
                      <Text style={[styles.p, {fontSize:15, width:'17px', textAlign:'center'}]}>0</Text>
                      <Text style={[styles.p, {marginTop:5}]}>g</Text>
                    </View>
                  </View>
                  <Text style={[styles.tableCellAlignCenter, {width:'5%', marginLeft:6}]}>{"←"}</Text>
                  <View style={[styles.tableColWidth80, styles.tableRow, {justifyContent:'flex-start'}]}>
                    <Text style={[styles.p, {fontSize:7.3}]}>Subtract avg. wt. of empty container in bag to determine approx. net wt. of donor human stool.</Text>
                  </View>
                </View>
                <View style={[styles.tableRow, styles.tableColWidth100, {justifyContent:'flex-start'}]}>
                  <View style={[styles.tableColWidth20, styles.tableRow, {width:'15%', justifyContent:'flex-start', marginLeft:0}]}>
                    <View style={{flexDirection:'row'}}>
                      <Text style={[styles.p, {fontSize:15, marginTop:5}]}>=</Text>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', borderRightWidth:0, borderLeftWidth:0, width:'20px', height:'25px'}}></View>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                      <Text style={[styles.p, {marginTop:15}]}>.</Text>
                      <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                      <Text style={[styles.p, {marginTop:15}]}>g</Text>
                    </View>
                  </View>
                  <Text style={[styles.p, {width:'10%', marginTop:10, textAlign:'right'}]}>{"÷"}55.0g</Text>
                  <View style={{width:'25%', flexDirection:'row', marginLeft:10}}>
                    <Text style={[styles.p, {fontSize:15, marginTop:5}]}>=</Text>
                    <View style={{borderWidth:1, backgroundColor:'#FFF', borderRightWidth:0, borderLeftWidth:1, width:'20px', height:'25px'}}></View>
                    <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                    <Text style={[styles.p, {marginTop:15}]}>.</Text>
                    <View style={{borderWidth:1, backgroundColor:'#FFF', width:'20px', height:'25px'}}></View>
                  </View>
                  <View style={[styles.tableRow, {justifyContent:'flex-start', width:'45%'}]}>
                    <Text style={[styles.p, {fontSize:9, marginTop:8}]}>Round down to whole number</Text>
                    <Text style={[styles.tableCellAlignCenter, {width:'7%', marginLeft:3}]}>{"→"}</Text>
                    <View style={{borderWidth:1, backgroundColor:'#FFF', width:'100px', height:'25px', textAlign:'right'}}>
                      <Text style={[styles.p, {marginTop:10}]}>doses</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.sectionRow}>
              <View style={styles.signSection}>
                <View style={[styles.signSectionCol, styles.tableColWidth60]}>
                  <View><Text style={styles.p}>Perfomed By:</Text></View>
                  <View style={{width:'50%', borderBottomWidth:1}}></View>
                </View>
                <View style={[styles.signSectionCol, styles.tableColWidth40]}>
                  <View><Text style={styles.p}>Date:</Text></View>
                  <View style={{width:'40%', borderBottomWidth:1}}></View>
                </View>
              </View>
              <View style={styles.signSection}>
                <View style={[styles.signSectionCol, styles.tableColWidth60]}>
                  <View><Text style={styles.p}>Reviewed By:</Text></View>
                  <View style={{width:'50%', borderBottomWidth:1}}></View>
                </View>
                <View style={[styles.signSectionCol, styles.tableColWidth40]}>
                  <View><Text style={styles.p}>Date:</Text></View>
                  <View style={{width:'40%', borderBottomWidth:1}}></View>
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow, styles.tableRowBg}>
                  <View style={[styles.tableCol, styles.tableColWidth100]}>
                    <Text style={[styles.tableCellTitle]}>Materials</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={[styles.tableCol, styles.tableColWidth50, styles.tableRowBg]}>
                    <Text style={[styles.tableCellTitle, styles.tableCellAlignRight]}>Pooled Sample #</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth50]}>
                  </View>
                </View>
                <View style={[styles.tableRow, styles.tableCellAlignCenter]}>
                  <View style={[styles.tableCol, styles.tableRowBg, styles.tableColWidth10]}>
                    <Text style={[styles.tableCellTitle]}>Material Number</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableRowBg, styles.tableColWidth30]}>
                    <Text style={[styles.tableCellTitle]}>Description</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableRowBg, styles.tableColWidth20]}>
                    <Text style={[styles.tableCellTitle]}>Lot#</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableRowBg, styles.tableColWidth20]}>
                    <Text style={[styles.tableCellTitle]}>Expiry</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableRowBg, styles.tableColWidth20, {flexDirection:'column'}]}>
                    <Text style={[styles.tableCellTitle]}>Qty Needed</Text>
                    <Text style={[styles.p, {fontSize:7}]}>(Determined by Expected Yield)</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableCellAlignCenter, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell]}>1001-01</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>Donor Human Stool</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, {paddingTop:4, flexDirection:'column'}]}>
                    <Text style={[styles.p, {fontSize:7, marginBottom:5}]}>Date:</Text>
                    <Text style={[styles.p, {fontSize:7, marginBottom:5}]}>Time:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>50-500g</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableCellAlignCenter, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell]}>3001-01</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>PEG3350/Saline Solution</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, {paddingTop:4, flexDirection:'column'}]}>
                    <Text style={[styles.p, {fontSize:7, marginBottom:5}]}>Date:</Text>
                    <Text style={[styles.p, {fontSize:7, marginBottom:5}]}>Time:</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>150-192g per dose (see Table 1)</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableCellAlignCenter, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30, styles.tableRowBg]}>
                    <Text style={[styles.tableCellTitle]}>Kitted Materials</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.p, {fontSize:7}]}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}></Text>
                  </View>
                </View>
                <View style={[styles.tableRow, styles.tableRowBg]}>
                  <View style={[styles.tableCol, styles.tableColWidth100]}>
                    <Text style={styles.tableCellTitle}>or</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10, styles.tableRowBg]}>
                    <Text style={styles.tableCellTitle}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth90, styles.tableRowBg]}>
                    <Text style={styles.tableCellTitle}>Individual Staged Materials</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2012</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>EVA Bag (Enema Bag), 250ml</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, {paddingTop:4, flexDirection:'column'}]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>1 per dose</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2034</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>Strainer Bag for Seward Stomacher® 400 Seward Part# BA6041/STR</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell]}></Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, {paddingTop:4, flexDirection:'column'}]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>1 per dose</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2033</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>Cryovial, Sterile, 2mL</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>10</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2035</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>Closure Bag for Seward Stomacher® 400 Seward Part# BA6041/CLR</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>1 per dose</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2090</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>Tyvek Shipping Tag, White, 2-3/4 in. X 1-3/8 in.</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>1 per dose</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2161</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>Pipet, Serological, Disposable, Sterile, 25mL</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>1</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2306-01</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>TS864 label stock, 1x0.5" with TR618HZN ribbon</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>22</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth10]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>2423</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth30]}>
                    <Text style={[styles.tableCell]}>Pallet Knife - 150mm Blade, Sterile, HDPE (transfer tool-disposable)</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>1</Text>
                  </View>
                </View>
                <View style={[styles.tableRow]}>
                  <View style={[styles.tableCol, styles.tableColWidth40]}>
                    <Text style={[styles.tableCell]}>Quarantine Status Sticker (Apply to identification tag)</Text>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20, styles.tableRowBg]}>
                  </View>
                  <View style={[styles.tableCol, styles.tableColWidth20]}>
                    <Text style={[styles.tableCell, styles.tableCellAlignCenter]}>1 per dose</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.sectionRow}>
              <View style={styles.signSection}>
                <View style={[styles.signSectionCol, styles.tableColWidth60]}>
                  <View><Text style={styles.p}>Perfomed By:</Text></View>
                  <View style={{width:'50%', borderBottomWidth:1}}></View>
                </View>
                <View style={[styles.signSectionCol, styles.tableColWidth40]}>
                  <View><Text style={styles.p}>Date:</Text></View>
                  <View style={{width:'40%', borderBottomWidth:1}}></View>
                </View>
              </View>
            </View>
            <View style={styles.section}>
              <View style={{flexDirection:'row'}}>
                <View style={[styles.tableColWidth50, styles.tableCellAlignRight]}>
                  <Text style={[styles.tableCellTitle, {fontSize:9, paddingRight:10}]}>Confidential</Text>
                </View>
                <View style={[styles.tableColWidth50]}>
                  <Text style={[styles.tableCellTitle, {fontSize:9}]}>Property of Rebioitix, Inc.</Text>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      </div>
      );
    }
}
export default COAPrint;
