import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import PooledSampleQuickview from './PooledSampleQuickview';
import PooledSampleModalTestResults from './PooledSampleModalTestResults';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class PooledSamples extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        modalVisible:false,
        modalTestVisible:false,
        modalComponent:null,
        quickviewVisible:false,
        psStatusFilter:'all',
        pooled_sample:{id:null, donor_id:0, dhs_list:[], target_weight:0, is_test:0},
        pooled_sample_list:[],
        pooled_sample_listOriginal:[],
        available_dhs_list:[],
        donor_list:[],
        searchName:'',
        dhs_list_count:0,
        loading:true
      }
  }

  componentDidMount(){
    this.psStatusFilter(1);
    this.loadPooledSamples();
  }

  loadFetchAvailablePS = (donor_hash_id) => {
    if (donor_hash_id !== '0'){
      this.setState({
        available_dhs_list:[]
      }, ()=>{
        connectionGet('pooled-samples/'+ donor_hash_id +'/available', {}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              available_dhs_list:res.data.dhs_list
            })
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      });
    }
  }

  loadPooledSamples = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('pooled-samples', {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            pooled_sample_list:res.data.pooled_sample_list,
            pooled_sample_listOriginal:res.data.pooled_sample_list,
            donor_list:res.data.donor_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  addPooledSample = (pooled_sample) => {
    // check if there are less than 10
    if (pooled_sample && pooled_sample.dhs_list.length <= 10){
      if (pooled_sample.hash_id){
        connectionPut('pooled-samples/' + pooled_sample.hash_id, {pooled_sample:pooled_sample}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.modalBackgroundClicked();
            this.loadPooledSamples();
            this.setState({quickviewVisible:false});
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      } else {
        connectionPost('pooled-samples', {pooled_sample:pooled_sample}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.modalBackgroundClicked();
            this.loadPooledSamples();
            this.setState({quickviewVisible:false});
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      }
    } else {
      toast.warning('Only 10 Lots can be added to a Pooled Sample', toastParams);
    }
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.pooled_sample);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let dhs_count = updated_obj.dhs_list.length + 1;
    if (e.target.name === 'target_weight'){
      // make sure we update DHS that has been added to the dhs_list
      let new_dhs_list = updated_obj.dhs_list.map((dhs, dhs_index)=>{dhs.sample_weight = parseInt(e.target.value);return dhs});
      updated_obj[e.target.name] = value;
    } else if (e.target.type !== 'checkbox'){
      if (e.target.name === 'donor_id'){
        // dropdown for donor
        this.loadFetchAvailablePS(value);
        updated_obj.donor_id = value;
        updated_obj.dhs_list = [];
      } else {
        updated_obj[e.target.name] = value;
      }
    } else if (e.target.name === 'is_test') {
      updated_obj[e.target.name] = value;
    } else {
      if (dhs_count > 10 && e.target.checked){
        toast.warning('Only 10 Lots can added to a Pooled Sample', toastParams);
        e.target.checked = false;
      } else {
        if (e.target.name === 'dhs-add'){
          var dhs_id = parseInt(e.target.value);
          let dhs_add = this.state.available_dhs_list.find(su => su.id === dhs_id);
          if (e.target.checked){
            if (dhs_add.is_test){
              if (updated_obj.dhs_list.length === 0 && !updated_obj.dhs_list.includes(dhs_add) && !updated_obj.id){
                dhs_add.sample_weight = this.state.pooled_sample.target_weight;
                updated_obj.dhs_list.push(dhs_add);
                if (dhs_add.is_test){
                  updated_obj.is_test = 1;
                }
              } else {
                alert('Only 1 DHS is allowed for Test Only Pooled Sample');
                e.target.checked = false;
              }
            } else {
              if (updated_obj.is_test && updated_obj.dhs_list.length > 0){
                alert('Only 1 DHS is allowed for Test Only Pooled Sample');
                e.target.checked = false;
                return false;
              }
              if (!updated_obj.is_test && dhs_add.is_test){
                alert('Test Only DHS not allowed');
                return false;
              }
              if (e.target.checked && !updated_obj.dhs_list.includes(dhs_add)){
                dhs_add.sample_weight = this.state.pooled_sample.target_weight;
                updated_obj.dhs_list.push(dhs_add);
                if (dhs_add.is_test){
                  updated_obj.is_test = 1;
                }
              }
            }
          } else {
            var index = updated_obj.dhs_list.map(function(e) { return e.id; }).indexOf(dhs_id);
            if (index !== -1) updated_obj.dhs_list.splice(index, 1);
            if (dhs_add.is_test){
              updated_obj.is_test = null;
            }
          }
          dhs_count = updated_obj.dhs_list.length;
        }
      }
    }
    this.setState({
      pooled_sample:updated_obj,
      dhs_list_count:dhs_count
    })
  }

  handleWeightChange = (e, dhs_id) => {
    let updated_obj = Object.assign({}, this.state.pooled_sample);
    var value = e.target.value;
    if (updated_obj.dhs_list && updated_obj.dhs_list.find(su => su.id === dhs_id)){
      let dhs_add = this.state.available_dhs_list.find(su => su.id === dhs_id);
      dhs_add.sample_weight = value;
    } else {
      alert('Please add this DHS before entering weight');
    }
    this.setState({
      pooled_sample:updated_obj
    })
  }

  openHandler = (pooled_sample) => {
    // get a list of donor's who don't have an open pooled sample
    connectionGet('pooled-samples/donor-list', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          donor_list:res.data.donor_list,
          quickviewVisible:false,
          modalVisible:true,
          pooled_sample:pooled_sample
        }, () => {
          if (this.state.pooled_sample.donor_id !== 0){
            this.loadFetchAvailablePS(this.state.pooled_sample.donor.hash_id);
          }
        })
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  modalBackgroundClicked = () => {
    this.setState({
      modalVisible:false,
      modalTestVisible:false,
      modalComponent:null,
      pooled_sample:{id:null, donor_id:0, dhs_list:[], target_weight:0, is_test:0},
      available_dhs_list:[]
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openQVHandler = (pooled_sample) => {
    this.setState({
      modalVisible:false,
      quickviewVisible:true,
      quickviewComponent:<PooledSampleQuickview submitHandler={this.addPooledSample} closeHandler={this.closeHandler} openTestModal={this.openTestModal} pooled_sample={pooled_sample}/>
    })
  }

  openTestModal = (pooled_sample, pooled_sample_test) => {
    this.setState({
      modalVisible:false,
      quickviewVisible:false,
      modalTestVisible:true,
      modalComponent:<PooledSampleModalTestResults pooled_sample={pooled_sample} modalBackgroundClicked={this.modalBackgroundClicked} addHandler={this.addTestResult} pooled_sample_test={pooled_sample_test}/>
    })
  }

  addTestResult = (pooled_sample_hash_id, pooled_sample_test, testResults, isComplete) => {
    if (pooled_sample_test.test_date){
      pooled_sample_test.test_date = moment(pooled_sample_test.test_date).format('YYYY-MM-DD');
    }
    if (isComplete === true){
      pooled_sample_test.is_complete = 1;
    }
    if (!pooled_sample_test.hash_id){
      connectionPost('pooled-sample-test-results/' + pooled_sample_hash_id, {pooled_sample_test:pooled_sample_test, testResults:testResults}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          if (res.data.hold_message){
            toast.warning(res.data.hold_message, toastParams)
          }
          this.loadPooledSamples();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPut('pooled-sample-test-results/' + pooled_sample_hash_id + '/' + pooled_sample_test.hash_id, {pooled_sample_test:pooled_sample_test, testResults:testResults, is_complete:isComplete}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadPooledSamples();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  psStatusFilter = (filter) => {
    this.setState({
      psStatusFilter:filter
    });
  }

  render() {
    const {searchName, pooled_sample_list, donor_list, available_dhs_list, pooled_sample, loading, psStatusFilter} = this.state;
    const isInvalid = pooled_sample.dhs_list.length === 0 || pooled_sample.donor_id === 0 || pooled_sample.target_weight === 0;
    return (
      <React.Fragment>
        <Modal visible={this.state.modalTestVisible} onClickBackdrop={this.modalBackgroundClicked} dialogClassName='modal-lg'>
          {this.state.modalComponent ? this.state.modalComponent : <div></div>}
        </Modal>
        <Modal visible={this.state.modalVisible} onClickBackdrop={this.modalBackgroundClicked} dialogClassName="modal-lg">
          <div className="modal-header">
            <h4 className="modal-title">{pooled_sample.id !== null ? 'Edit Pooled Sample - ' + pooled_sample.rbx_lot_number: 'Add Pooled Sample'}</h4>
            <button type="button" className="close" onClick={this.modalBackgroundClicked} aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="card form-type-combine">
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-4">
                    <label>Donor</label>
                    {pooled_sample.id !== null && pooled_sample.donor ? <React.Fragment><br/><strong>{pooled_sample.donor.rbx_donor_id}</strong></React.Fragment> :
                    <select className="form-control" name="donor_id" onChange={this.handleInputChange} value={pooled_sample.donor_id}>
                      <option value={0}>Select Donor</option>
                    {donor_list ? donor_list.map((donor, donor_index)=>
                      <option key={donor_index} value={donor.hash_id}>{donor.rbx_donor_id}</option>
                    ):null}
                    </select>
                    }
                  </div>
                  <div className="form-group col-4">
                    <label>Target Sample Weight</label>
                    <input type="number" step="any" className="form-control" disabled={pooled_sample.id !== null ? true : false} name="target_weight" onChange={this.handleInputChange} value={pooled_sample.target_weight}/>
                  </div>
                  <div className="form-group col-4">
                    <label>Test Only</label>
                    {pooled_sample.is_test ? <p className="form-control-plaintext"><span className="badge badge-info">YES</span></p> : <p className="form-control-plaintext"><span className="badge badge-info">NO</span></p>}
                  </div>
                </div>
              </div>
            </form>
            <div>
              <h2>Select DHS {pooled_sample.id !== null ? ' - Adding to ' + pooled_sample.rbx_lot_number : null}</h2>
              <table className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th width="5%">Add</th>
                    <th width="10%">Lot#</th>
                    <th width="10%">Donation Weight</th>
                    <th width="10%">Weight to Sample</th>
                    <th width="15%">Donation Date</th>
                    <th width="10%">Test Only?</th>
                    <th width="15%">Expiration Date</th>
                  </tr>
                </thead>
                <tbody>
                {available_dhs_list && available_dhs_list.length > 0 ?
                  available_dhs_list.map((dhs, dhs_index)=> {
                    let bHide = false;
                    if (!pooled_sample.is_test && pooled_sample.dhs_list.length > 0 && dhs.is_test){
                      bHide = true;
                    }
                    if (!bHide){
                      return (
                        <tr key={dhs_index}>
                          <td>
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" name="dhs-add" checked={pooled_sample && pooled_sample.dhs_list && pooled_sample.dhs_list.find(su => su.id === dhs.id)} id={dhs_index} className="custom-control-input" onChange={this.handleInputChange} value={dhs.id}/>
                              <label className="custom-control-label" htmlFor={dhs_index}>&nbsp;</label>
                            </div>
                          </td>
                          <td>{dhs.rbx_lot_number}</td>
                          <td>{dhs.weight}</td>
                          <td><input className="form-control col-8" disabled type="number" step="any" onChange={(e)=>this.handleWeightChange(e, dhs.id)} name={"sample_weight"} value={dhs.sample_weight && dhs.sample_weight !== pooled_sample.target_weight ? dhs.sample_weight : pooled_sample.target_weight }/></td>
                          <td>{dhs.donation_date}</td>
                          <td>{dhs.is_test ? <span className="badge badge-info">YES</span> : <span className="badge badge-info">NO</span>}</td>
                          <td>{moment(dhs.expiration_date).format('LL')}</td>
                        </tr>
                      )
                    }
                  })
                : <tr><td colSpan="6">No eligible DHS for this donor</td></tr>}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.modalBackgroundClicked}>Close</button>
            <button type="button" disabled={isInvalid} className={isInvalid ? "btn btn-primary disabled" : "btn btn-primary"} onClick={()=>this.addPooledSample(pooled_sample)}>Save</button>
          </div>
        </Modal>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Pooled Samples</h2>
              <div className="btn-group-vertical">
              {this.props.userStore.hasPermission('dhs.pooled_samples', 2) ?
              <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler({id:null, donor_id:0, dhs_list:[], target_weight:0, is_test:0})}><i className="fa fa-plus"/> Add Pooled Sample</button>
              :null}
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadPooledSamples}><i className="fa fa-refresh"/> Refresh</button>
              </div>
              <hr/>
              <p><small className="text-uppercase">Filter by Status</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.psStatusFilter("all")}>
                  <input type="radio" name="role_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={psStatusFilter === 'all'}/>
                  <label className="custom-control-label">All Pooled Samples</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.psStatusFilter(1)}>
                  <input type="radio" name="role_filter" value={1} className="custom-control-input" onChange={()=>{return false;}} checked={psStatusFilter === 1}/>
                  <label className="custom-control-label">Open Samples</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.psStatusFilter(2)}>
                  <input type="radio" name="role_filter" value={2} className="custom-control-input" onChange={()=>{return false;}} checked={psStatusFilter === 2}/>
                  <label className="custom-control-label">Samples Sent</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.psStatusFilter(3)}>
                  <input type="radio" name="role_filter" value={3} className="custom-control-input" onChange={()=>{return false;}} checked={psStatusFilter === 3}/>
                  <label className="custom-control-label">Results Recorded</label>
                </div>
              </React.Fragment>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :

            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="15%">Pooled Sample #</th>
                  <th width="10%">Donor ID</th>
                  <th width="10%">Open Date</th>
                  <th width="10%">Exp Date</th>
                  <th width="20%">Samples</th>
                  <th width="15%">Status of Sample</th>
                  <th width="10%">Test Only</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
          {pooled_sample_list ? pooled_sample_list.map((pooled_sample, index)=>
            {
              let bShow = false;
              switch (psStatusFilter) {
                case 'all':
                  bShow = true;
                  break;
                case 1:
                  if (!pooled_sample.is_sent || pooled_sample.is_sent === 0){
                    bShow = true;
                  }
                  break;
                case 2:
                  if (pooled_sample.is_sent && pooled_sample.is_sent === 1 && (pooled_sample.results_recorded === null || pooled_sample.results_recorded === 0)){
                    bShow = true;
                  }
                  break;
                case 3:
                  if (pooled_sample.results_recorded){
                    bShow = true;
                  }
                  break;
                case 4:
                  if (pooled_sample.is_sent && pooled_sample.is_sent === 2){
                    bShow = true;
                  }
                  break;
                default:

              }
              if (bShow){
                return (
                  <tr key={index}>
                    <td>{pooled_sample.rbx_lot_number}</td>
                    <td>{pooled_sample.donor.rbx_donor_id}</td>
                    <td>{pooled_sample.create_timestamp ? moment(pooled_sample.create_timestamp).format('YYYY-MM-DD') : 'N/A'}</td>
                    <td>{pooled_sample.expiration_dhs ? moment(pooled_sample.expiration_dhs.expiration_date).format('YYYY-MM-DD') : 'N/A'}</td>
                    <td>
                      {pooled_sample.dhs_full_list ?
                        <ul>
                          {pooled_sample.dhs_full_list.map((dhs, dhs_index)=>
                            <li key={dhs_index}>{dhs.rbx_lot_number}</li>
                          )}
                        </ul>
                      : null}
                    </td>
                    <td>{pooled_sample.results_recorded === 1  ? <span className="badge badge-success">RESULTS RECORDED</span> : pooled_sample.is_sent === 1 ? <span className="badge badge-info">AWAITING RESULTS</span> : pooled_sample.is_sent === 2 ? <span className="badge badge-default">CLOSED</span> : <span className="badge badge-warning">NOT SENT</span>}</td>
                    <td>
                      {pooled_sample.is_test ? <span className="badge badge-default">TEST ONLY</span> : null}
                    </td>
                    <td className="text-right table-actions">
                    {this.props.userStore.hasPermission('dhs.pooled_samples', 2) ?
                      <React.Fragment>
                    {(pooled_sample.is_sent === null || pooled_sample.is_sent === 0) && pooled_sample.is_expired !== 1 && pooled_sample.is_test !== 1 ?
                      <a className="table-action hover-primary" title="Add Samples" href="#" onClick={()=>this.openHandler(pooled_sample)}><i className="ti-plus"></i></a>
                    : null}
                      <a className="table-action hover-primary" title="View Details" href="#" onClick={()=>this.openQVHandler(pooled_sample)}><i className="ti-menu-alt"></i></a>
                      </React.Fragment>
                    :null}
                    {this.props.userStore.hasPermission('dhs.pooled_samples', 3) ?
                      <a className="table-action hover-primary" title="View Audit" href={"/audit/PooledSample/" + pooled_sample.id + "/pooled_sample_id" } target="_blank"><i className="ion-clipboard"></i></a>
                    :null}
                    </td>
                  </tr>
                );
              } else {
                return (null);
              }
            }
          ) : <tr><td colSpan="5">No Pooled Samples Found</td></tr>}
              </tbody>
            </table>
          }
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default PooledSamples;


// {pooled_sample && pooled_sample.dhs_list && pooled_sample.dhs_list.includes(dhs.id)}
//                    <td><input className="form-control col-8" disabled={pooled_sample && pooled_sample.dhs_list && !pooled_sample.dhs_list.find(su => su.id === dhs.id)} type="number" step="any" onChange={(e)=>this.handleWeightChange(e, dhs.id)} name={"sample_weight"} value={dhs.sample_weight && dhs.sample_weight !== pooled_sample.target_weight ? dhs.sample_weight : pooled_sample.target_weight }/></td>
