import React, { Component } from 'react';

class Error extends Component {
  render() {
    const {error} = this.props;
    let msg = "An error occurred";
    if (error === 403) {
      msg = "You do not have permission to view this component or it could not be found.";
    }
    return (
      <React.Fragment>
        <main className="main-container">
          <header className="header bg-ui-general">
            <div className="header-info">
              <h1 className="header-title">
                <strong>{error} Error</strong> Occurred
                <small>{msg}</small>
              </h1>
            </div>
          </header>
        </main>
      </React.Fragment>
    );
  }
}

export default Error;
