import React, { Component } from 'react';
import DHSQuickview from './DHSQuickview';
import DHSStatusDetail from './DHSStatusDetail';
import Modal from 'react-bootstrap4-modal';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class DHSStatus extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'unexpired',
        dhsStatusFilter:'all',
        dhs:null,
        dhs_list:[],
        dhs_listOriginal:[],
        searchName:'',
        sys_dhs_status_list:[],
        loading:true,
        view_type:''
      }
  }

  componentDidMount(){
    this.loadDHS();
  }

  loadDHS = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('donor-human-stools', {search:this.state.searchName, filter:this.state.statusFilter, dhsFilter:this.state.dhsStatusFilter}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            dhs_list:res.data.dhs_list,
            dhs_listOriginal:res.data.dhs_list,
            sys_dhs_status_list:res.data.sys_dhs_status_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  addDHS = (dhs) => {
    if (dhs.hash_id){
      if (dhs.fridge_status_list.length > 0){
        dhs.fridge_status_list.forEach((status)=>{
          status.status_datetime = moment(status.status_datetime).format('YYYY-MM-DD H:mm')
        })
      }
      connectionPut('donor-human-stools/' + dhs.hash_id, {donor_human_stool:dhs}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, dhs:null, view_type:''}, ()=>this.loadDHS());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('donor-human-stools', {donor_human_stool:dhs}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, dhs:null, view_type:''}, ()=>this.loadDHS());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      dhs:null,
      view_type:''
    })
  }

  openHandler = (dhs, view_type) => {
    this.setState({
      quickviewVisible:true,
      view_type:view_type,
      dhs:dhs
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  handleInputChange = (e) => {
    this.setState({
      searchName:e.target.value
    }, ()=>{
      if (this.state.searchName.length > 4){
        connectionGet('donor-human-stools/search', {search:this.state.searchName, filter:this.state.statusFilter, dhsFilter:this.state.dhsStatusFilter}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              dhs_list:res.data.dhs_list
            })
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      }
    })
  }

  handleDateChange = (date, fridge_status, dhs) => {
    // set the dhs date change
    let dhs_list_temp = Object.assign([], this.state.dhs_list);
    let index = dhs_list_temp.findIndex(x => x.id === dhs.id);
    let dhs_temp = dhs_list_temp[index];
    if (dhs_temp){
      let index = dhs_temp.fridge_status_list.findIndex(x => x.sys_fridge_status_id === fridge_status.sys_fridge_status_id);
      fridge_status['status_datetime'] = date;
      if (index > -1){
        // entry already exists for this status
        dhs_temp.fridge_status_list[index] = fridge_status;
      } else {
        dhs_temp.fridge_status_list.push(fridge_status);
      }
      this.setState({
        dhs:dhs_temp,
        dhs_list:dhs_list_temp,
        dhs_listOriginal:dhs_list_temp
      })
    }
  }

  statusFilter = (filter) => {
    this.setState({
      statusFilter:filter
    }, ()=>{
      this.loadDHS();
    })
  }

  dhsStatusFilter = (filter) => {
    this.setState({
      dhsStatusFilter:filter
    }, ()=>{
      this.loadDHS();
    })
  }

  resetDHS = () => {
    this.setState({
      dhs_list:this.state.dhs_listOriginal,
      searchName:''
    })
  }

  render() {
    const {searchName, dhs_list, loading, sys_dhs_status_list, dhs, view_type} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>DHS Status Log</h2>
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDHS}><i className="fa fa-refresh"/> Refresh</button>
              <hr/>
              <p><small className="text-uppercase">Filter by Role</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter("all")}>
                  <input type="radio" name="role_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === 'all'}/>
                  <label className="custom-control-label">All DHS</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(1)}>
                  <input type="radio" name="role_filter" value={1} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === 1}/>
                  <label className="custom-control-label">Ops Receiving</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(2)}>
                  <input type="radio" name="role_filter" value={2} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === 2}/>
                  <label className="custom-control-label">QC Inspection</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(3)}>
                  <input type="radio" name="role_filter" value={3} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === 3}/>
                  <label className="custom-control-label">Ops Receiving</label>
                </div>
              </React.Fragment>
              </div>
              <hr/>
              <p><small className="text-uppercase">Filter by DHS Status</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter("all")}>
                  <input type="radio" name="dhs_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === 'all'}/>
                  <label className="custom-control-label">All Statuses</label>
                </div>
                {this.state.sys_dhs_status_list.map((status, status_index)=>
                  <div key={status_index} className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(status.id)}>
                    <input type="radio" name="dhs_filter" value={status.id} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === status.id}/>
                    <label className="custom-control-label">{status.name}</label>
                  </div>
                )}
              </React.Fragment>
              </div>
              <hr/>
              <p><small className="text-uppercase">Filter by Expiration</small></p>
              <div className="custom-controls-stacked">
                <div className="custom-control custom-radio" onClick={()=>this.statusFilter("unexpired")}>
                  <input type="radio" name="donor_filter" value="unexpired" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.statusFilter === 'unexpired'}/>
                  <label className="custom-control-label">Unexpired DHS</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.statusFilter("all")}>
                  <input type="radio" name="donor_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.statusFilter === 'all'}/>
                  <label className="custom-control-label">All DHS</label>
                </div>
              </div>
              <hr/>
              <p><small className="text-uppercase">Search by Donor ID</small></p>
              <div className="form-group">
                <input className="form-control" type="text" placeholder="Donor ID" value={searchName} onChange={this.handleInputChange}/>
              </div>
              <div className="btn-group pull-right">
                <button className="btn btn-sm btn-secondary" type="button" onClick={this.resetDHS}>Reset</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card p-10">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
          this.state.dhs_list.length > 0 ?
            this.state.dhs_list.map((dhs, index)=>{
              let ops_receiving = null;
              let qc_out = null;
              let qc_in = null;
              if (dhs.fridge_status_list.length > 0){
                ops_receiving = dhs.fridge_status_list.find(x => x.sys_fridge_status_id === 1);
                qc_out = dhs.fridge_status_list.find(x => x.sys_fridge_status_id === 2);
                qc_in = dhs.fridge_status_list.find(x => x.sys_fridge_status_id === 3);
              }
              return (
                <DHSStatusDetail key={index} qc_in={qc_in} qc_out={qc_out} ops_receiving={ops_receiving} dhs={dhs}/>
              );
            })
            :<div className="p-10 text-info">No Results Found</div>
          }
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewVisible ?
            <DHSQuickview addHandler={this.addDHS} closeHandler={this.closeHandler} dhs={dhs} sys_dhs_status_list={sys_dhs_status_list} view_type={view_type} handleDateChange={this.handleDateChange}/>
            :<div>No DHS Loaded</div>
          }
        </div>
      </React.Fragment>
      );
    }
}
export default DHSStatus;
