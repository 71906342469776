import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import PEGSalineModal from './PEGSalineModal';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import DrugSubstanceQuickview from './DrugSubstanceQuickview';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class PEGSaline extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        modalVisible:false,
        modalComponent:null,
        statusFilter:'all',
        recipe:null,
        ps:null,
        ps_list:[],
        ps_listOriginal:[],
        searchName:'',
        sys_bom_status_list:[],
        loading:true,
        quickviewVisible:false,
        ds:null,
        sys_disposition_list:[]
      }
  }

  componentDidMount(){
    this.loadPEGSaline();
  }

  loadPEGSaline = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('boms/9', {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            recipe:res.data.recipe,
            ps_list:res.data.kit_list,
            ps_listOriginal:res.data.kit_list,
            sys_bom_status_list:res.data.sys_bom_status_list,
            sys_disposition_list:res.data.sys_disposition_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  disposition = (kit) => {
    if (kit.product_hash_id){
      delete kit['expiration_date'];
      delete kit['manufacture_date'];
      delete kit['children'];
      delete kit['create_timestamp'];
      delete kit['last_modified'];
      connectionPut('boms/9/' + kit.product_hash_id, {bom:kit}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.closeQV();
          this.loadPEGSaline();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  addManufacturingKits = (ps, batch_record) => {
    if (ps.children){
      ps.children.map((child, i)=>{
        if (child.sys_bom_type_id === 2){
          if (child.expiration_date){
            child.expiration_date = moment(child.expiration_date).format('YYYY-MM-DD')
          }
        } else if (child.sys_bom_type_id === 3) {
          child.children.map((sub_child, i)=>{
            if (sub_child.expiration_date){
              sub_child.expiration_date = moment(sub_child.expiration_date).format('YYYY-MM-DD')
            }
          })
        }
      })
    }
    if (ps.expiration_date){
      ps.expiration_date = moment(ps.expiration_date).format('YYYY-MM-DD H:mm')
    }
    if (ps.product_hash_id){
      connectionPut('boms/9/' + ps.product_hash_id, {bom:ps}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({modalVisible:!this.state.modalVisible, modalComponent:null}, ()=>this.loadPEGSaline());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      if (batch_record.batch_size > 0){
        connectionPost('boms/9', {bom:ps, batch_record:batch_record}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.setState({modalVisible:!this.state.modalVisible, modalComponent:null}, ()=>this.loadPEGSaline());
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      } else {
        alert(ps.unit + ' Must be greater than 0');
      }
    }
  }

  closeHandler = () => {
    this.setState({
      modalVisible:!this.state.modalVisible,
      modalComponent:null
    })
  }

  openHandler = (ps) => {
    this.setState({
      modalVisible:true,
      modalComponent:<PEGSalineModal addHandler={this.addManufacturingKits} closeHandler={this.closeHandler} ps={ps} sys_bom_status_list={this.state.sys_bom_status_list} recipe={this.state.recipe}/>
    })
  }

  openModal = () => {
    this.setState({
      modalVisible:true,
      modalComponent:<PEGSalineModal addHandler={this.addManufacturingKits} closeHandler={this.closeHandler} ps={null} sys_bom_status_list={this.state.sys_bom_status_list} recipe={this.state.recipe}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  handleInputChange = (e) => {
    this.setState({
      searchName:e.target.value
    })
  }

  openQV = (ds) => {
    this.setState({
      quickviewVisible:true,
      ds:ds
    })
  }

  closeQV = () => {
    this.setState({
      quickviewVisible:false,
      ds:null
    })
  }

  render() {
    const {searchName, ps_list, loading, ds, sys_disposition_list} = this.state;
    const columns = [{
      dataField: 'batch_lot_number',
      text: 'Lot#'
    },{
      dataField: 'lot_number',
      text: 'Sublot#'
    },{
      dataField: 'name',
      text: 'Name',
      sort: false
    }, {
      dataField: 'expiration_date',
      text: 'Expiration Date',
      sort: true,
      formatter: (cellContent, row)=>{
        if (row.expiration_date){
          return (
            <span>{moment(row.expiration_date).format('DDMMMYYYY')}</span>
          )
        }
      }
    }, {
      dataField: 'none',
      isDummyField:true,
      text: 'Disposition',
      formatter: (cellContent, row)=>{
        if (row.disposition){
          return (
            <React.Fragment>
            <ul className="list-unstyled mb-0 fs-10">
              <li><small><span className={row.disposition.sys_disposition_id === 1 ? "badge badge-success" : "badge badge-danger"}>{row.disposition.sys_disposition}</span> by {row.disposition.user.full_name} on {moment(row.disposition.create_timestamp).format('ll')}</small></li>
            </ul>
            </React.Fragment>
          )
        }
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
          {this.props.userStore.hasPermission('manufacturing.peg_saline', 2) ?
            <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(row)}><i className="ti-pencil"></i></a>
          : null}
          {this.props.userStore.hasPermission('manufacturing.peg_saline.disposition', 1) ?
            <a className="table-action hover-primary ml-10" href="#" onClick={()=>this.openQV(row)}>QA</a>
          : null}
          {this.props.userStore.hasPermission('manufacturing.peg_saline', 3) ?
            <a className="table-action hover-primary ml-10" title="View Audit" href={"/audit/BOMProduct/" + row.id + "/bom_product_id" } target="_blank"><i className="ion-clipboard"></i></a>
          :null}
          </React.Fragment>
        );
      }
    }];
    return (
      <React.Fragment>
        <Modal visible={this.state.modalVisible} onClickBackdrop={this.closeHandler} dialogClassName="modal-lg">
          {this.state.modalComponent}
        </Modal>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>PEG/Saline</h2>
              <div className="btn-group-vertical">
              {this.props.userStore.hasPermission('manufacturing.peg_saline', 2) ?
              <button className="btn btn-sm btn-success" type="button" onClick={this.openModal}><i className="fa fa-plus"/> Add PEG/Saline</button>
              : null}
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadPEGSaline}><i className="fa fa-refresh"/> Refresh</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card p-10">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
            <BootstrapTable keyField='id' data={ps_list} columns={columns} pagination={paginationFactory()} striped hover headerClasses="thead-light"/>
          }
          </div>
          <br/>
          <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
            {this.state.quickviewVisible ?
              <DrugSubstanceQuickview addHandler={this.disposition} closeHandler={this.closeQV} ds={ds} sys_disposition_list={sys_disposition_list}/>
              :<div>No DS Loaded</div>
            }
          </div>
        </div>
      </React.Fragment>
      );
    }
}
export default PEGSaline;


// <table className="table table-striped table-hover">
//   <thead>
//     <tr>
//       <th width="10%">Lot#</th>
//       <th width="10%">Donor Id</th>
//       <th width="15%">Donation Date</th>
//       <th width="15%">Expiration Date</th>
//       <th width="10%">Weight</th>
//       <th width="20%">Status</th>
//       <th width="15%">PS Assignment</th>
//       <th></th>
//     </tr>
//   </thead>
//   <tbody>
// {ps_list ? ps_list.map((ps, index)=>
//     <tr key={index}>
//       <td>{ps.rbx_lot_number}</td>
//       <td>{ps.rbx_donor_id}</td>
//       <td>{moment(ps.donation_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{moment(ps.expiration_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{ps.weight}</td>
//       <td>{ps.status.sys_ps_status}</td>
//       <td>{ps.pooled_sample_number ? ps.pooled_sample_number : 'NA'}</td>
//       <td className="text-right table-actions">
//         <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(kit)}><i className="ti-pencil"></i></a>
//         <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(kit)}><i className="ti-menu-alt"></i></a>
//       </td>
//     </tr>
// ) : <tr><td colSpan="5">No ManufacturingKits Found</td></tr>}
//   </tbody>
// </table>
