import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import DatePicker from "react-datepicker";
import BootstrapTable from 'react-bootstrap-table-next';
import {Spinner} from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {CSVLink, CSVDownload} from 'react-csv';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datepicker/dist/react-datepicker.css";

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class DonorPayments extends Component {
  constructor(props){
    super(props);
    this.state = {
      dhs_dict:null,
      loading:false,
      filter: {
        start_date: new Date(),
        end_date: new Date()
      }
    }
  }

  componentDidMount(){
  }

  handleDateChange = (date, type) => {
    let tmp = Object.assign({}, this.state.filter);
    tmp[type] = date;
    this.setState({
      filter:tmp
    });
  }

  handleInputChange = (e, dhs) => {
    let temp_obj = Object.assign({}, this.state.dhs_dict);
    const value = e.target.type === 'checkbox' ? 1 : e.target.value;
    // find the dhs in the dict
    let temp_dhs = temp_obj[dhs.rbx_donor_id].find(su => su.id === dhs.id);
    if (e.target.name === 'is_paid'){
      if (value){
        temp_dhs[e.target.name] = parseInt(value);
        if (value === 1){
          let dt = new Date();
          temp_dhs['payment_date'] = moment(dt).format('YYYY-MM-DD');
        }
      } else {
        temp_dhs[e.target.name] = null;
      }
    } else {
      temp_dhs[e.target.name] = value;
    }
    this.setState({
      dhs_dict:temp_obj
    });
  }

  savePayment = (pay, dhs) => {
    let temp_obj = Object.assign({}, this.state.dhs_dict);
    // find the dhs in the dict
    let temp_dhs = temp_obj[dhs.rbx_donor_id].find(su => su.id === dhs.id);
    if (pay){
      temp_dhs.is_paid = 1;
    } else {
      temp_dhs.is_paid = 0;
    }
    console.log(temp_dhs.is_paid)
    connectionPut('donor-payments', {dhs:temp_dhs}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
        temp_dhs.payment_date = res.data.dhs.payment_date;
        temp_dhs.is_paid = parseInt(res.data.dhs.is_paid);
        this.setState({
          dhs_dict:temp_obj
        });
      } else {
        alert(res.data.message);
      }
    })
    .catch((error)=>{console.log('error')});
  }

  fetchDonations = (paid) => {
    this.setState({
      loading:true
    },()=>{
      connectionPost('donor-payments', {paid:paid, filter:this.state.filter}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          // toast.success(res.data.message, toastParams);
          this.setState({
            dhs_dict:res.data.dhs_dict,
            loading:false
          })
        } else {
          alert(res.data.message);
          this.setState({
            loading:false
          })
        }
      })
      .catch((error)=>{console.log(error)});
    })
  }

  savePayments = (dhs) => {
    connectionPost('donor-payments', {dhs_dict:dhs}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
      } else {
        alert(res.data.message);
      }
    })
    .catch((error)=>{console.log('error')});
  }

  render() {
    const {dhs_dict, loading, filter} = this.state;
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
          pauseOnHover={false}
        />
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Donor Payments</h2>
              <div className="form-group">
                <DatePicker
                  selected={filter.start_date ? new Date(filter.start_date) : null}
                  onChange={(date)=>this.handleDateChange(date, 'start_date')}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="form-group">
                <DatePicker
                  selected={filter.end_date ? new Date(filter.end_date) : null}
                  onChange={(date)=>this.handleDateChange(date, 'end_date')}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="btn-group-vertical">
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={()=>this.fetchDonations(false)}><i className="fa fa-refresh"/> Get Unpaid</button>
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={()=>this.fetchDonations(true)}><i className="fa fa-refresh"/> Get Paid</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
           <div className="card card-body p-10">

        {loading ?
              <h4><Spinner color="primary"/> Loading...</h4>
        :
        dhs_dict === null ?
              <h4>Please choose a date range.</h4>
        :
              <React.Fragment>
               {Object.keys(dhs_dict).map((key, index)=>
                 <div key={index} className="">
                   <div className="card card-bordered">
                     <h4 className="card-title bg-light">Donor: {key}</h4>
                     <table className="table table-striped">
                        <thead>
                          <tr>
                            <th width="25%">Donation Date</th>
                            <th width="25%">Lot #</th>
                            <th width="15%">Weight</th>
                            <th width="20%">Recorded Date</th>
                            <th width="15%">Pay?</th>
                          </tr>
                        </thead>
                        <tbody>
                         {dhs_dict[key].map((dhs, dhs_index) => {
                           console.log(dhs.original_weight);
                           if (dhs.original_weight && parseInt(dhs.original_weight) > 0){
                             return (
                               <tr key={dhs_index}>
                                 <td>{moment(dhs.donation_date).format('ll')}</td>
                                 <td>{dhs.rbx_lot_number}</td>
                                 <td>{dhs.original_weight}</td>
                                 <td>{dhs.payment_date ? moment(dhs.payment_date).format('LL') : null}</td>
                                 <td>
                                   <div className="btn-group pull-right">
                                     <button className={dhs.is_paid === 1 ? "btn btn-sm btn-success" : "btn btn-sm"} onClick={()=>this.savePayment(true, dhs)}>Pay</button>
                                     <button className={dhs.is_paid === 0 ? "btn btn-sm btn-danger" : "btn btn-sm"} onClick={()=>this.savePayment(false, dhs)}>Don't Pay</button>
                                   </div>
                                 </td>
                               </tr>
                             );
                           }
                         }
                         )}
                       </tbody>
                     </table>
                     <div className="card-footer">
                      <CSVLink className="btn btn-primary pull-right" data={dhs_dict[key]} >Download Donor Report</CSVLink>
                     </div>
                   </div>
                 </div>
                )}
              </React.Fragment>
          }
          </div>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default DonorPayments;
