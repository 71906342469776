import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import DoseQuickview from './DoseQuickview';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Doses extends Component {
  constructor(props){
      super(props);
      this.state = {
        dose_list:[],
        dose_listOriginal:[],
        dose:null,
        filter:'',
        quickviewVisible:false,
        sys_disposition_list:[],
        sys_dose_status_list:[],
        bom_storage_list:[]
      }
  }

  componentDidMount(){
    this.loadDoses();
  }

  loadDoses = () => {
    connectionGet('doses', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          dose_list:res.data.dose_list,
          dose_listOriginal:res.data.dose_list,
          sys_dose_status_list:res.data.sys_dose_status_list,
          sys_disposition_list:res.data.sys_disposition_list,
          bom_storage_list:res.data.bom_storage_list
        })
      } else {
        alert(res.data.message)
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  addDose = (dose) => {
    if (dose.expiration_date){
      dose.expiration_date = moment(dose.expiration_date).format('YYYY-MM-DD')
    }
    if (dose.shipped_date){
      dose.shipped_date = moment(dose.shipped_date).format('YYYY-MM-DD')
    }
    if (dose.id){
      connectionPut('doses/' + dose.hash_id, {dose:dose}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.closeQV();
          this.loadDoses();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      toast.warning('Only existing doses can be updated', toastParams);
    }
  }

  closeQV =() => {
    this.setState({
      quickviewVisible:false
    })
  }

  editDose = (dose) => {
    this.setState({
      quickviewVisible:true,
      dose:dose
    })
  }

  render() {
    const {filter, dose_list, dose, quickviewVisible, sys_disposition_list, bom_storage_list, sys_dose_status_list} = this.state;
    const columns = [{
      dataField: 'batch_number',
      text: 'Batch Number'
    }, {
      dataField: 'dose_number',
      text: 'Dose Number',
      sort: false
    }, {
      dataField: 'dhs_weight',
      text: 'Stool Mass',
      sort: false,
      formatter: (cellContent, row) => {
        if (row.is_scrap){
          return (<span className="text-danger">IN PROCESS SCRAP</span>)
        } else{
          return (<span>{row.dhs_weight}</span>)
        }
      }
    }, {
      dataField: 'peg_weight',
      text: 'Total PEG/Saline',
      sort: true
    }, {
      dataField: 'eva_bag_weight',
      text: 'Filled EVA Bag'
    }, {
      dataField: 'sys_dose_status',
      text: 'Status',
      formatter: (cellContent, row)=>{
        if (row.is_scrap){
          return (
            null
          )
        } else {
          return (<span>{row.sys_dose_status}</span>)
        }
      }
    },{
      dataField: 'none',
      isDummyField:true,
      text: 'Disposition',
      formatter: (cellContent, row)=>{
        if (row.disposition && !row.is_scrap){
          return (
            <React.Fragment>
            <ul className="list-unstyled mb-0 fs-10">
              <li><small><span className={row.disposition.sys_disposition_id === 1 ? "badge badge-success" : "badge badge-danger"}>{row.disposition.sys_disposition}</span> by {row.disposition.user.full_name} on {moment(row.disposition.create_timestamp).format('ll')}</small></li>
            </ul>
            </React.Fragment>
          )
        }
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
          <div className="btn-group">
            <button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown">Actions</button>
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={()=>this.editDose(row)}>Manage</button>
            </div>
          </div>
          {this.props.userStore.hasPermission('manufacturing.doses', 3) ?
            <a className="table-action hover-primary ml-10" title="View Audit" href={"/audit/BOMProductDose/" + row.id + "/bom_product_dose_id" } target="_blank"><i className="ion-clipboard"></i></a>
          :null}
          </React.Fragment>
        );
      }
    }];
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Doses</h2>
              <div className="btn-group-vertical">
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDoses}><i className="fa fa-refresh"/> Refresh</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
            <div className="card p-10">
              <BootstrapTable keyField='id' data={dose_list} columns={columns} striped hover headerClasses="thead-light"/>
            </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewVisible ?
            <DoseQuickview addHandler={this.addDose} closeHandler={this.closeQV} dose={dose} sys_disposition_list={sys_disposition_list} bom_storage_list={bom_storage_list} sys_dose_status_list={sys_dose_status_list}/>
            :<div>No Dose Loaded</div>
          }
        </div>
      </React.Fragment>
      );
    }
}
export default Doses;


// <table className="table table-striped table-hover">
//   <thead>
//     <tr>
//       <th width="10%">Lot#</th>
//       <th width="10%">Donor Id</th>
//       <th width="15%">Donation Date</th>
//       <th width="15%">Expiration Date</th>
//       <th width="10%">Weight</th>
//       <th width="20%">Status</th>
//       <th width="15%">PS Assignment</th>
//       <th></th>
//     </tr>
//   </thead>
//   <tbody>
// {ds_list ? ds_list.map((kit, index)=>
//     <tr key={index}>
//       <td>{kit.rbx_lot_number}</td>
//       <td>{kit.rbx_donor_id}</td>
//       <td>{moment(kit.donation_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{moment(kit.expiration_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{kit.weight}</td>
//       <td>{kit.status.ds_status}</td>
//       <td>{kit.pooled_sample_number ? kit.pooled_sample_number : 'NA'}</td>
//       <td className="text-right table-actions">
//         <a className="table-action hover-primary" href="#" onClick={()=>this.editDS(kit)}><i className="ti-pencil"></i></a>
//         <a className="table-action hover-primary" href="#" onClick={()=>this.editDS(kit)}><i className="ti-menu-alt"></i></a>
//       </td>
//     </tr>
// ) : <tr><td colSpan="5">No DrugSubstance Found</td></tr>}
//   </tbody>
// </table>
