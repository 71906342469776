import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class BatchRecords extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        br:null,
        br_list:[],
        br_listOriginal:[],
      }
  }

  componentDidMount(){
    this.loadBatchRecords();
  }

  loadBatchRecords = () => {
    connectionGet('batch-records', {filter:this.state.statusFilter}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          br_list:res.data.batch_record_list,
          br_listOriginal:res.data.batch_record_list
        })
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (br) => {
    this.setState({
      quickviewVisible:true,
      quickviewComponent:null
    })
  }

  handleInputChange = (e) => {
  }

  render() {
    const {br_list} = this.state;
    const columns = [{
      dataField: 'batch_record_number',
      text: 'Record Number'
    }, {
      dataField: 'bom_product_list',
      text: 'Item',
      sort: true,
      formatter: (cellContent, row) => {
        if (row.bom_product_list.length > 0)
        return (row.bom_product_list[0].name);
      }
    }, {
      dataField: 'batch_size',
      text: 'Batch Size',
      sort: true
    }, {
      dataField: 'expiration_date',
      text: 'Expiration Date',
      sort: true,
      formatter: (cellContent, row) => {
        return (moment(row.expiration_date).format('YYYY-MM-DD'));
      }
    }, {
      dataField: 'description',
      text: 'Description'
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
            <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(row)}><i className="ti-pencil"></i></a>
            <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(row)}><i className="ti-menu-alt"></i></a>
          </React.Fragment>
        );
      }
    }];
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Batch Records</h2>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card p-10">
            <BootstrapTable keyField='id' data={br_list} columns={columns} pagination={paginationFactory()} striped hover headerClasses="thead-light"/>
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default BatchRecords;
