import React, { Component } from 'react';
import PermissionsQuickview from './PermissionsQuickview';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Permissions extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'all',
        permission:null,
        permissions:[],
        permissionsOriginal:[],
        searchName:'',
        sys_bom_type_list:[],
        sys_status_list:[]
      }
  }

  componentDidMount(){
    this.loadPermissions();
  }

  loadPermissions = () => {
    connectionGet('permissions', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          permissions:res.data.sys_permission_list,
          permissionsOriginal:res.data.sys_permission_list
        })
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  addPermission = (permission) => {
    if (permission.hash_id){
      connectionPut('permissions/' + permission.id, {permission:permission}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadPermissions();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('permissions', {permission:permission}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadPermissions();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (permission) => {
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<PermissionsQuickview addHandler={this.addPermission} closeHandler={this.closeHandler} permission={permission}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }


  render() {
    const {searchName, permissions} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>User Role Permissions</h2>
              <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add Permission</button>
              <hr/>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="20%">Section</th>
                  <th width="20%">Sub Section</th>
                  <th width="20%">Task</th>
                  <th width="30%">Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {permissions ? permissions.map((permission, index)=>
                <tr key={index}>
                  <td>{permission.section}</td>
                  <td>{permission.sub_section}</td>
                  <td>{permission.task}</td>
                  <td>{permission.description}</td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(permission)}><i className="ti-pencil"></i></a>
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(permission)}><i className="ti-menu-alt"></i></a>
                  </td>
                </tr>
              ) : <tr><td colSpan="5">No Permissions Found</td></tr>
              }
              </tbody>
            </table>
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Permissions;
