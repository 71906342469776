import React, { Component } from 'react';
import DonorGroupQuickview from './DonorGroupQuickview';
// import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import {Spinner} from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

class DrawDate extends Component{
  constructor(props){
    super(props);
    this.state = {
      edit:false,
      drawDateObj:null,
      drawDate:new Date(),
      drawSet:false,
      comment:''
    }
  }

  componentDidMount(){
    if (this.props.typeOf === 'current'){
      if (this.props.donorGroup.draw_dates.current_draw && this.props.donorGroup.draw_dates.current_draw.draw_date !== null){
        this.setState({
          drawDate:moment(this.props.donorGroup.draw_dates.current_draw.draw_date).toDate(),
          drawSet:true,
          drawDateObj:this.props.donorGroup.draw_dates.current_draw
        })
      }
    } else if (this.props.typeOf === 'next') {
      if (this.props.donorGroup.draw_dates.next_draw && this.props.donorGroup.draw_dates.next_draw.draw_date !== null){
        this.setState({
          drawDate:moment(this.props.donorGroup.draw_dates.next_draw.draw_date).toDate(),
          drawSet:true,
          drawDateObj:this.props.donorGroup.draw_dates.next_draw
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.donorGroup !== prevProps.donorGroup) {
      if (this.props.typeOf === 'current'){
        if (this.props.donorGroup.draw_dates.current_draw && this.props.donorGroup.draw_dates.current_draw.draw_date !== null){
          this.setState({
            drawDate:moment(this.props.donorGroup.draw_dates.current_draw.draw_date).toDate(),
            drawSet:true,
            drawDateObj:this.props.donorGroup.draw_dates.current_draw
          })
        }
      } else if (this.props.typeOf === 'next') {
        if (this.props.donorGroup.draw_dates.next_draw && this.props.donorGroup.draw_dates.next_draw.draw_date !== null){
          this.setState({
            drawDate:moment(this.props.donorGroup.draw_dates.next_draw.draw_date).toDate(),
            drawSet:true,
            drawDateObj:this.props.donorGroup.draw_dates.next_draw
          })
        }
      }
    }
  }

  handleInputChange = (e) => {
    this.setState({comment:e.target.value});
  }

  handleChange = date => {
    this.setState({
      drawDate: date
    });
  };

  saveDrawDate = () => {
    if (this.state.drawSet && this.state.comment === ''){
      alert('Comment Required to Edit');
    } else {
      this.setState({
        edit:false
      }, ()=>this.props.saveDrawDate(this.props.donorGroup.hash_id, this.state.drawDate, this.props.typeOf, this.state.comment));
    }
  }

  editState = () => {
    if (this.props.typeOf == 'next' && this.props.donorGroup.draw_dates.current_draw && !this.props.donorGroup.draw_dates.next_draw){
      // attempt to set the drawDate to be 6 mo from current (if current is set)
      this.setState({
        edit:true,
        drawDate:moment(this.props.donorGroup.draw_dates.current_draw.draw_date).add(42, 'day').toDate()
      });
    } else {
      this.setState({
        edit:true
      });
    }
  }

  cancel = () => {
    if (this.state.drawSet){
      this.setState({
        edit:false,
        drawDate:moment(this.state.draw_date).toDate()
      })
    } else {
      this.setState({
        edit:false,
        drawDate:new Date()
      })
    }
  }

  render(){
    const {edit, drawDate, drawSet, drawDateObj} = this.state;
    const {typeOf, donorGroup, saveDrawDate} = this.props;
    return (
      <React.Fragment>
        {edit ?
          <React.Fragment>
            <div className="form-group">
              <DatePicker
                selected={drawDate}
                onChange={this.handleChange}
                className="form-control col-6"
                dateFormat="yyyy-MM-dd"
              />
            </div>
            {drawSet ?
            <div className="form-group">
              <input type="text" className="form-control" name="comment" placeholder="Add Comment to Edit" onChange={this.handleInputChange}/>
            </div>
            : null}
            <div className="form-group">
              <div className="btn-group pull-right">
                <button className="btn btn-sm" onClick={()=>this.cancel()}><i className="fa fa-close text-danger"></i></button>
                <button className="btn btn-sm" onClick={()=>this.saveDrawDate()}><i className="fa fa-check text-success"></i></button>
              </div>
            </div>
          </React.Fragment>
          :
            drawSet ? <React.Fragment>{drawDateObj && drawDateObj.comment && drawDateObj.comment !== '' ? <span className="bb-1 border-info cursor-pointer" title={drawDateObj.comment}>{moment(drawDate).format('YYYY-MM-DD')}</span> : moment(drawDate).format('YYYY-MM-DD')} <span onClick={()=>this.editState()}><i className="fa fa-pencil"></i></span></React.Fragment>:
            <React.Fragment>{"Not Set"} <span onClick={()=>this.editState()}><i className="fa fa-calendar"></i></span></React.Fragment>
          }
      </React.Fragment>
    );
  }
}


@inject('userStore')
class DonorGroups extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        donorStatusFilter:'all',
        donor_group:null,
        donor_group_list:[],
        loading:true
      }
  }

  componentDidMount(){
    this.loadDonorGroups();
  }

  loadDonorGroups = () => {
    this.setState({
      loading:true
    }, ()=> {
      connectionGet('donor-groups', {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            donor_group_list:res.data.donor_group_list
          })
        }
      })
      .catch((error)=>{console.log('error')});
    });
  }

  addDonorGroup = (donorGroup) => {
    if (donorGroup.hash_id){
      connectionPut('donor-groups/' + donorGroup.hash_id, {donorGroup:donorGroup}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            quickviewVisible:false,
            quickviewComponent:null
          })
          this.loadDonorGroups();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{console.log('error')});
    } else {
      connectionPost('donor-groups', {donorGroup:donorGroup}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({
            quickviewVisible:false,
            quickviewComponent:null
          })
          this.loadDonorGroups();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{console.log('error')});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (donorGroup) => {
    console.log(donorGroup);
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<DonorGroupQuickview addHandler={this.addDonorGroup} closeHandler={this.closeHandler} donorGroup={donorGroup}/>
    })
  }

  saveDrawDate = (donorGroupHashId, drawDate, typeOf, comment) => {
    connectionPut('donor-groups/' + donorGroupHashId + '/draw-date', {draw_date:moment(drawDate).format('YYYY-MM-DD'), typeOf:typeOf, comment:comment}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
        this.setState({
          donor_group_list:res.data.donor_group_list
        })
      } else {
        toast.error(res.data.message, toastParams);
      }
    })
  }

  render() {
    const {loading} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Donor Groups</h2>
              <div className="btn-group-vertical">
              {this.props.userStore.hasPermission('system_admin.donor_groups', 2) ?
                <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add Donor Group</button>
              : null}
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDonorGroups}><i className="fa fa-refresh"/> Refresh</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
            :
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="15%">Donor Group</th>
                  <th width="25%">Current Draw Date</th>
                  <th width="25%">Next Draw Date</th>
                  <th width="20%">Color</th>
                  <th width="20%">Members</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
          {this.state.donor_group_list.length > 0 ? this.state.donor_group_list.map((donorGroup, index)=>
                <tr key={index}>
                  <td>{donorGroup.name}</td>
                  <td><DrawDate donorGroup={donorGroup} typeOf={'current'} saveDrawDate={this.saveDrawDate}/></td>
                  <td><DrawDate donorGroup={donorGroup} typeOf={'next'} saveDrawDate={this.saveDrawDate}/></td>
                  <td>{donorGroup.color}</td>
                  <td>{donorGroup.donor_list ?
                    <ul>
                    {donorGroup.donor_list.map((donor, dIndex)=>
                      <li key={dIndex}>{donor.rbx_donor_id}</li>
                    )}
                    </ul>
                  : null}</td>
                  <td className="text-right table-actions">
                  {this.props.userStore.hasPermission('system_admin.donor_groups', 2) ?
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(donorGroup)}><i className="ti-pencil"></i></a>
                  : null}
                  </td>
                </tr>
          ) :
            <tr>
              <td colSpan="20"><span className="text-info">No Results Found</span></td>
            </tr>
          }
              </tbody>
            </table>
          }
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default DonorGroups;
