import React, { Component } from 'react';
import StatusQuickview from './StatusQuickview';
import {Spinner} from 'reactstrap';
// import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Status extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        status:null,
        status_list:[],
        donationStatusList:null,
        loading:true
      }
  }

  componentDidMount(){
    this.loadStatus();
  }

  loadStatus = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('status/' +  this.props.statusType, {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            status_list:res.data.status_list,
            donationStatusList:res.data.donation_status_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    })
  }

  addStatus = (status) => {
    if (status.id){
      connectionPut('status/' + this.props.statusType +'/' + status.id, {status:status}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, quickviewComponent:null}, ()=>this.loadStatus());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('status/' + this.props.statusType, {status:status}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, quickviewComponent:null}, ()=>this.loadStatus());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (status) => {
    console.log(status);
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<StatusQuickview addHandler={this.addStatus} closeHandler={this.closeHandler} status={status} statusType={this.props.statusType} donationStatusList={this.state.donationStatusList}/>
    })
  }

  render() {
    const {statusType} = this.props;
    const {loading} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>{statusType} Status</h2>
              <div className="btn-group-vertical">
                <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add {statusType} Status</button>
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadStatus}><i className="fa fa-refresh"/> Refresh</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="65%">Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
          {this.state.status_list.length > 0 ? this.state.status_list.map((status, index)=>
                <tr key={index}>
                  <td>{status.name}</td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(status)}><i className="ti-pencil"></i></a>
                  </td>
                </tr>
          ) :
            <tr>
              <td colSpan="20"><span className="text-info">No Results Found</span></td>
            </tr>
          }
              </tbody>
            </table>
          }
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Status;
