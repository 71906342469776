import React, { Component } from 'react';
import TestQuickview from './TestQuickview';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

@inject('userStore')
class Tests extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'all',
        sys_test:null,
        sys_test_list:[],
        sys_question_list:[]
      }
  }

  componentDidMount(){
    this.loadTests();
  }

  loadTests = () => {
    connectionGet('tests', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          sys_test_list:res.data.sys_test_list,
          sys_question_list:res.data.sys_question_list
        })
      }
    })
    .catch((error)=>{console.log('error')});
  }

  addTest = (sys_test) => {
    console.log(sys_test);
    if (sys_test.id){
      connectionPut('tests/' + sys_test.id, {sys_test:sys_test}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success('Test edited', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
          this.loadTests();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error)=>{console.log('error')});
    } else {
      connectionPost('tests', {sys_test:sys_test}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success('Test added', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
          this.loadTests();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error)=>{console.log('error')});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (sys_test) => {
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<TestQuickview addHandler={this.addTest} closeHandler={this.closeHandler} sys_test={sys_test} sys_question_list={this.state.sys_question_list}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }


  render() {
    const {searchName, sys_test_list} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>System Tests</h2>
              <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add Test</button>
              <hr/>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="20%">Test Name</th>
                  <th width="20%">SOP Number</th>
                  <th width="20%">SOP Revision</th>
                  <th width="20%">Change Comment</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
          {sys_test_list ? sys_test_list.map((sys_test, index)=>
                <tr key={index}>
                  <td>{sys_test.name}</td>
                  <td>{sys_test.sop_number}</td>
                  <td>{sys_test.revision}</td>
                  <td>{sys_test.change_comment}</td>
                  <td>
                    <ul className="list-iconic">
                    {sys_test.children && sys_test.children.length > 0 ?
                      sys_test.children.map((children, children_index)=>
                      <li key={children_index}><i className="icon ti-check"></i>{children.name}</li>
                      )
                    : null}
                    </ul>
                  </td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(sys_test)}><i className="ti-pencil"></i></a>
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(sys_test)}><i className="ti-menu-alt"></i></a>
                  </td>
                </tr>
          ) : <tr><td colSpan="5">No Tests Found</td></tr>}
              </tbody>
            </table>
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Tests;
