import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class PermissionsQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      permission:{id:null, section:'', sub_section:'', task:'', description:''}
    }
  }

  componentDidMount(){
    if (this.props.permission !== null){
      this.setState({
        permission:this.props.permission,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.permission);
  }

  handleSubmitAdd = () => {
    this.props.addHandler(this.state.permission);
    this.setState({
      permission:{id:null, section:'', sub_section:'', task:'', description:''}
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.permission);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_obj[e.target.name] = value;
    this.setState({permission:updated_obj});
  }

  render() {
    if (!this.state.permission) return <div>Loading</div>;
    const {closeHandler} = this.props;
    const {permission} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!permission.id ? 'Add Permission' : permission.section + '::' + permission.sub_section}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="permission-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Section</label>
                    <input required className="form-control" type="text" name="section" onChange={this.handleInputChange} value={permission.section}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">SubSection</label>
                    <input required className="form-control" type="text" name="sub_section" onChange={this.handleInputChange} value={permission.sub_section}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Task</label>
                    <input required className="form-control" type="text" name="task" onChange={this.handleInputChange} value={permission.task}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Description</label>
                    <input required className="form-control" type="text" name="description" onChange={this.handleInputChange} value={permission.description}/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="permission-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-success" onClick={this.handleSubmitAdd}><i className="fa fa-fw fa-save"></i> Save & Add New</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default PermissionsQuickview;
