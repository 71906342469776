import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const Login = () =>
  <div>
    <LoginForm/>
  </div>

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
@withRouter
@observer
class LoginForm extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
        email: '',
        password: '',
        password_new: '',
        password_new_confirm: '',
        error: null,
        forgottenPassword:false,
        resetPassword:false
      };
	}

	onSubmit = (event) => {
  	event.preventDefault();

  	const { email, password, password_new_confirm, password_new, forgottenPassword, resetPassword} = this.state;

  	const { history,} = this.props;
    if (!forgottenPassword && !resetPassword){
      this.props.userStore.get_token(email, password).then((res) => {
        if (!res || (res.response && res.response.status === 401)){
          toast.error("Authenication Error - Incorrect Username/Password or Password has Expired", toastParams);
        } else {
          console.log(this.props.userStore.token);
        }
      });
    } else {
      if (resetPassword){
        // reset password
        // check that new password and confirm are the same
        if (password_new === password_new_confirm){
          this.props.userStore.resetPassword(email, password, password_new, password_new_confirm).then((res) => {
            if (res.response && res.response.status !== 401){
              toast.success('Password Updated', toastParams);
              this.setState({forgottenPassword:!forgottenPassword});
            } else {
              toast.error(res.data.message, toastParams);
            }
          });
        } else {
          toast.error('Passwords must match', toastParams);
        }
      } else {
        // reset password
        toast.error('This function is not enabled', toastParams);
        // this.props.userStore.resetPassword(email).then((res) => {
        //   if (res.data.errors === 0){
        //     toast.success(res.data.message, toastParams);
        //     this.setState({forgottenPassword:!forgottenPassword});
        //   } else {
        //     toast.error(res.data.message, toastParams);
        //   }
        // });
      }
    }
	}

  render() {
	  const {email,password, password_new, password_new_confirm,error,forgottenPassword,resetPassword} = this.state;
    const isInvalid = email === '';
    return (
      <React.Fragment>
  	    <div className="row min-h-fullscreen center-vh p-20 m-0">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />

  	      <div className="col-12">
  	        <div className="card card-shadowed px-50 py-30 w-400px mx-auto" style={{maxWidth: 100 + '%'}}>
              <div className="text-center">
                <img src={require('../../img/rebiotix.logo2.png')} style={{height:55+'px', width:200+'px'}} className="mb-20"/>
              </div>
  	          <br/>
  	          { error && <p>{error.message}</p> }
  	          <form className={this.state.email !== '' ? "form-type-material do-float" : "form-type-material"} onSubmit={this.onSubmit}>
  				      <div className="form-group m-form__group">
  	              <input type="text" className="form-control"
  	              	value={email}
            			onChange={event => this.setState(byPropKey('email', event.target.value))}/>
  	              <label>{'Email'}</label>
  	            </div>
                {!forgottenPassword ?
                <React.Fragment>
                  <div className="form-group">
    	              <input type="password" className="form-control"
    	              	value={password}
              			onChange={event => this.setState(byPropKey('password', event.target.value))}/>
    	              <label>{'Password'}</label>
    	            </div>
                  {resetPassword ?
                  <React.Fragment>
                    <div className="form-group">
      	              <input type="password" className="form-control"
      	              	value={password_new}
                			onChange={event => this.setState(byPropKey('password_new', event.target.value))}/>
      	              <label>{'New Password'}</label>
      	            </div>
                    <div className="form-group">
      	              <input type="password" className="form-control"
      	              	value={password_new_confirm}
                			onChange={event => this.setState(byPropKey('password_new_confirm', event.target.value))}/>
      	              <label>{'Confirm New Password'}</label>
      	            </div>
      	            <div className="form-group flexbox flex-column flex-md-row">
      	              <a className="cursor-pointer text-muted hover-primary fs-13 mt-2 mt-md-0" onClick={()=>this.setState({resetPassword:!resetPassword})}>{'Sign In!'}</a>
      	            </div>
                  </React.Fragment>
                  :
    	            <div className="form-group flexbox flex-column flex-md-row">
    	              <a className="cursor-pointer text-muted hover-primary fs-13 mt-2 mt-md-0" onClick={()=>this.setState({forgottenPassword:!forgottenPassword})}>{'Forgot password?'}</a><br/>
                    <a className="cursor-pointer text-muted hover-primary fs-13 mt-2 mt-md-0" onClick={()=>this.setState({resetPassword:!resetPassword})}>{'Reset password?'}</a>
    	            </div>}
                </React.Fragment>
                :
                <React.Fragment>
                <p className="small">Your password will be reset and sent in an email.</p>
                  <div className="flexbox flex-column flex-md-row">
                    <p>
                      Know your password? <a className="cursor-pointer text-muted hover-primary fs-13 mt-2 mt-md-0" onClick={()=>this.setState({forgottenPassword:!forgottenPassword})}>{'Sign In!'}</a>
                    </p>
                  </div>
                </React.Fragment>
                }
  	            <div className="form-group">
  	              <button disabled={isInvalid} type="submit" className="btn btn-bold btn-block btn-primary">{!forgottenPassword ? resetPassword ? 'Update Password' : 'Log In' : 'Reset Password'}</button>
  	            </div>
  	          </form>
  	        </div>
  	      </div>
  	      <footer className="col-12 align-self-end text-center fs-13">
  	        <p className="mb-0"><small>{'Copyright © 2020 '}<a href="https://rebiotix.com">Rebiotix.</a>{'. All rights reserved.'}</small></p>
  	      </footer>
  	    </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Login);

export {
  LoginForm,
};
