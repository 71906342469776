import React, { Component } from 'react';
import DonorQuickview from './DonorQuickview';
import Modal from 'react-bootstrap4-modal';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut, connectionFilePost } from '../../../utils/connection';
import moment from 'moment';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Donors extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        donorStatusFilter:'all',
        donor:null,
        donors:[],
        donorsOriginal:[],
        sys_donor_status_list:[],
        donor_group_list:[],
        searchName:'',
        duplicateDonorList:[],
        next_donor_id:'',
        loading:true
      }
  }

  componentDidMount(){
    this.loadDonors();
  }

  loadDonors = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('donors', {for_admin:'1'}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            donors:res.data.donors,
            donorsOriginal:res.data.donors,
            sys_donor_status_list:res.data.sys_donor_status_list,
            donor_group_list:res.data.donor_group_list
          })
        }
      })
      .catch((error)=>{console.log(error);toast.error(error.response.statusText, toastParams);});
    })
  }

  addDonor = (donor) => {
    let reevaluation_date = null;
    if (donor.donorActivity && donor.donorActivity.reevaluation_date){
      donor.donorActivity.reevaluation_date = moment(donor.donorActivity.reevaluation_date).format('YYYY-MM-DD');
    }
    if (donor.hash_id){
      connectionPut('donors/' + donor.hash_id, {donor:donor}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, quickviewComponent:null}, ()=>this.loadDonors());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('donors', {donor:donor}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, quickviewComponent:null}, ()=>this.loadDonors());
        } else {
          toast.error(res.data.message, toastParams);
          if (res.data.donor_list){
            this.setState({
              duplicateDonorList:res.data.donor_list
            })
          }
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  statusFilter = (filter) => {
    var filteredList = [];
    switch (filter) {
      case 'all':
        filteredList = this.state.donorsOriginal;
        break;
      default:
        filteredList = this.state.donorsOriginal.filter(donor => donor.sys_donor_status === filter);
        break;
    }
    this.setState({
      donors:filteredList,
      donorStatusFilter:filter
    })
  }

  searchDonors = (e) => {
    var filteredList = [];
    let filter = e.target.value;
    if (filter.length > 2){
      filteredList = this.state.donorsOriginal.filter(donor => donor.rbx_donor_id.includes(filter));
      this.setState({
        donors:filteredList
      });
    } else {
      this.setState({
        donors:this.state.donorsOriginal
      });
    }
    this.setState({
      searchName:e.target.value
    });
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible
    })
  }

  openHandler = (donor) => {
    connectionGet('donor/next-id', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          quickviewVisible:true,
          next_donor_id:res.data.next_donor_id,
          donor:donor,
          duplicateDonorList:[]
        })
      } else {
        toast.error(res.data.message, toastParams);
      }
    });
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  fileUpload = (donor_hash_id, files) => {
    var formData = new FormData();
    formData.append('file',files[0]);
    connectionFilePost('donor/' + donor_hash_id + '/upload', formData, {username: this.props.userStore.token}
    ).then((res) => {
      if (res.data.errors === 0){
        this.setState({quickviewComponent:null}, ()=>this.openHandler(res.data.donor));
        this.openHandler(res.data.donor);
      } else {
        toast.error(res.data.message, toastParams);
      }
    }).catch((error) => {
        toast.error(error.response.statusText, toastParams);
    });
  }

  createDHS = (donor_hash_id) => {
    connectionPost('donors/' + donor_hash_id + '/create-dhs', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
      } else {
        toast.error(res.data.message, toastParams);
      }
    })
  }

  saveQualification = () => {
    let reevaluation_date = null;
    if (this.state.donorActivity.reevaluation_date){
      reevaluation_date = moment(this.state.donorActivity.reevaluation_date).format('YYYY-MM-DD');
    }
    connectionPost('donors/' + this.state.donor.hash_id + '/qualification-results', {hold_number:this.state.donorActivity.hold_number, reevaluation_date:reevaluation_date, qualification_result:this.state.donorActivity.qualification_result}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        alert('saved!');
        this.setState({donorActivity:{qualification_result:'',hold_number:'', reevaluation_date:new Date()}})
        this.props.loadDonors();
      }
    })
    .catch((error)=>{console.log('error')});
  }

  render() {
    const {searchName, next_donor_id, donor, duplicateDonorList, loading} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Donors</h2>
              <div className="btn-group-vertical">
                <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add Donor</button>
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDonors}><i className="fa fa-refresh"/> Refresh</button>
              </div>
              <hr/>
              <p><small className="text-uppercase">Donor Qualification Status</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.statusFilter("all")}>
                  <input type="radio" name="donor_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.donorStatusFilter === 'all'}/>
                  <label className="custom-control-label">All Statuses</label>
                </div>
                {this.state.sys_donor_status_list.map((status, status_index)=>
                  <div key={status_index} className="custom-control custom-radio" onClick={()=>this.statusFilter(status.name)}>
                    <input type="radio" name="donor_filter" value={status.name} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.donorStatusFilter === status.name}/>
                    <label className="custom-control-label">{status.name}</label>
                  </div>
                )}
              </React.Fragment>
              </div>
              <hr/>
              <p><small className="text-uppercase">Search</small></p>
              <div className="form-group">
                <input className="form-control" type="text" placeholder="Donor ID" onChange={this.searchDonors} value={searchName}/>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="10%">Donor ID</th>
                  <th width="30%">Create Date</th>
                  <th width="25%">Donor Qualification Status</th>
                  <th width="15%">Donor Donating Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
          {this.state.donors.length > 0 ? this.state.donors.map((donor, index)=>
                <tr key={index}>
                  <td>{donor.rbx_donor_id}</td>
                  <td>{donor.create_timestamp ? moment(donor.create_timestamp).format('LLLL') : null}</td>
                  <td>{donor.sys_donor_status}</td>
                  <td>{donor.sys_donation_status}</td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(donor)}><i className="ti-pencil"></i></a>
                    {this.props.userStore.hasPermission('system_admin.donors', 3) ?
                      <a className="table-action hover-primary ml-10" title="View Audit" href={"/audit/Donor/" + donor.id + "/donor_id" } target="_blank"><i className="ion-clipboard"></i></a>
                    :null}
                  </td>
                </tr>
          ) :
            <tr>
              <td colSpan="20"><span className="text-info">No Results Found</span></td>
            </tr>
          }
              </tbody>
            </table>
            }
          </div>
          <br/>
        </div>
        {!loading ?
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          <DonorQuickview addHandler={this.addDonor} closeHandler={this.closeHandler} donor={donor} donor_group_list={this.state.donor_group_list} sys_donor_status_list={this.state.sys_donor_status_list} nextDonorId={next_donor_id} fileUploadHandler={this.fileUpload} createDHSHandler={this.createDHS} duplicateDonorList={duplicateDonorList}/>
        </div> : null}
      </React.Fragment>
      );
    }
}
export default Donors;
