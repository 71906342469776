import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

class PooledSampleQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      pooled_sample:null
    }
  }

  componentDidMount(){
    if (this.props.pooled_sample !== null){
      this.setState({
        pooled_sample:this.props.pooled_sample,
      })
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.pooled_sample !== null){
      this.setState({
        pooled_sample:nextProps.pooled_sample,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.pooled_sample.dhs_sample_list.length > 0){
      let updated_obj = Object.assign({}, this.state.pooled_sample);
      if (updated_obj.sent_date){
        updated_obj.sent_date = moment(updated_obj.sent_date).format('YYYY-MM-DD');
      }
      this.props.submitHandler(updated_obj);
    } else {
      alert('Must have 1 DHS Sample Assigned');
    }
  }

  handleDateChange = (date) => {
    let updated_obj = Object.assign({}, this.state.pooled_sample);
    updated_obj.sent_date = date;
    updated_obj.is_sent = 1;
    this.setState({
      pooled_sample:updated_obj
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.pooled_sample);
    const value = e.target.type === 'checkbox' ? parseInt(e.target.value) : e.target.value;
    updated_obj[e.target.name] = value;
    this.setState({pooled_sample:updated_obj});
  }

  render() {
    if (!this.state.pooled_sample) return <div>Loading</div>;
    const {closeHandler, openTestModal} = this.props;
    const {pooled_sample} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{pooled_sample.rbx_lot_number}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <div className="media-list media-list-divided media-list-hover">
            {pooled_sample.dhs_sample_list ?
              pooled_sample.dhs_sample_list.map((dhs, dhs_index)=>
                <div className="media" key={dhs_index}>
                  <div className="media-body">
                    <dl className="row">
                      <dt className="col-sm-5">DHS</dt>
                      <dd className="col-sm-7">{dhs.donor_human_stool.rbx_lot_number}</dd>
                      <dt className="col-sm-5">Sample Weight:</dt>
                      <dd className="col-sm-7">{dhs.sample_weight}</dd>
                      <dt className="col-sm-5">Original Weight</dt>
                      <dd className="col-sm-7">{dhs.donor_human_stool.original_weight}</dd>
                    </dl>
                  </div>
                </div>
              )
            : null}
            </div>
            <form className="card form-type-combine" id="dhs-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-6">
                    <div className="form-check">
                      <input className="form-check-input" id="close" type="checkbox" name="is_sent" onChange={this.handleInputChange} value={2} checked={pooled_sample.is_sent && parseInt(pooled_sample.is_sent) === 2}/>
                      <label className="form-check-label" htmlFor="close">Close Sample</label>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label>Date Sent</label>
                    <DatePicker
                      selected={pooled_sample.sent_date ? moment(pooled_sample.sent_date).toDate() : null}
                      onChange={this.handleDateChange}
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <label className="required">Fairview REQ #</label>
                    {pooled_sample.pooled_sample_test && pooled_sample.fairview_req ?
                    <p className="form-control-plaintext">{pooled_sample.fairview_req}</p>
                    :
                    <input type="text" required className="form-control" name="fairview_req" onChange={this.handleInputChange} value={pooled_sample.fairview_req}/>
                    }
                    {pooled_sample.pooled_sample_test && pooled_sample.is_sent === 1 ?
                      <button type="button" className="btn btn-xs btn-primary" onClick={()=>openTestModal(pooled_sample, pooled_sample.pooled_sample_test)}>Enter Test Results</button>
                    : null}
                  </div>
                  <div className="form-group col-6">
                    <label>Ridgeway REQ #</label>
                    {pooled_sample.pooled_sample_test02 && pooled_sample.ridgeway_req ?
                      <p className="form-control-plaintext">{pooled_sample.ridgeway_req}</p>
                    :
                    <input type="text" className="form-control" name="ridgeway_req" onChange={this.handleInputChange} value={pooled_sample.ridgeway_req}/>
                    }
                    {pooled_sample.pooled_sample_test_02 && pooled_sample.is_sent === 1 ?
                      <button type="button" className="btn btn-xs btn-primary" onClick={()=>openTestModal(pooled_sample, pooled_sample.pooled_sample_test_02)}>Enter Test Results</button>
                    : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="dhs-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default PooledSampleQuickview;
