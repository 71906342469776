import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {inject} from 'mobx-react'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

class OpsReceiving extends Component {
  render(){
    const {dhs, handleInputChange, sys_dhs_status_list, handleDateChange, sys_dhs_discard_list} = this.props;
    let fridge_status = {status_datetime:null, sys_fridge_status_id:1}
    if (dhs.fridge_status_list.length > 0){
      let filtered_list = dhs.fridge_status_list.filter(x => x.sys_fridge_status_id === 1);
      if (filtered_list.length > 0){
        fridge_status = filtered_list[0]; // assume it's the first found entry
      }
    }
    return (
    <React.Fragment>
      <label>OPS</label>
      {dhs.sys_dhs_status_id === 1 ?
        <div className="row">
          <div className="form-group col-12">
            <label style={{display:'block'}}>In Fridge</label>
            <DatePicker
              selected={fridge_status.status_datetime ? new Date(fridge_status.status_datetime) : null}
              onChange={(date)=>handleDateChange(date, fridge_status, dhs)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              timeCaption="time"
              className="form-control"
              dateFormat="yyyy-MM-dd HH:mm"
            />
          </div>
        </div>
      : null}
      <div className="row">
        <div className="form-group col-12">
          <label className="required">DHS Discard</label>
          <select required className="form-control" name="sys_dhs_discard_id" onChange={handleInputChange} value={dhs.sys_dhs_discard_id}>
            <option value={null}>Not Discarded</option>
          {sys_dhs_discard_list.map((dhs_discard, dhs_discard_index)=> {
            if (dhs_discard.sys_user_role_id === 5) {
              return (<option key={dhs_discard_index} value={dhs_discard.id}>{dhs_discard.name}</option>);
            }
          })}
          </select>
        </div>
      </div>
    </React.Fragment>
    );
  }
}

@inject('userStore')
class QCInspection extends Component {
  render(){
    const {dhs, handleInputChange, sys_dhs_status_list, handleDateChange, sys_dhs_fail_list, sys_dhs_discard_list} = this.props;
    let fs_out = {status_datetime:null, sys_fridge_status_id:2}
    if (dhs.fridge_status_list.length > 0){
      let filtered_list = dhs.fridge_status_list.filter(x => x.sys_fridge_status_id === 2);
      if (filtered_list.length > 0){
        fs_out = filtered_list[0]; // assume it's the first found entry
      }
    }
    let fs_in = {status_datetime:null, sys_fridge_status_id:3}
    if (dhs.fridge_status_list.length > 0){
      let filtered_list = dhs.fridge_status_list.filter(x => x.sys_fridge_status_id === 3);
      if (filtered_list.length > 0){
        fs_in = filtered_list[0]; // assume it's the first found entry
      }
    }
    return (
    <React.Fragment>
      <label>QC Inspection</label>
      {1 === 0 ?
      <div className="row">
        <div className="form-group col-6">
          <label>Out of Fridge</label>
          <DatePicker
            selected={fs_out.status_datetime ? new Date(fs_out.status_datetime) : null}
            onChange={(date)=>handleDateChange(date, fs_out, dhs)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={1}
            timeCaption="time"
            className="form-control"
            dateFormat="yyyy-MM-dd HH:mm"
          />
        </div>
        <div className="form-group col-6">
          <label>In Fridge</label>
          <DatePicker
            selected={fs_in.status_datetime ? new Date(fs_in.status_datetime) : null}
            onChange={(date)=>handleDateChange(date, fs_in, dhs)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={1}
            timeCaption="time"
            popperPlacement="bottom-end"
            className="form-control"
            dateFormat="yyyy-MM-dd HH:mm"
          />
        </div>
      </div>
      : null}
      <div className="row">
        <div className="form-group col-12">
          <label className="required">Weight</label>
          <input type="number" step="any" required className="form-control" name="original_weight" onChange={handleInputChange} value={dhs.original_weight ? dhs.original_weight : 0}/>
        </div>
        {1 === 0 ?
        <div className="form-group col-6">
          <label className="required">Calculated Weight <small>weight after PS sample</small></label>
          <p className="form-control-plaintext">{dhs.weight}</p>
        </div>
        : null}
      </div>
      <div className="row">
        <div className="form-group col-12">
          <label className="required">Collection Kit Lot #</label>
          <input type="text" className="form-control" name="collection_kit" required onChange={handleInputChange} value={dhs.collection_kit}/>
        </div>
        {1===0 ?
        <div className="form-group col-6">
          <label className="required">Collection Kit</label>
          <select required className="form-control" name="is_collection_kit" onChange={handleInputChange} value={dhs.is_collection_kit}>
            <option value={null}>Select Result</option>
            <option value={1}>Pass</option>
            <option value={0}>Fail</option>
          </select>
        </div>
        : null}
      </div>
      {1===0 ?
      <div className="row">
        <div className="form-group col-6">
          <label className="required">Weight</label>
          <select required className="form-control" name="is_weight" onChange={handleInputChange} value={dhs.is_weight}>
            <option value={null}>Select Result</option>
            <option value={1}>Pass</option>
            <option value={0}>Fail</option>
          </select>
        </div>
        <div className="form-group col-6">
          <label className="required">Formation</label>
          <select required className="form-control" name="is_formation" onChange={handleInputChange} value={dhs.is_formation}>
            <option value={null}>Select Result</option>
            <option value={1}>Pass</option>
            <option value={0}>Fail</option>
          </select>
        </div>
      </div>
      :null}
      {1===0 ?
      <div className="row">
        <div className="form-group col-12">
          <label className="required">Donation Questionnaire</label>
          <select required className="form-control" name="questionnaire_result" onChange={handleInputChange} value={dhs.questionnaire_result}>
            <option value={null}>Select Result</option>
            <option value={1}>Pass</option>
            <option value={0}>Fail</option>
          </select>
        </div>
      </div>
      : null}
      {1===0 ?
      <div className="row">
        <div className="form-group col-6">
          <label className="required">Donor Status</label>
          <p className={dhs.donor_status_change && dhs.donor_status_change === 'No Change' ? "form-control-plaintext" : "form-control.plaintext text-danger"}>{dhs.donor_status_change}</p>
        </div>
        <div className="form-group col-6">
          <label className="required">Donating Status</label>
          <p className={dhs.donating_status_change && dhs.donating_status_change === 'No Change' ? "form-control-plaintext" : "form-control.plaintext text-danger"}>{dhs.donating_status_change}</p>
        </div>
      </div>
      : null}
      {1 === 0 ?
        <React.Fragment>
      <div className="row">
        <div className="form-group col-12 pt-10">
          <label>DHS Inspection Failure List</label>
          {sys_dhs_fail_list.map((sys_dhs_fail, fail_index)=>
            <div className="form-check" key={fail_index}>
              <input className="form-check-input" type="checkbox" name="sys_dhs_fail_id" onChange={handleInputChange} value={sys_dhs_fail.id} id={fail_index} checked={dhs.dhs_fail_list && dhs.dhs_fail_list.filter(e => e.sys_dhs_fail_id === sys_dhs_fail.id).length > 0}/>
              <label className="form-check-label" htmlFor={fail_index}>{sys_dhs_fail.name}</label>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="form-group col-12">
          <label className="required">DHS Status</label>
          <select required className="form-control" name="sys_dhs_status_id" onChange={handleInputChange} value={dhs.status.sys_dhs_status_id}>
          {sys_dhs_status_list.map((dhs_status, dhs_status_index)=>
            <option key={dhs_status_index} value={dhs_status.id}>{dhs_status.name}</option>
          )}
          </select>
        </div>
      </div>
        </React.Fragment>
      : null}
      <div className="row">
        <div className="form-group col-12">
          <label className="required">DHS Status</label>
          <select required className="form-control" name="sys_dhs_discard_id" onChange={handleInputChange} value={dhs.sys_dhs_discard_id}>
            <option value={null}>Select Status</option>
            <option value={0}>OK TO PROCESS FORWARD</option>
          {sys_dhs_discard_list.map((dhs_discard, dhs_discard_index)=> {
            if (dhs_discard.sys_user_role_id === 4) {
              return (<option key={dhs_discard_index} value={dhs_discard.id}>DISCARDED: {dhs_discard.name}</option>);
            }
          })}
          </select>
        </div>
      </div>
      {parseInt(dhs.sys_dhs_discard_id) === 7  ?
        <div className="row">
          <div className="form-group col-12">
            <label className="required">Other Discard Reason</label>
            <input type="text" required className="form-control" name="discard_other" onChange={handleInputChange} value={dhs.discard_other}/>
          </div>
        </div>
      : null}
      {this.props.userStore.is_admin === 1 ?
      <div className="row">
        <div className="form-group col-12">
          <div className="form-check">
            <input className="form-check-input" id="qc_label_reset" type="checkbox" name="qc_label_reset" onChange={handleInputChange} value={null} checked={dhs.qc_label_reset}/>
            <label className="form-check-label" htmlFor="qc_label_reset">Reset QC Label Print</label>
          </div>
        </div>
      </div>
      : null}
    </React.Fragment>
    );
  }
}

class QADisposition extends Component {
  render(){
    const {dhs, handleInputChange, sys_disposition_list} = this.props;
    return (
    <React.Fragment>
      <label>QA Disposition</label>
      <div className="row">
        <div className="form-group col-12">
          <label className="required">Disposition</label>
          <select required className="form-control" name="sys_disposition_id" onChange={handleInputChange} value={dhs.disposition ? dhs.disposition.sys_disposition_id : null}>
            <option value={null}>Select</option>
          {sys_disposition_list.map((disposition, disposition_index)=>
            <option key={disposition_index} value={disposition.id}>{disposition.name}</option>
          )}
          </select>
        </div>
      </div>
      {1 === 0 && dhs.disposition && dhs.disposition.sys_disposition_id && parseInt(dhs.disposition.sys_disposition_id) === 2 ?
        <div className="row">
          <div className="form-group col-12">
            <label className="required">Reject Reason</label>
            <select required className="form-control" name="dhs_disposition_comment" onChange={handleInputChange} value={dhs.disposition && dhs.disposition.reject_reason ? dhs.disposition.reject_reason : null}>
              <option value={null}>Select</option>
            {sys_disposition_list[1].reason_list.map((reason, reason_index)=>
              <option key={reason_index} value={reason}>{reason}</option>
            )}
            </select>
          </div>
        </div>
      : null}
      {dhs.disposition && dhs.disposition.sys_disposition_id && parseInt(dhs.disposition.sys_disposition_id) === 2 ?
        <div className="row">
          <div className="form-group col-12">
            <label className="required">Reject Number</label>
            <input className="form-control" type="text" value={dhs.disposition && dhs.disposition.reject_number ? dhs.disposition.reject_number : ''} name="reject_number" onChange={handleInputChange}/>
          </div>
        </div>
      : null}
    </React.Fragment>
    );
  }
}

@inject('userStore')
class DHSQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      dhs:null,
      changedWeight:false,
      changedFormation:false
    }
  }

  componentDidMount(){
    if (this.props.dhs !== null){
      this.setState({
        dhs:this.props.dhs,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.dhs);
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.dhs);
    let value = e.target.value;
    if (e.target.type === 'checkbox'){
      if (e.target.name === 'sys_dhs_fail_id') {
        let sys_dhs_fail_id = parseInt(e.target.value);
        let index = -1;
        if (updated_obj.dhs_fail_list.length > 0){
          index = updated_obj.dhs_fail_list.findIndex(su => su.sys_dhs_fail_id === sys_dhs_fail_id);
        }
        if (e.target.checked){
          // checked - add if not in the list
          if (index === -1){ // not found
            // add
            updated_obj.dhs_fail_list.push({sys_dhs_fail_id:sys_dhs_fail_id})
          }
        } else {
          // unchecked - remove
          if (index > -1){ // found - remove
            updated_obj.dhs_fail_list.splice(index, 1);
          }
        }
      } else if (e.target.name !== 'qc_label_reset'){
        if (e.target.checked){
          value = 1;
        } else {
          value = 0;
        }
        if (e.target.name === 'is_formation'){
          this.setState({changedFormation:true})
        } else {
          this.setState({changedWeight:true})
        }
      } else {
        if (e.target.checked){
          value = true;
        } else {
          value = false;
        }
      }
    }
    updated_obj[e.target.name] = value;
    if (e.target.name === 'sys_dhs_status_id'){
      updated_obj['status']['sys_dhs_status_id'] = value;
    }
    if (e.target.name === 'sys_disposition_id'){
      if (updated_obj['disposition']){
        updated_obj['disposition']['sys_disposition_id'] = value;
      } else {
        updated_obj['disposition'] = {};
        updated_obj['disposition']['sys_disposition_id'] = value;
      }
    }
    if (e.target.name === 'dhs_disposition_comment'){
      updated_obj['disposition']['reject_reason'] = value;
    }
    if (e.target.name === 'reject_number'){
      updated_obj['disposition']['reject_number'] = value;
    }
    this.setState({dhs:updated_obj});
  }

  render() {
    if (!this.state.dhs) return <div>Loading</div>;
    const {closeHandler, sys_dhs_status_list, view_type, handleDateChange, sys_disposition_list, sys_dhs_fail_list, sys_dhs_discard_list} = this.props;
    const {dhs, changedWeight, changedFormation} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{dhs.rbx_lot_number}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <div className="media-list media-list-divided media-list-hover">
            {view_type === 'DETAIL' ?
              <React.Fragment>
              <div className="media">
                <div className="media-body">
                  <p><strong>Donor</strong></p>
                  <dl className="row">
                    <dt className="col-sm-5">ID</dt>
                    <dd className="col-sm-7">{dhs.rbx_donor_id}</dd>
                    <dt className="col-sm-5">Donor Status</dt>
                    <dd className="col-sm-7">{dhs.sys_donor_status}</dd>
                    <dt className="col-sm-5">Donor Donating Status</dt>
                    <dd className="col-sm-7">{dhs.sys_donation_status}</dd>
                  </dl>
                </div>
              </div>
              <div className="media">
                <div className="media-body">
                  <p><strong>Dates</strong></p>
                  <dl className="row">
                    <dt className="col-sm-5">Donation Date</dt>
                    <dd className="col-sm-7">{moment(dhs.donation_date).format('MM/DD/YYYY h:mm:ss a')}</dd>
                    <dt className="col-sm-5">Expiration Date</dt>
                    <dd className="col-sm-7">{moment(dhs.expiration_date).format('YYYY-MM-DD')}</dd>
                    <dt className="col-sm-5">QC Label Print Date</dt>
                    <dd className="col-sm-7">{dhs.qc_label_datetime ? moment(dhs.qc_label_datetime).format('MM/DD/YYYY h:mm:ss a') : 'Not Printed'}</dd>
                  </dl>
                </div>
              </div>
              <div className="media">
                <div className="media-body">
                  <p><strong>Weight</strong></p>
                  <dl className="row">
                    <dt className="col-sm-5">Original Weight</dt>
                    <dd className="col-sm-7">{dhs.original_weight}</dd>
                    <dt className="col-sm-5">Current Weight</dt>
                    <dd className="col-sm-7">{dhs.weight}</dd>
                  </dl>
                </div>
              </div>
              <div className="media">
                <div className="media-body">
                  <p><strong>Payment Status</strong></p>
                  <dl className="row">
                    <dt className="col-sm-5">Paid?</dt>
                    <dd className="col-sm-7">{dhs.is_paid}</dd>
                    <dt className="col-sm-5">Payment date</dt>
                    <dd className="col-sm-7">{dhs.payment_date}</dd>
                    <dt className="col-sm-5">Comment</dt>
                    <dd className="col-sm-7">{dhs.payment_comment}</dd>
                  </dl>
                </div>
              </div>
              </React.Fragment>
            : null}
            { view_type === 'QA' || view_type == 'DETAIL' ?
              <div className="media">
                <div className="media-body">
                  {dhs.is_discarded && dhs.sys_dhs_discard_id ?
                  <React.Fragment>
                  <p><strong>Discard Reason</strong></p>
                  <dl className="list-unstyled">
                    <dt className="col-sm-12">{dhs.sys_dhs_discard}</dt>
                  </dl>
                  </React.Fragment>
                  : null}
                  <dl>
                  {1 === 0 ?
                    <React.Fragment>
                    <dt className="col-sm-5">Weight?</dt>
                    <dd className="col-sm-7">{dhs.is_weight === null ? <span className="badge badge-default">Awaiting Inspection</span> : changedWeight === false ? dhs.is_weight == 1 ? <span className="badge badge-success">WEIGHT</span> : <span className="badge badge-danger">FAIL</span> : <span className="badge badge-info">Save Changes</span>}</dd>
                    <dt className="col-sm-5">Formation?</dt>
                    <dd className="col-sm-7">{dhs.is_formation === null ? <span className="badge badge-default">Awaiting Inspection</span> : changedFormation === false ? dhs.is_formation == 1 ? <span className="badge badge-success">FORMATION</span> : <span className="badge badge-danger">FAIL</span> : <span className="badge badge-info">Save Changes</span>}</dd>
                    <dt className="col-sm-5">Collection Kit?</dt>
                    <dd className="col-sm-7">{dhs.is_collection_kit === null ? <span className="badge badge-default">Awaiting Inspection</span> :  dhs.is_collection_kit == 1 ? <span className="badge badge-success">PASS</span> : <span className="badge badge-danger">FAIL</span>}</dd>
                    </React.Fragment>
                   : null}
                   </dl>
                </div>
              </div>
            : null }
            </div>
            { view_type !== 'DETAIL' ?
            <form className="card form-type-combine" id="dhs-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
              { view_type === 'QC' ?
                <QCInspection dhs={dhs} handleInputChange={this.handleInputChange} sys_dhs_status_list={sys_dhs_status_list} handleDateChange={handleDateChange} sys_dhs_fail_list={sys_dhs_fail_list} sys_dhs_discard_list={sys_dhs_discard_list}/>
              : null}
              { view_type === 'OPS' ?
                <OpsReceiving dhs={dhs} handleInputChange={this.handleInputChange} sys_dhs_status_list={sys_dhs_status_list} handleDateChange={handleDateChange} sys_dhs_discard_list={sys_dhs_discard_list}/>
              : null}
              { view_type === 'QA' ?
                <QADisposition dhs={dhs} handleInputChange={this.handleInputChange} sys_disposition_list={sys_disposition_list}/>
              : null}
              </div>
            </form>
            : null}
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
          { view_type !== 'DETAIL' ?
            view_type === 'QC' && this.props.userStore.hasPermission('dhs.dhs.inspection', 2) ?
              <button className="btn btn-lg btn-outline btn-block btn-success" form="dhs-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            : view_type === 'OPS' && this.props.userStore.hasPermission('dhs.dhs.processing', 2) ?
              <button className="btn btn-lg btn-outline btn-block btn-success" form="dhs-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            : view_type === 'QA' && this.props.userStore.hasPermission('dhs.dhs.disposition', 2) ?
              <button className="btn btn-lg btn-outline btn-block btn-success" form="dhs-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            : null
          : null}
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default DHSQuickview;


// <p><strong>Inspection Results</strong></p>
// <dl>
// {dhs.dhs_fail_list && dhs.dhs_fail_list.length > 0 ?
//   dhs.dhs_fail_list.map((dhs_fail, d_fail_index)=>
//   <React.Fragment key={d_fail_index}>
//     <li><span className="badge badge-danger">{dhs_fail.sys_dhs_fail.name}</span></li>
//   </React.Fragment>
//   )
// :
//   <React.Fragment>
//     <dt className="col-sm-5">No Failures</dt>
//   </React.Fragment>
// }
