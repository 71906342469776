const nav = [
  {
    id:'system_admin',
    type:'section',
    name:'System Administration',
    permissions:[1, 2, 3],
    links:[
      {
        id:'system_admin.users',
        type:'sub_section',
        name:'Users',
        to:'/admin/users',
        icon:'fa fa-users',
        permissions:[1, 2, 3]
      },{
        id:'system_admin.donors',
        type:'sub_section',
        name:'Donors',
        to:'/admin/donors',
        icon:'fa fa-users',
        permissions:[1, 2, 3]
      },{
        id:'system_admin.donor_groups',
        type:'sub_section',
        name:'Donor Groups',
        to:'/admin/donor-groups',
        icon:'fa fa-users',
        permissions:[1, 2, 3]
      },{
        id:'system_admin.qualification_status',
        type:'sub_section',
        name:'Qualification Status',
        to:'/admin/donor-status',
        icon:'fa fa-cog',
        permissions:[1, 2, 3]
      },{
        id:'system_admin.donating_status',
        type:'sub_section',
        name:'Donating Status',
        to:'/admin/donating-status',
        icon:'fa fa-cog',
        permissions:[1, 2, 3]
      },{
        id:'system_admin.dhs_status',
        type:'sub_section',
        name:'DHS Status',
        to:'/admin/dhs-status',
        icon:'fa fa-cog',
        permissions:[1, 2, 3]
      },{
        id:'system_admin.dose_status',
        type:'sub_section',
        name:'Dose Status',
        to:'/admin/dose-status',
        icon:'fa fa-cog',
        permissions:[1, 2, 3]
      }
    ]
  }, {
    id:'donors',
    type:'section',
    name:'Donors',
    permissions:[1, 2, 3],
    links:[
      {
        id:'donors.donors',
        type:'sub_section',
        name:'Donors',
        to:'/donors',
        icon:'fa fa-users',
        permissions:[1, 2, 3]
      },{
        id:'donors.donor_payments',
        type:'sub_section',
        name:'Donor Payments',
        to:'/donor-payments',
        icon:'fa fa-money',
        permissions:[1, 2, 3]
      }
    ]
  }, {
    id:'dhs',
    type:'section',
    name:'DHS',
    permissions:[1, 2, 3],
    links:[
      {
        id:'dhs.dhs',
        type:'sub_section',
        name:'DHS',
        to:'/dhs',
        icon:'fa fa-cube',
        permissions:[1, 2, 3]
      },{
        id:'dhs.pooled_samples',
        type:'sub_section',
        name:'Pooled Samples',
        to:'/dhs/pooled-samples',
        icon:'fa fa-cubes',
        permissions:[1, 2, 3]
      }
    ]
  }, {
    id:'manufacturing',
    type:'section',
    name:'Manufacturing',
    permissions:[1, 2, 3],
    links:[
      {
        id:'manufacturing.drug_substance',
        type:'sub_section',
        name:'Drug Substance',
        to:'/manufacturing/drug-substance',
        icon:'ion-erlenmeyer-flask',
        permissions:[1, 2, 3]
      },{
        id:'manufacturing.doses',
        type:'sub_section',
        name:'Doses',
        to:'/manufacturing/doses',
        icon:'ion-erlenmeyer-flask',
        permissions:[1, 2, 3]
      },{
        id:'manufacturing.kits',
        type:'sub_section',
        name:'Kits',
        to:'/manufacturing/kits',
        icon:'ion-trash-b',
        permissions:[1, 2, 3]
      },{
        id:'manufacturing.peg_saline',
        type:'sub_section',
        name:'PEG/Saline',
        to:'/manufacturing/peg-saline',
        icon:'fa fa-flask',
        permissions:[1, 2, 3]
      }
    ]
  }, {
    id:'reporting',
    type:'section',
    name:'Reporting',
    permissions:[1, 2, 3],
    links:[
      {
        id:'reporting.donors',
        type:'sub_section',
        name:'Donor Reports',
        to:'/reports/donors',
        icon:'fa fa-bar-chart',
        permissions:[1, 2, 3]
      },
      {
        id:'reporting.manufacturing',
        type:'sub_section',
        name:'Manufacturing Reports',
        to:'/reports/manufacturing',
        icon:'fa fa-bar-chart',
        permissions:[1, 2, 3]
      },
      {
        id:'reporting.shipping',
        type:'sub_section',
        name:'Shipping Reports',
        to:'/reports/shipping',
        icon:'fa fa-bar-chart',
        permissions:[1, 2, 3]
      },
      {
        id:'reporting.ops',
        type:'sub_section',
        name:'Ops Reports',
        to:'/reports/ops',
        icon:'fa fa-bar-chart',
        permissions:[1, 2, 3]
      }
    ]
  }
];

module.exports = nav;
