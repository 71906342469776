import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Dropzone from 'react-dropzone';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import { inject } from 'mobx-react';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

@inject('userStore')
class DonorQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      donor:{id:null, rbx_donor_id:'', sys_donor_status_id:1, sys_donation_status_id:1, donor_group_id:1, ssn:''},
      donorActivity:{qualification_result:'',hold_number:'', reevaluation_date:new Date()},
      sys_donation_status_list:[],
      sys_donor_status_list:[],
      donor_group_list:[],
      thumbEdit:false
    }
  }

  componentDidMount(){
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.donor !== null){
      this.setState({
        donor:nextProps.donor,
        sys_donor_status_list:nextProps.sys_donor_status_list,
        donor_group_list:nextProps.donor_group_list,
        sys_donation_status_list:nextProps.sys_donor_status_list[0].sys_donation_status_list
      },()=>{
        let selectedDonorStatus = this.state.sys_donor_status_list.find(du => du.id === parseInt(this.state.donor.sys_donor_status_id));
        var donationStatusList = selectedDonorStatus.sys_donation_status_list;
        this.setState({sys_donation_status_list:donationStatusList});
      })
    } else {
      let tmpDonor = Object.assign({}, {id:null, rbx_donor_id:'', sys_donor_status_id:1, sys_donation_status_id:1, donor_group_id:1, ssn:''});
      tmpDonor.rbx_donor_id = nextProps.nextDonorId
      this.setState({
        sys_donor_status_list:nextProps.sys_donor_status_list,
        donor_group_list:nextProps.donor_group_list,
        sys_donation_status_list:nextProps.sys_donor_status_list[0].sys_donation_status_list,
        donor:tmpDonor
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.donorActivity.qualification_result !== ''){
      let tmp_donor = Object.assign({}, this.state.donor);
      tmp_donor.donorActivity = this.state.donorActivity;
      this.setState({donor:tmp_donor, donorActivity:{qualification_result:'',hold_number:'', reevaluation_date:new Date()}}, ()=>this.props.addHandler(this.state.donor))
    } else {
      this.props.addHandler(this.state.donor);
    }
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.donor);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_obj[e.target.name] = value;
    if (e.target.name === 'sys_donor_status_id'){
      if (e.target.value === "5"){
        // terminated status - confirm choice
        if (!window.confirm("Are you sure you wish to terminate this donor?")){
          return false;
        }
      }
      let selectedDonorStatus = this.state.sys_donor_status_list.find(du => du.id === parseInt(value));
      var donationStatusList = selectedDonorStatus.sys_donation_status_list;
      updated_obj.sys_donation_status_id = donationStatusList[0].id;
      this.setState({sys_donation_status_list:donationStatusList});
    }
    this.setState({donor:updated_obj});
  }

  handleHoldChange = (e) => {
    let temp_hold = Object.assign({}, this.state.donorActivity);
    let updated_obj = Object.assign({}, this.state.donor);
    const value = e.target.value;
    temp_hold[e.target.name] = value;
    updated_obj[e.target.name] = value;
    this.setState({donorActivity:temp_hold, donor:updated_obj});
  };

  handleHoldDateChange = date => {
    let temp = Object.assign({}, this.state.donorActivity);
    temp.reevaluation_date = date;
    this.setState({
      donorActivity:temp
    });
  };

  fileUpload = (files) => {
    this.props.fileUploadHandler(this.state.donor.hash_id, files);
  }

  onDrop = (files) => {
    this.fileUpload(files)
  }

  onCancel = () => {
  }

  render() {
    if (!this.state.donor) return <div>Loading</div>;
    const {closeHandler, createDHSHandler, duplicateDonorList} = this.props;
    const {donor, sys_donor_status_list, sys_donation_status_list, donor_group_list, thumbEdit, donorActivity} = this.state;
    const dropzone = {
        'position': 'relative',
        'width': '200px',
        'height': '200px',
        'borderWidth': '2px',
        'borderColor': 'rgb(102, 102, 102)',
        'borderStyle': 'dashed',
        'borderRadius': '5px',
        marginTop: '15px',
        marginRight: 'auto',
        marginBottom: 0,
        marginLeft: 'auto',
    };
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer">
            <div className="flexbox px-20 pt-20">
              <label className="toggler text-white">
              </label>
              <a className="text-white fs-20 lh-1" href="#" onClick={closeHandler}><i className="fa fa-close"></i></a>
            </div>
            <div className="card-body text-center pb-10">
              {donor.id ?
                <React.Fragment>
                {donor.img_url ?
                  <img src={donor.img_url} className="avatar avatar-xxl avatar-bordered"/>
                  : null
                }
                {thumbEdit ?
                  <React.Fragment>
                  <Dropzone onDrop={this.onDrop} style={dropzone} multiple={false}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p>click to select a jpeg file</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <br/><button className="btn btn-xs" onClick={()=>this.setState({thumbEdit:false})}>Cancel</button>
                  </React.Fragment>
                  :
                  <React.Fragment>
                  <br/><button className="btn btn-xs" onClick={()=>this.setState({thumbEdit:true})}>Edit</button>
                  </React.Fragment>
                }
                </React.Fragment>
              : null}
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!donor.id ? 'Add Donor' : donor.rbx_donor_id}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="donor-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Donor ID</label>
                    {1 === 0 ?
                      <input required className="form-control" type="text" name="rbx_donor_id" onChange={this.handleInputChange} value={donor.rbx_donor_id}/>
                      :
                      <p className="form-control-plaintext">{donor.rbx_donor_id}</p>
                    }
                  </div>
                  <div className="form-group col-12">
                    <label className="required">SSN (last 6 digits)</label>
                    <InputMask required className="form-control" mask="999999" maskChar=" " name="ssn" onChange={this.handleInputChange} value={donor.ssn ? donor.ssn : ''}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Donor Group</label>
                    <select required className="form-control" name="donor_group_id" onChange={this.handleInputChange} value={donor.donor_group_id}>
                    {donor_group_list.length > 0 ? donor_group_list.map((donor_group, group_index)=>
                      <option key={group_index} value={donor_group.id}>{donor_group.name}</option>
                    ):null}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Qualification Status</label>
                    <select required className="form-control" name="sys_donor_status_id" onChange={this.handleInputChange} value={donor.sys_donor_status_id}>
                    {sys_donor_status_list.length > 0 ? sys_donor_status_list.map((sys_donor_status, status_index)=>
                      <option key={status_index} value={sys_donor_status.id}>{sys_donor_status.name}</option>
                    ):null}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Donor Donating Status</label>
                    <select required className="form-control" name="sys_donation_status_id" onChange={this.handleInputChange} value={donor.sys_donation_status_id}>
                    {sys_donation_status_list.length > 0 ? sys_donation_status_list.map((sys_donation_status, donation_status_index)=>
                      <option key={donation_status_index} value={sys_donation_status.id}>{sys_donation_status.name}</option>
                    ):null}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label>Qualification Results</label>
                    <select className="form-control" name="qualification_result" value={donor.qualification_result ? donor.qualification_result : ''} onChange={this.handleHoldChange}>
                      <option value={null}>Select</option>
                      <option value={1}>Pass</option>
                      <option value={2}>Pass with Hold</option>
                      <option value={0}>Fail</option>
                    </select>
                  </div>
                </div>
                {donorActivity.qualification_result && donorActivity.qualification_result === "2" ?
                  <div className="row">
                    <div className="form-group col-6">
                      <label>Hold #</label>
                      <input type="text" name="hold_number" className="form-control" value={donorActivity.hold_number} onChange={this.handleHoldChange}/>
                    </div>
                    <div className="form-group col-6">
                      <label>Re-evaluation Date</label>
                      <DatePicker
                        selected={moment(donorActivity.reevaluation_date).toDate()}
                        onChange={this.handleHoldDateChange}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                      />
                    </div>
                  </div>
                : null}
              </div>
            </form>
            {duplicateDonorList.length > 0 ?
              <div className="card card-outline-warning">
                <header className="card-header">
                  <h4 className="card-title"><strong>Duplcate Donor Records</strong></h4>
                </header>
                <div className="card-body">
                {duplicateDonorList.map((donor, d_index)=>
                  <React.Fragment key={d_index}>
                    <h5>{donor.rbx_donor_id}</h5>
                    <ul>
                      {donor.donor_activity_list ? donor.donor_activity_list.map((activity, a_index)=>
                      <li>{activity.sys_donor_activity_type} - {activity.description}</li>):null}
                    </ul>
                  </React.Fragment>
                )}
                </div>
              </div>
            : null}
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="donor-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
            {donor.id ?
            <button className="btn btn-lg btn-outline btn-block btn-primary" type="button" onClick={()=>createDHSHandler(donor.hash_id)}><i className="fa fa-fw fa-cube"></i> Create DHS Donation</button>
             : null}
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default DonorQuickview;
