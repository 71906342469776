import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {inject} from 'mobx-react'

class QADisposition extends Component {
  render(){
    const {ds, handleInputChange, sys_disposition_list} = this.props;
    return (
    <React.Fragment>
      <label>QA Disposition</label>
      <div className="row">
        <div className="form-group col-12">
          <label className="required">Disposition</label>
          <select required className="form-control" name="sys_disposition_id" onChange={handleInputChange} value={ds.disposition ? ds.disposition.sys_disposition_id : null}>
            <option value={null}>Select</option>
          {sys_disposition_list.map((disposition, disposition_index)=>
            <option key={disposition_index} value={disposition.id}>{disposition.name}</option>
          )}
          </select>
        </div>
      </div>
      {ds.disposition && ds.disposition.sys_disposition_id && parseInt(ds.disposition.sys_disposition_id) === 2 ?
        <div className="row">
          <div className="form-group col-12">
            <label className="required">Reject Number</label>
            <input className="form-control" type="text" value={ds.disposition && ds.disposition.reject_number ? ds.disposition.reject_number : ''} name="reject_number" onChange={handleInputChange}/>
          </div>
        </div>
      : null}
    </React.Fragment>
    );
  }
}

@inject('userStore')
class DrugSubstanceQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      ds:null
    }
  }

  componentDidMount(){
    if (this.props.ds !== null){
      this.setState({
        ds:this.props.ds,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.ds);
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.ds);
    let value = e.target.value;
    updated_obj[e.target.name] = value;
    if (e.target.name === 'sys_disposition_id'){
      if (updated_obj['disposition']){
        updated_obj['disposition']['sys_disposition_id'] = value;
      } else {
        updated_obj['disposition'] = {};
        updated_obj['disposition']['sys_disposition_id'] = value;
      }
    }
    if (e.target.name === 'reject_number'){
      updated_obj['disposition']['reject_number'] = value;
    }
    this.setState({ds:updated_obj});
  }

  render() {
    if (!this.state.ds) return <div>Loading</div>;
    const {closeHandler, sys_disposition_list} = this.props;
    const {ds} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{ds.lot_number}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="ds-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <QADisposition ds={ds} handleInputChange={this.handleInputChange} sys_disposition_list={sys_disposition_list}/>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
          {this.props.userStore.hasPermission('manufacturing.drug_substance.disposition', 2) ?
            <button className="btn btn-lg btn-outline btn-block btn-success" form="ds-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
          : null}
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default DrugSubstanceQuickview;
