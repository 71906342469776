import React, { Component } from 'react';
import DHSQuickview from './DHSQuickview';
import Modal from 'react-bootstrap4-modal';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class DHS extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'unexpired',
        dhsStatusFilter:'all',
        dhs:null,
        dhs_list:[],
        dhs_listOriginal:[],
        searchName:'',
        sys_dhs_status_list:[],
        loading:true,
        view_type:'',
        sys_disposition_list:[],
        sys_dhs_discard_list:[],
        sys_dhs_fail_list:[],
        rbx_lot_number_entered:''
      }
  }

  componentDidMount(){
    this.loadDHS();
  }

  loadDHS = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('donor-human-stools', {search:this.state.searchName, filter:this.state.statusFilter, dhsFilter:this.state.dhsStatusFilter}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            dhs_list:res.data.dhs_list,
            dhs_listOriginal:res.data.dhs_list,
            sys_dhs_status_list:res.data.sys_dhs_status_list,
            sys_disposition_list: res.data.sys_disposition_list,
            sys_dhs_fail_list: res.data.sys_dhs_fail_list,
            sys_dhs_discard_list: res.data.sys_dhs_discard_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  addDHS = (dhs) => {
    if (dhs.hash_id){
      if (dhs.fridge_status_list.length > 0){
        dhs.fridge_status_list.forEach((status)=>{
          status.status_datetime = moment(status.status_datetime).format('YYYY-MM-DD H:mm')
        })
      }
      connectionPut('donor-human-stools/' + dhs.hash_id, {donor_human_stool:dhs,view_type:this.state.view_type}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, dhs:null, view_type:''}, ()=>this.loadDHS());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('donor-human-stools', {donor_human_stool:dhs}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.setState({quickviewVisible:!this.state.quickviewVisible, dhs:null, view_type:''}, ()=>this.loadDHS());
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      dhs:null,
      view_type:''
    })
  }

  openHandler = (dhs, view_type) => {
    this.setState({
      quickviewVisible:true,
      view_type:view_type,
      dhs:dhs
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  handleInputChange = (e) => {
    this.setState({
      searchName:e.target.value
    }, ()=>{
      if (this.state.searchName.length > 4){
        connectionGet('donor-human-stools/search', {search:this.state.searchName, filter:this.state.statusFilter, dhsFilter:this.state.dhsStatusFilter}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            this.setState({
              dhs_list:res.data.dhs_list
            })
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      }
    })
  }

  handleDHSInputChange = (e) => {
    this.setState({
      rbx_lot_number_entered:e.target.value,
    })
  }

  handleDHSKeyPress = (e) => {
    if(e.key === 'Enter'){
      if (this.state.rbx_lot_number_entered !== ''){
        // submit for entry in receiving
        this.receiveDHS(this.state.rbx_lot_number_entered);
      } else {
        toast.warning('Scanned Lot Number cannot be blank');
      }
    }
  }

  receiveDHS = (rbx_lot_number_entered) => {
    connectionPost('donor-human-stools/receiving', {rbx_lot_number:rbx_lot_number_entered}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        toast.success(res.data.message, toastParams);
        this.setState({
          rbx_lot_number_entered:''
        },()=>{this.loadDHS();})

      } else {
        toast.error(res.data.message, toastParams);
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  handleDateChange = (date, fridge_status, dhs) => {
    // set the dhs date change
    let dhs_list_temp = Object.assign([], this.state.dhs_list);
    let index = dhs_list_temp.findIndex(x => x.id === dhs.id);
    let dhs_temp = dhs_list_temp[index];
    if (dhs_temp){
      let index = dhs_temp.fridge_status_list.findIndex(x => x.sys_fridge_status_id === fridge_status.sys_fridge_status_id);
      fridge_status['status_datetime'] = date;
      if (index > -1){
        // entry already exists for this status
        dhs_temp.fridge_status_list[index] = fridge_status;
      } else {
        dhs_temp.fridge_status_list.push(fridge_status);
      }
      this.setState({
        dhs:dhs_temp,
        dhs_list:dhs_list_temp,
        dhs_listOriginal:dhs_list_temp
      })
    }
  }

  statusFilter = (filter) => {
    this.setState({
      statusFilter:filter
    }, ()=>{
      this.loadDHS();
    })
  }

  dhsStatusFilter = (filter) => {
    this.setState({
      dhsStatusFilter:filter
    }, ()=>{
      this.loadDHS();
    })
  }

  resetDHS = () => {
    this.setState({
      dhs_list:this.state.dhs_listOriginal,
      searchName:''
    })
  }

  render() {
    const {searchName, dhs_list, loading, sys_dhs_status_list, dhs, view_type, sys_disposition_list, sys_dhs_fail_list, sys_dhs_discard_list, rbx_lot_number_entered, dhsStatusFilter} = this.state;
    const columns = [{
      dataField: 'rbx_lot_number',
      text: 'Lot Number'
    }, {
      dataField: 'rbx_donor_id',
      text: 'ID',
      sort: true
    }, {
      dataField: 'donation_date',
      text: 'Don. Date',
      sort: true,
      formatter: (cellContent, row) => {
        return (moment(row.donation_date).format('MM/DD/YYYY h:mm:ss a'));
      }
    }, {
      dataField: 'expiration_date',
      text: 'Exp. Date',
      sort: true,
      formatter: (cellContent, row) => {
        return (moment(row.expiration_date).format('YYYY-MM-DD'));
      }
    }, {
      dataField: 'weight',
      text: 'Weight'
    }, {
      dataField: 'is_test',
      text: 'Test',
      formatter: (cellContent, row)=>{
        if (row.is_test === 1){
          return (<span className="badge badge-info">TEST</span>);
        }
      }
    }, {
      dataField: 'Status',
      isDummyField:true,
      text: 'Status',
      formatter: (cellContent, row)=>{
        if (row.disposition){
          return (
            <React.Fragment>
            <span>{row.status.sys_dhs_status}</span>
            <ul className="list-unstyled mb-0 fs-10">
              <li><small><span className={row.disposition.sys_disposition_id === 1 ? "badge badge-success" : "badge badge-danger"}>{row.disposition.sys_disposition}</span> by {row.disposition.user.full_name} on {moment(row.disposition.create_timestamp).format('ll')}</small></li>
            </ul>
            </React.Fragment>
          )
        } else {
          return (
            <span>{row.status.sys_dhs_status}</span>
          )
        }
      }
    }, {
      dataField: 'pooled_sample_number',
      text: 'Assigned PS',
      sort: true
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
          <div className="btn-group">
          {
          dhsStatusFilter === 1 ?
            <button className="btn btn-sm btn-default" onClick={()=>this.receiveDHS(row.rbx_lot_number)}>Receive</button>
          :
          dhsStatusFilter === 3 ?
            <button className="btn btn-sm btn-default" onClick={()=>this.openHandler(row, 'OPS')}>Operations</button>
          :
          dhsStatusFilter === 2 && this.props.userStore.hasPermission('dhs.dhs.inspection', 1) ?
            <button className="btn btn-sm btn-default" onClick={()=>this.openHandler(row, 'QC')}>QC Inspection</button>
          :
          dhsStatusFilter === 'qa' ?
            <button className="btn btn-sm btn-default" onClick={()=>this.openHandler(row, 'QA')}>QA Disposition</button>
          :
            <React.Fragment>
              <button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown">Actions</button>
              <div className="dropdown-menu">
                <button className="dropdown-item" onClick={()=>this.openHandler(row, 'DETAIL')}>DHS Details</button>
                {this.props.userStore.hasPermission('dhs.dhs.processing', 2) ?
                <button className="dropdown-item" onClick={()=>this.openHandler(row, 'OPS')}>Operations</button>
                :null}
                {this.props.userStore.hasPermission('dhs.dhs.inspection', 1) ?
                <button className="dropdown-item" onClick={()=>this.openHandler(row, 'QC')}>Quality Control</button>
                :null}
                {this.props.userStore.hasPermission('dhs.dhs.disposition', 2) ?
                <button className="dropdown-item" onClick={()=>this.openHandler(row, 'QA')}>Quality Assurance</button>
                :null}
              </div>
            </React.Fragment>}
          </div>
          {this.props.userStore.hasPermission('dhs.dhs', 3) ?
            <a className="table-action hover-primary ml-10" title="View Audit" href={"/audit/DonorHumanStool/" + row.id + "/donor_human_stool_id" } target="_blank"><i className="ion-clipboard"></i></a>
          :null}
          </React.Fragment>
        );
      }
    }];
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <PerfectScrollbar>
            <form className="aside-block form-type-line">
              <h2>DHS List</h2>
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDHS}><i className="fa fa-refresh"/> Refresh</button>
              <hr/>
              <p><small className="text-uppercase">Filter by Role</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter("all")}>
                  <input type="radio" name="role_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={dhsStatusFilter === 'all'}/>
                  <label className="custom-control-label">All DHS</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(1)}>
                  <input type="radio" name="role_filter" value={1} className="custom-control-input" onChange={()=>{return false;}} checked={dhsStatusFilter === 1}/>
                  <label className="custom-control-label">Receiving</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(2)}>
                  <input type="radio" name="role_filter" value={2} className="custom-control-input" onChange={()=>{return false;}} checked={dhsStatusFilter === 2}/>
                  <label className="custom-control-label">QC Inspection</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(3)}>
                  <input type="radio" name="role_filter" value={1} className="custom-control-input" onChange={()=>{return false;}} checked={dhsStatusFilter === 3}/>
                  <label className="custom-control-label">Ops Processing</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter('qa')}>
                  <input type="radio" name="role_filter" value={'qa'} className="custom-control-input" onChange={()=>{return false;}} checked={dhsStatusFilter === 'qa'}/>
                  <label className="custom-control-label">QA Disposition</label>
                </div>
              </React.Fragment>
              </div>
              {dhsStatusFilter === 1 ?
              <React.Fragment>
                <hr/>
                <p><small className="text-uppercase">Scan in DHS</small></p>
                <div className="form-group">
                  <input className="form-control" type="text" name="rbx_lot_number_entered" placeholder={"DHS Lot Number"} onKeyPress={this.handleDHSKeyPress} onChange={this.handleDHSInputChange} value={rbx_lot_number_entered}/>
                </div>
              </React.Fragment>
               : null}
              <hr/>
              <p><small className="text-uppercase">Filter by DHS Status</small></p>
              <div className="custom-controls-stacked">
              <React.Fragment>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter("all")}>
                  <input type="radio" name="dhs_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === 'all'}/>
                  <label className="custom-control-label">All Statuses</label>
                </div>
                {this.state.sys_dhs_status_list.map((status, status_index)=>
                  <div key={status_index} className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter(status.id)}>
                    <input type="radio" name="dhs_filter" value={status.id} className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === status.id}/>
                    <label className="custom-control-label">{status.name}</label>
                  </div>
                )}
              </React.Fragment>
              </div>
              <hr/>
              <p><small className="text-uppercase">Filter by Other</small></p>
              <div className="custom-controls-stacked">
                <div className="custom-control custom-radio" onClick={()=>this.statusFilter("all")}>
                  <input type="radio" name="donor_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.statusFilter === 'all'}/>
                  <label className="custom-control-label">All DHS</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.statusFilter("unexpired")}>
                  <input type="radio" name="donor_filter" value="unexpired" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.statusFilter === 'unexpired'}/>
                  <label className="custom-control-label">Unexpired DHS</label>
                </div>
                <div className="custom-control custom-radio" onClick={()=>this.dhsStatusFilter("dispositioned")}>
                  <input type="radio" name="dhs_filter" value="all" className="custom-control-input" onChange={()=>{return false;}} checked={this.state.dhsStatusFilter === 'dispositioned'}/>
                  <label className="custom-control-label">Dispositioned by QA</label>
                </div>
              </div>
              <hr/>
              <p><small className="text-uppercase">Search by Donor ID</small></p>
              <div className="form-group">
                <input className="form-control" type="text" placeholder="Donor ID" value={searchName} onChange={this.handleInputChange}/>
              </div>
              <div className="btn-group pull-right">
                <button className="btn btn-sm btn-secondary" type="button" onClick={this.resetDHS}>Reset</button>
              </div>
            </form>
            </PerfectScrollbar>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card p-10">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
            <BootstrapTable keyField='id' data={this.state.dhs_list} columns={columns} striped hover headerClasses="thead-light"/>
          }
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewVisible ?
            <DHSQuickview addHandler={this.addDHS} closeHandler={this.closeHandler} dhs={dhs} sys_dhs_status_list={sys_dhs_status_list} view_type={view_type} handleDateChange={this.handleDateChange} sys_disposition_list={sys_disposition_list} sys_dhs_fail_list={sys_dhs_fail_list} sys_dhs_discard_list={sys_dhs_discard_list}/>
            :<div>No DHS Loaded</div>
          }
        </div>
      </React.Fragment>
      );
    }
}
export default DHS;
