import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';

class LineChart extends Component {
  render() {
    var chartOptions = {
      responsive: true,
      hoverMode: 'index',
      stacked: false,
      title: {
        display: true,
        text: this.props.name
      },
      scales: this.props.scales
    };
    if (this.props.hideTitle == true){
      chartOptions.title.display = false;
    }
    return (
    	<Line data={this.props.data} options={chartOptions} />
    );
  }
}

export default LineChart;
