import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class DonorGroupQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      donorGroup:{id:null, name:'', color:''}
    }
  }

  componentDidMount(){
    if (this.props.donorGroup !== null){
      this.setState({
        donorGroup:this.props.donorGroup
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.donorGroup);
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.donorGroup);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_obj[e.target.name] = value;
    this.setState({donorGroup:updated_obj});
  }

  render() {
    if (!this.state.donorGroup) return <div>Loading</div>;
    const {closeHandler} = this.props;
    const {donorGroup} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!donorGroup.id ? 'Add Donor Group' : donorGroup.name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="donor-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Group Name</label>
                    <input required className="form-control" type="text" name="name" onChange={this.handleInputChange} value={donorGroup.name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Color</label>
                    <input required className="form-control" type="text" name="color" onChange={this.handleInputChange} value={donorGroup.color}/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="donor-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default DonorGroupQuickview;
