import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import DrugSubstanceModal from './DrugSubstanceModal';
import DrugSubstanceModalTestResults from './DrugSubstanceModalTestResults';
import DrugSubstanceModalTestEntry from './DrugSubstanceModalTestEntry';
import DrugSubstanceQuickview from './DrugSubstanceQuickview'
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class DrugSubstance extends Component {
  constructor(props){
      super(props);
      this.state = {
        loading:true,
        addDSVisible:false,
        statusFilter:'all',
        recipe:null,
        kit:null,
        ds_list:[],
        ds_listOriginal:[],
        searchName:'',
        sys_bom_status_list:[],
        sys_disposition_list:[],
        dhs_list:[],
        quickviewVisible:false,
        ds:null,
        modalVisible:false,
        modalResultsVisible:false
      }
  }

  componentDidMount(){
    this.loadDrugSubstance();
  }

  loadDrugSubstance = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('boms/8', {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            recipe:res.data.recipe,
            ds_list:res.data.ds_list,
            ds_listOriginal:res.data.ds_list,
            sys_bom_status_list:res.data.sys_bom_status_list,
            sys_disposition_list:res.data.sys_disposition_list,
            dhs_list:res.data.dhs_list,
            loading:false
          })
        } else {
          alert(res.data.message)
        }
      })
      .catch((error)=>{toast.error('An error occurred', toastParams);});
    })
  }

  addDrugSubstance = (drug_substance) => {
    this.setState({
      loading:true,
      addDSVisible:false
    }, ()=>{
      if (drug_substance.expiration_date){
        drug_substance.expiration_date = moment(drug_substance.expiration_date).format('YYYY-MM-DD')
      }
      if (drug_substance.manufacture_date){
        drug_substance.manufacture_date = moment(drug_substance.manufacture_date).format('YYYY-MM-DD')
      }
      if (drug_substance.product_hash_id){
        connectionPut('boms/8/' + drug_substance.product_hash_id, {bom:drug_substance}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.setState({addDSVisible:false, quickviewVisible:false});
            this.loadDrugSubstance();
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      } else {
        connectionPost('boms/8', {drug_substance:drug_substance}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.setState({addDSVisible:false}, ()=>this.loadDrugSubstance());
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      }
    })
  }

  closeHandler = () => {
    this.setState({
      addDSVisible:!this.state.addDSVisible
    })
  }

  closeQV =() => {
    this.setState({
      quickviewVisible:false,
      modalResultsVisible:false
    })
  }

  editDS = (ds) => {
    this.setState({
      quickviewVisible:false,
      addDSVisible:true,
      modalResultsVisible:false,
      ds:ds
    })
  }

  dispositionDS = (ds) => {
    this.setState({
      quickviewVisible:true,
      addDSVisible:false,
      modalResultsVisible:false,
      ds:ds
    })
  }

  addDS = () => {
    this.setState({
      quickviewVisible:false,
      addDSVisible:true,
      modalResultsVisible:false,
      ds:null
    })
  }

  openDSTestQV = (ds) => {
    this.setState({
      quickviewVisible:false,
      addDSVisible:false,
      modalResultsVisible:true,
      ds:ds
    })
  }

  openTestResults = (ds) => {
    this.setState({
      ds:ds,
      modalResultsVisible:true
    })
  }

  closeTestResults = () => {
    this.setState({
      ds:null,
      modalResultsVisible:false
    })
  }

  openTestEntry = (ds) => {
    this.setState({
      ds:ds,
      modalVisible:true
    })
  }

  closeTestEntry = () => {
    this.setState({
      ds:null,
      modalVisible:false
    })
  }

  render() {
    const {searchName, ds_list, addDSVisible, ds, recipe, dhs_list, sys_bom_status_list, sys_disposition_list, loading, modalVisible} = this.state;
    const columns = [{
      dataField: 'lot_number',
      text: 'Batch Number'
    }, {
      dataField: 'name',
      text: 'Name',
      sort: false
    }, {
      dataField: 'expiration_date',
      text: 'Expiration Date',
      sort: true,
      formatter: (cellContent, row)=>{
        if (row.expiration_date){
          return (
            <span>{moment(row.expiration_date).format('DDMMMYYYY')}</span>
          )
        }
      }
    }, {
      dataField: 'planned_sub_size',
      text: 'Planned Dose #'
    }, {
      dataField: 'sys_bom_status',
      text: 'Status',
    }, {
      dataField: 'none',
      isDummyField:true,
      text: 'Disposition',
      formatter: (cellContent, row)=>{
        if (row.disposition){
          return (
            <React.Fragment>
            <ul className="list-unstyled mb-0 fs-10">
              <li><small><span className={row.disposition.sys_disposition_id === 1 ? "badge badge-success" : "badge badge-danger"}>{row.disposition.sys_disposition}</span> by {row.disposition.user.full_name} on {moment(row.disposition.create_timestamp).format('ll')}</small></li>
            </ul>
            </React.Fragment>
          )
        }
      }
    },{
      dataField: 'Actions',
      isDummyField: true,
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
          <div className="btn-group">
            <button className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown">Actions</button>
            <div className="dropdown-menu">
              <button className="dropdown-item" onClick={()=>this.editDS(row)}>View/Edit Details</button>
              <button className="dropdown-item" onClick={()=>this.openTestEntry(row)}>Enter Test Results</button>
              <button className="dropdown-item" onClick={()=>this.openTestResults(row)}>View Test Results</button>
              {this.props.userStore.hasPermission('manufacturing.drug_substance.disposition', 1) ?
              <button className="dropdown-item" onClick={()=>this.dispositionDS(row)}>Quality Assurance</button>
              : null}
            </div>
          </div>
          {this.props.userStore.hasPermission('manufacturing.drug_substance', 3) ?
            <a className="table-action hover-primary ml-10" title="View Audit" href={"/audit/BOMProduct/" + row.id + "/bom_product_id" } target="_blank"><i className="ion-clipboard"></i></a>
          :null}
          </React.Fragment>
        );
      }
    }];
    return (
      <React.Fragment>
        <Modal visible={this.state.modalVisible} onClickBackdrop={this.closeTestEntry} dialogClassName="modal-lg">
          {ds ?
          <DrugSubstanceModalTestEntry ds={ds} modalBackgroundClicked={this.closeTestEntry}/>
          : <div/>
          }
        </Modal>
        <Modal visible={this.state.modalResultsVisible} onClickBackdrop={this.closeTestResults} dialogClassName="modal-lg">
          {ds ?
          <DrugSubstanceModalTestResults modalBackgroundClicked={this.closeTestResults} ds={ds}/>
          :<div>No DS Loaded</div>
          }
        </Modal>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Drug Substance</h2>
              <div className="btn-group-vertical">
              {this.props.userStore.hasPermission('manufacturing.drug_substance', 2) ?
              <button className="btn btn-sm btn-success btn-block" type="button" onClick={this.addDS}><i className="fa fa-plus"/> Add DS</button>
              :null}
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDrugSubstance}><i className="fa fa-refresh"/> Refresh</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          {addDSVisible ?
            <div className="card p-10">
              <DrugSubstanceModal addHandler={this.addDrugSubstance} closeHandler={this.closeHandler} drug_substance={ds} sys_bom_status_list={sys_bom_status_list} recipe={recipe} isEdit={true} dhs_list={dhs_list} loading={loading}/>
            </div>
            :
            <div className="card p-10">
            {loading ?
              <h4><Spinner color="primary"/> Loading...</h4>
            :
              <BootstrapTable keyField='id' data={ds_list} columns={columns} pagination={paginationFactory()} striped hover headerClasses="thead-light"/>
            }
            </div>
          }
          <br/>
          <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
            {this.state.quickviewVisible ?
              <DrugSubstanceQuickview addHandler={this.addDrugSubstance} closeHandler={this.closeQV} ds={ds} sys_disposition_list={sys_disposition_list}/>
              :<div>No DS Loaded</div>
            }
          </div>
        </div>
      </React.Fragment>
      );
    }
}
export default DrugSubstance;


// <table className="table table-striped table-hover">
//   <thead>
//     <tr>
//       <th width="10%">Lot#</th>
//       <th width="10%">Donor Id</th>
//       <th width="15%">Donation Date</th>
//       <th width="15%">Expiration Date</th>
//       <th width="10%">Weight</th>
//       <th width="20%">Status</th>
//       <th width="15%">PS Assignment</th>
//       <th></th>
//     </tr>
//   </thead>
//   <tbody>
// {ds_list ? ds_list.map((kit, index)=>
//     <tr key={index}>
//       <td>{kit.rbx_lot_number}</td>
//       <td>{kit.rbx_donor_id}</td>
//       <td>{moment(kit.donation_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{moment(kit.expiration_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{kit.weight}</td>
//       <td>{kit.status.ds_status}</td>
//       <td>{kit.pooled_sample_number ? kit.pooled_sample_number : 'NA'}</td>
//       <td className="text-right table-actions">
//         <a className="table-action hover-primary" href="#" onClick={()=>this.editDS(kit)}><i className="ti-pencil"></i></a>
//         <a className="table-action hover-primary" href="#" onClick={()=>this.editDS(kit)}><i className="ti-menu-alt"></i></a>
//       </td>
//     </tr>
// ) : <tr><td colSpan="5">No DrugSubstance Found</td></tr>}
//   </tbody>
// </table>
// }, {
//   dataField: 'description',
//   text: 'Description',
//   sort: false
