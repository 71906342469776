import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';

class HeaderNavLink extends Component {
    static propTypes = {
      match: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired
    };

    render() {
        const { match, location, history } = this.props;
        let isActive = location.pathname === this.props.to;
        let className = isActive ? "menu-item active" : "menu-item";
        return (
            <li className={className}>
                <NavLink history={this.props.history} linkname={this.props.linkname} match={this.props.match} to={this.props.to} className="menu-link">
                    <span className={"icon " + this.props.icon}></span>
                    <span className="title">{this.props.linkname}</span>
                    {this.props.count && this.props.count > 0 ? <span className="badge badge-pill badge-info">{this.props.count}</span> : null}
                </NavLink>
            </li>
        );
    }
}

HeaderNavLink.contextTypes = {
  router: PropTypes.object
};

export default HeaderNavLink;
