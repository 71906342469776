import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../../timepicker.css';

class PEGSalineModal extends Component {
  // Accounts Payable
  constructor(props){
    super(props);
    this.state = {
      ps:null,
      batch_record:{batch_record_number:'', batch_size:0},
      recipe:null,
      sys_bom_status_list:[],
      is_edit:false
    }
  }

  componentDidMount(){
    if (this.props.ps === null){
      this.setState({
        is_edit:false,
        ps:this.props.recipe,
        recipe:this.props.recipe,
        sys_bom_status_list:this.props.sys_bom_status_list
      })
    } else {
      this.setState({
        is_edit:true,
        ps:this.props.ps,
        recipe:this.props.recipe,
        sys_bom_status_list:this.props.sys_bom_status_list
      },()=>{
        let tmp_recipe = Object.assign({}, this.state.recipe);
        tmp_recipe.children = this.state.ps.children;
        this.setState({
          recipe:tmp_recipe
        })
      })
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.ps === null){
      this.setState({
        is_edit:false,
        ps:nextProps.recipe,
        recipe:nextProps.recipe,
        sys_bom_status_list:nextProps.sys_bom_status_list
      })
    } else {
      this.setState({
        is_edit:true,
        ps:nextProps.ps,
        recipe:nextProps.recipe,
        sys_bom_status_list:nextProps.sys_bom_status_list
      },()=>{
        let tmp_recipe = Object.assign({}, this.state.recipe);
        tmp_recipe.children = this.state.ps.children;
        this.setState({
          recipe:tmp_recipe
        })
      })
    }
  }

  handleDateChange = (date, child) => {
    let updated_obj = Object.assign({}, this.state.ps);
    if (child){
      let updated_child = updated_obj.children.find(su => su.id === child.id);
      if (updated_child){
        updated_child.expiration_date = date;
      } else {
        // handle option
        let updated_child_parent = updated_obj.children.find(su => su.id === child.parent_id);
        if (updated_child_parent){
          let updated_child = updated_child_parent.children.find(su => su.id === child.id);
          updated_child.expiration_date = date;
        }
      }
    } else {
      updated_obj.expiration_date = date;
    }
    this.setState({ps:updated_obj});
  }

  addHandler = () => {
    this.props.addHandler(this.state.ps, this.state.batch_record);
  }

  handleBatchInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.batch_record);
    const value = e.target.value;
    if (e.target.name === 'batch_size'){
      if (parseInt(value) <= 0 || parseInt(value) > 4){
        alert('Only Sublots up to 4 allowed');
      } else {
        updated_obj[e.target.name] = value;
      }
    } else {
      updated_obj[e.target.name] = value;
    }
    this.setState({batch_record:updated_obj});
  }

  handleInputChange = (e, child) => {
    let updated_obj = Object.assign({}, this.state.ps);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (!child){
      if (e.target.name === 'planned_sub_size'){
        if (value < 11){
          updated_obj[e.target.name] = parseInt(value);
          if (updated_obj.children){
            updated_obj.children.map((child, i) => {
              if (updated_obj && updated_obj.planned_sub_size && child.quantity_rule === 'MULTIPLY'){
                child.quantity = child.quantity_init * updated_obj.planned_sub_size;
              }
            })
          }
        } else {
          alert('Only 10 sublots allowed');
        }
      } else {
        updated_obj[e.target.name] = value;
      }
    } else if (e.target.name === 'option') {
      // attempt to set the selected option value
      let updated_child_parent = updated_obj.children.find(su => su.id === child.parent_id);
      if (updated_child_parent){
        let selected_child = updated_child_parent.children.find(su => su.selected === true);
        if (selected_child){
          selected_child['selected'] = false;
        }
        let updated_child = updated_child_parent.children.find(su => su.id === child.id);
        updated_child['selected'] = true;
      }
    } else {
      let updated_child = updated_obj.children.find(su => su.id === child.id);
      if (updated_child){
        updated_child[e.target.name] = value;
      } else {
        // handle option
        let updated_child_parent = updated_obj.children.find(su => su.id === child.parent_id);
        if (updated_child_parent){
          let updated_child = updated_child_parent.children.find(su => su.id === child.id);
          updated_child[e.target.name] = value;
        }
      }
    }
    this.setState({ps:updated_obj});
  }

  render() {
    if (!this.state.recipe) return <div>Loading</div>;
    const {closeHandler} = this.props;
    const {ps, recipe, batch_record, is_edit} = this.state;
    return (
      <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title">{recipe.name}</h4>
              <button type="button" className="close" onClick={closeHandler} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body h-800px">
          <PerfectScrollbar>
            <div className="form-type-combine">
              <div className="bg-lighter p-20 b-1 mb-3">
                <div className="row form-groups-attached">
                  <div className={is_edit ? "d-none" : "col-6"}>
                    <h6 className="text-light fw-300">Batch Information</h6>
                    <div className="row">
                      <div className="form-group col-6">
                        <label>Lot Number</label>
                        <input className="form-control col-12" type="text" name="batch_record_number" onChange={this.handleBatchInputChange} value={batch_record.batch_record_number} placeholder="Lot Number"/>
                      </div>
                      <div className="form-group col-6">
                        <label>Batch Size</label>
                        <input className="form-control" type="number" name="batch_size" onChange={this.handleBatchInputChange} value={batch_record.batch_size} placeholder='Batch Size'/>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <h6 className="text-light fw-300">PEG/Saline Solution Information</h6>
                    <div className="row">
                      <div className="form-group col-6">
                        <label>Item Number</label>
                        <input className="form-control" type="text" name="item_number" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.material_number : ''} placeholder='Item Number'/>
                      </div>
                      <div className="form-group col-6">
                        <label>Expiration Date</label>
                        <DatePicker
                          selected={ps.expiration_date ? moment(ps.expiration_date).toDate() : null}
                          onChange={(date)=>this.handleDateChange(date, null)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={5}
                          timeCaption="time"
                          popperPlacement="left-start"
                          className="form-control"
                          dateFormat="yyyy-MM-dd HH:mm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-lighr p-20 b-1">
            {recipe.children.map((child, child_index)=> {
              if (child.sys_bom_type_id === 2){
                return (
                  <React.Fragment key={child_index}>
                    <h6 className="text-light fw-300">{child.name}</h6>
                    <div className="form-groups-attached">
                      <div className="row">
                        <div className="form-group col-3">
                          <label>Material Number</label>
                          <input className="form-control" name="material_number" type="text" value={child.material_number} onChange={(e) => this.handleInputChange(e, child)}/>
                        </div>
                        <div className={child.is_extended ? "form-group col-3" : "form-group col-3 bg-light"}>
                          <label>Expiration date</label>
                          <DatePicker
                            selected={child.expiration_date ? moment(child.expiration_date).toDate() : null}
                            onChange={(date)=>this.handleDateChange(date, child)}
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                            disabled={!child.is_extended}
                          />
                        </div>
                        <div className={child.is_extended ? "form-group col-3" : "form-group col-3 bg-light"}>
                          <label>Lot Number</label>
                          <input disabled={!child.is_extended} className="form-control" name="lot_number" value={child.lot_number} type="text" onChange={(e) => this.handleInputChange(e, child)}/>
                        </div>
                        <div className="form-group col-3">
                          <label>Quantity {child.quantity_rule === 'MULTIPLY' ? 'x ' + recipe.unit : null}</label>
                          <input className="form-control" name="quantity" type="number" value={child.quantity} onChange={(e) => this.handleInputChange(e, child)}/>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              } else if (child.sys_bom_type_id === 3 && child.children.length > 0) {
                return (
                  <div className="mb-20">
                  {child.children.map((sub_child, sub_child_index)=>
                    <React.Fragment key={sub_child_index}>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="option" id={sub_child.id} value={sub_child.id} checked={sub_child.selected} onChange={(e)=>this.handleInputChange(e, sub_child)}/>
                        <label className="form-check-label" htmlFor={sub_child_index}>
                          {sub_child.name}
                        </label>
                      </div>
                      <div style={{display:sub_child.selected ? 'block' : 'none'}}>
                        <div className="form-groups-attached">
                          <div className="row">
                            <div className="form-group col-3">
                              <label>Material Number</label>
                              <input className="form-control" name="material_number" type="text" value={sub_child.material_number} onChange={(e) => this.handleInputChange(e, sub_child)}/>
                            </div>
                            <div className={sub_child.is_extended ? "form-group col-3" : "form-group col-3 bg-light"}>
                              <label>Expiration date</label>
                              <DatePicker
                                selected={sub_child.expiration_date ? moment(sub_child.expiration_date).toDate() : null}
                                onChange={(date)=>this.handleDateChange(date, sub_child)}
                                className="form-control"
                                dateFormat="yyyy-MM-dd"
                                disabled={!sub_child.is_extended}
                              />
                            </div>
                            <div className={sub_child.is_extended ? "form-group col-3" : "form-group col-3 bg-light"}>
                              <label>Lot Number</label>
                              <input disabled={!sub_child.is_extended} className="form-control" name="lot_number" value={sub_child.lot_number} type="text" onChange={(e) => this.handleInputChange(e, sub_child)}/>
                            </div>
                            <div className="form-group col-3">
                              <label>Quantity {sub_child.quantity_rule === 'MULTIPLY' ? 'x ' + recipe.unit : null}</label>
                              <input className="form-control" name="quantity" type="number" value={sub_child.quantity} onChange={(e) => this.handleInputChange(e, sub_child)}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  </div>
                );
              }
            })}
              </div>
            </div>
          </PerfectScrollbar>
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeHandler}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={this.addHandler}>Save</button>
          </div>
      </React.Fragment>
    )
  }
}

export default PEGSalineModal;


// <div className="form-group col-3">
//   <label>{recipe.unit} Number</label>
//   <input className="form-control col-4" type="number" name="planned_sub_size" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.planned_sub_size : 0} placeholder={recipe.unit}/>
// </div>
// <div className="form-group col-3">
//   <label>Item Number</label>
//   <input className="form-control" type="text" name="item_number" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.material_number : ''} placeholder='Item Number'/>
// </div>
// <div className="form-group col-3">
//   <label>Lot Number</label>
//   <input className="form-control" type="text" name="lot_number" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.lot_number : ''} placeholder='Lot Number'/>
// </div>
// <div className="form-group col-3">
//   <label>Expiration Date</label>
//   <p className="form-control-plaintext">{ps.expiration_date ? ps.expiration_date : 'Expiration Set Automatically'}</p>
// </div>
