import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Page, Text, View, Image, Document, Font, StyleSheet, Canvas, PDFViewer } from '@react-pdf/renderer';

Font.register({ family: 'Roboto', src:'https://fonts.gstatic.com/s/roboto/v16/zN7GBFwfMP4uA6AR0HCoLQ.ttf' });
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    paddingLeft:60+'px',
    paddingTop:40+'px',
  },
  sectionImg: {
    flexDirection:'row',
    justifyContent: 'flex-start'
  },
  sectionText: {
    flexDirection:'row',
    justifyContent: 'flex-start',
    marginTop:15+'px'
  },
  section: {
    flexDirection:'column',
    justifyContent: 'flex-start'
  }
});

@inject('userStore')
class DonorPrint extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false
      }
  }

  componentDidMount(){
  }

  render() {
    const {modalBackgroundClicked, donor} = this.props;
    return (
      <React.Fragment>
        <div className="modal-header">
            <h4 className="modal-title">Donor Detail - {donor.id}</h4>
            <button type="button" className="close" onClick={modalBackgroundClicked} aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
        </div>
        <div className="modal-body" style={{height:900+'px'}}>
          <PDFViewer width={100+'%'} height={100+'%'}>
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.sectionImg}>
                  <Image src={donor.img_url} style={{width:80+'px', height:80+'px'}}/>
                  <Image src={require('../../img/rebiotix.logo.png')} style={{marginTop:15+'px', marginLeft:5+'px', width:130+'px', height:65+'px'}}/>
                </View>
                <View style={styles.sectionText}>
                  <Image src={'/api/donor/' + donor.rbx_donor_id + '/qr.png'} style={{width:50+'px', height:50+'px'}}/>
                  <View style={styles.section}>
                    <Text style={{marginTop:5+'px', marginLeft:50+'px'}}>{donor.rbx_donor_id}</Text>
                    <Text style={{fontFamily:'Roboto', fontSize:10, color:'#CCC', marginTop:10+'px', marginLeft:10+'px'}}>Your Business is Our Business</Text>
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={modalBackgroundClicked}>
              Close
            </button>
        </div>
      </React.Fragment>
      );
    }
}
export default DonorPrint;
