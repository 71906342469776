import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';

class BarChart extends Component {
  render() {
    const {data} = this.props;
    const options = {
      maintainAspectRatio: true,
    };
    return (
    	<Bar
        data={data}
        width={900}
        height={650}
        options={options}/>
    );
  }
}

export default BarChart;
