import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {inject} from 'mobx-react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

@inject('userStore')
class Storage extends Component {

  handleDateChange = (date) =>{
    let e = {}
    e.target = {};
    e.target.name = 'shipped_date';
    e.target.value = date;
    this.props.handleInputChange(e);
  }

  render(){
    const {dose, handleInputChange, bom_storage_list, sys_dose_status_list} = this.props;
    let sys_dose_status_sub_list = [];
    if (sys_dose_status_list.length > 0 && dose.sys_dose_status_id){
      let sys_dose_status = sys_dose_status_list.find(status => status.id === parseInt(dose.sys_dose_status_id));
      if (sys_dose_status && sys_dose_status.sub_status_list && sys_dose_status.sub_status_list.length > 0){
        sys_dose_status_sub_list = sys_dose_status.sub_status_list.split(',');
      }
    }
    return (
    <React.Fragment>
      <div className="form-group">
        <label>Freezer Location</label>
        <p className="form-control-plaintext">{dose.bom_storage ? dose.bom_storage.full_name : 'Freezer Not Set'}</p>
      </div>
      <div className="form-group">
        <label className="required">Dose Status</label>
        {this.props.userStore.hasPermission('manufacturing.doses.status', 2) ?
          <React.Fragment>
        {dose.status && parseInt(dose.status.sys_dose_status_id) === 1 ?
          <select required className="form-control" name="sys_dose_status_id" onChange={handleInputChange} value={dose.status ? dose.status.sys_dose_status_id : null}>
          {sys_dose_status_list.map((dose_status, dose_status_index)=>
            {
              if (dose_status.id === 1 || dose_status.id === 3){
                return (<option key={dose_status_index} value={dose_status.id}>{dose_status.name}</option>);
              }
            }
          )}
          </select>
        :
          <select required className="form-control" name="sys_dose_status_id" onChange={handleInputChange} value={dose.status ? dose.status.sys_dose_status_id : null}>
          {sys_dose_status_list.map((dose_status, dose_status_index)=>
            {
              if (dose_status.id !== 1){
                return (<option key={dose_status_index} value={dose_status.id}>{dose_status.name}</option>);
              }
            }
          )}
          </select>
        }
          </React.Fragment>
        :
        <p className="form-control-plaintext">{dose.sys_dose_status ? dose.sys_dose_status : ''}</p>
        }
      </div>
      {dose.status && parseInt(dose.status.sys_dose_status_id) !== 1 ?
        <React.Fragment>
          {parseInt(dose.status.sys_dose_status_id) === 6 ?
            <React.Fragment>
            <div className="row">
              <div className="form-group col-12">
                <label className="required">Shipped Date</label><br/>
                <DatePicker
                  selected={dose.shipped_date ? new Date(dose.shipped_date) : null}
                  onChange={this.handleDateChange}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-12">
                <label className="required">Study #/Protocol</label>
                <select required className="form-control" name="protocol" onChange={handleInputChange} value={dose.protocol ? dose.protocol : null}>
                  <option>Select</option>
                {sys_dose_status_sub_list.map((sub, sub_index)=>
                  <option key={sub_index} value={sub}>{sub}</option>
                )}
                </select>
              </div>
            </div>
            </React.Fragment>
          : null}
          {parseInt(dose.status.sys_dose_status_id) === 5 ?
            <React.Fragment>
              <div className="row">
                <div className="form-group col-6">
                  <label className="required">Shipped Date</label>
                  <DatePicker
                    selected={dose.shipped_date ? new Date(dose.shipped_date) : null}
                    onChange={this.handleDateChange}
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    required
                  />
                </div>
                <div className="form-group col-6">
                  <label className="required">Pick/Pack #</label>
                  <input required type="text" className="form-control" name="pack_pick_number" onChange={handleInputChange} value={dose.pack_pick_number}/>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-6">
                  <label className="required">Subject ID</label>
                  <input required type="text" className="form-control" name="subject_id" onChange={handleInputChange} value={dose.subject_id}/>
                </div>
                <div className="form-group col-6">
                  <label className="required">Study #/Protocol</label>
                  <select required className="form-control" name="protocol" onChange={handleInputChange} value={dose.protocol ? dose.protocol : null}>
                    <option>Select</option>
                  {sys_dose_status_sub_list.map((sub, sub_index)=>
                    <option key={sub_index} value={sub}>{sub}</option>
                  )}
                  </select>
                </div>
              </div>
            </React.Fragment>
          : null}
          {parseInt(dose.status.sys_dose_status_id) === 4 ?
            <React.Fragment>
              <div className="form-group">
                <label className="required">Transfer Reference #</label>
                <input required type="text" className="form-control" name="transfer_batch_number" onChange={handleInputChange} value={dose.transfer_batch_number}/>
              </div>
            </React.Fragment>
          : null}
          {parseInt(dose.status.sys_dose_status_id) === 3 ?
            <React.Fragment>
              <div className="form-group">
                <label className="required">Scrapped Comment</label>
                <input required type="text" className="form-control" name="scrap_comment" onChange={handleInputChange} value={dose.status.scrap_comment}/>
              </div>
            </React.Fragment>
          : null}
          {parseInt(dose.status.sys_dose_status_id) === 7 ?
            <React.Fragment>
              <div className="form-group">
                <label className="required">Staged For</label>
                <select required className="form-control" name="staging_study" onChange={handleInputChange} value={dose.staging_study ? dose.staging_study : null}>
                  <option>Select</option>
                {sys_dose_status_sub_list.map((sub, sub_index)=>
                  <option key={sub_index} value={sub}>{sub}</option>
                )}
                </select>
              </div>
            </React.Fragment>
          : null}
        </React.Fragment>
      :null}
    </React.Fragment>
    );
  }
}

@inject('userStore')
class QADisposition extends Component {
  render(){
    const {dose, handleInputChange, sys_disposition_list} = this.props;
    return (
    <React.Fragment>
      <div className="form-group">
        <label className="required">Disposition</label>
        {dose.disposition && dose.disposition.sys_disposition ?
          <p className="form-control-plaintext">{dose.disposition.sys_disposition}</p>
        :
          this.props.userStore.hasPermission('manufacturing.doses.disposition', 2) ?
          <select required className="form-control" name="sys_disposition_id" onChange={handleInputChange} value={dose.disposition ? dose.disposition.sys_disposition_id : null}>
            <option value={null}>Select</option>
          {sys_disposition_list.map((disposition, disposition_index)=>
            {
              if (disposition.id === 2){
                return <option key={disposition_index} value={disposition.id}>{disposition.name}</option>;
              }
            }
          )}
          </select>
        : null}
      </div>
      {dose.disposition && dose.disposition.sys_disposition_id && parseInt(dose.disposition.sys_disposition_id) === 2 ?
        <div className="form-group">
          <label className="required">Reject Number</label>
          <input className="form-control" type="text" value={dose.disposition && dose.disposition.reject_number ? dose.disposition.reject_number : ''} name="reject_number" onChange={handleInputChange}/>
        </div>
      : null}
    </React.Fragment>
    );
  }
}

@inject('userStore')
class DoseQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      dose:null
    }
  }

  componentDidMount(){
    if (this.props.dose !== null){
      this.setState({
        dose:this.props.dose,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('submitting')
    this.props.addHandler(this.state.dose);
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.dose);
    let value = e.target.value;
    updated_obj[e.target.name] = value;
    if (e.target.name === 'bom_storage_id'){
      if (updated_obj['storage']){
        updated_obj['storage']['bom_storage_id'] = value;
      } else {
        updated_obj['storage'] = {};
        updated_obj['storage']['bom_storage_id'] = value;
      }
    }
    if (e.target.name === 'sys_disposition_id'){
      if (updated_obj['disposition']){
        updated_obj['disposition']['sys_disposition_id'] = value;
      } else {
        updated_obj['disposition'] = {};
        updated_obj['disposition']['sys_disposition_id'] = value;
      }
    }
    if (e.target.name === 'reject_number'){
      updated_obj['disposition']['reject_number'] = value;
    }
    if (e.target.name === 'sys_dose_status_id'){
      updated_obj['status']['sys_dose_status_id'] = value;
    }
    if (e.target.name === 'scrap_comment'){
      updated_obj['status']['scrap_comment'] = value;
    }
    if (e.target.name === 'sys_dose_sub_status'){
      updated_obj['status']['sys_dose_sub_status'] = value;
    }
    this.setState({dose:updated_obj});
  }

  render() {
    if (!this.state.dose) return <div>Loading</div>;
    const {closeHandler, sys_disposition_list, bom_storage_list, sys_dose_status_list} = this.props;
    const {dose} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{dose.dose_number}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="dose-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <Storage dose={dose} handleInputChange={this.handleInputChange} bom_storage_list={bom_storage_list} sys_dose_status_list={sys_dose_status_list}/>
                <QADisposition dose={dose} handleInputChange={this.handleInputChange} sys_disposition_list={sys_disposition_list}/>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
          {this.props.userStore.hasPermission('manufacturing.doses.status', 2) || this.props.userStore.hasPermission('manufacturing.doses.disposition', 2) ?
            <button className="btn btn-lg btn-outline btn-block btn-success" form="dose-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
          : null}
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default DoseQuickview;


// <select required className="form-control" name="bom_storage_id" onChange={handleInputChange} value={dose.storage ? dose.storage.bom_storage_id : null}>
//   <option value={null}>Select</option>
// {bom_storage_list.map((bom_storage, bom_storage_index)=>
//   <option key={bom_storage_index} value={bom_storage.id}>{bom_storage.name}</option>
// )}
// </select>
