import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class TestQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      sys_test:{id:null, name:'', sop_number:1, children:[]}
    }
  }

  componentDidMount(){
    if (this.props.sys_test !== null){
      this.setState({
        sys_test:this.props.sys_test,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.sys_test);
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.sys_test);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'children'){
      var child_value = parseInt(e.target.value);
      let question = this.props.sys_question_list.find(su => su.id === child_value);
      if (e.target.checked && !updated_obj.children.includes(question)){
        updated_obj.children.push(question)
      } else {
        // var index = updated_obj.role_list.indexOf(su => su.sys_user_role_id === role_value);
        var index = updated_obj.children.map(function(e) { return e.id; }).indexOf(child_value);
        if (index !== -1) updated_obj.children.splice(index, 1);
      }
    } else {
      updated_obj[e.target.name] = value;
    }
    this.setState({sys_test:updated_obj});
    //
    // let updatedSelectedItem = Object.assign({}, this.state.selectedItem);
    // this.setState({selectedItem:updatedSelectedItem});
  }

  render() {
    if (!this.state.sys_test) return <div>Loading</div>;
    const {closeHandler, sys_question_list} = this.props;
    const {sys_test} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!sys_test.id ? 'Add Test' : sys_test.name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="test-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-6">
                    <label className="required">Name</label>
                    <input required className="form-control" type="text" name="name" onChange={this.handleInputChange} value={sys_test.name}/>
                  </div>
                  <div className="form-group col-6">
                    <label className="required">Description</label>
                    <input required className="form-control" type="text" name="description" onChange={this.handleInputChange} value={sys_test.description}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <label className="required">SOP Number</label>
                    <input required className="form-control" type="text" name="sop_number" onChange={this.handleInputChange} value={sys_test.sop_number}/>
                  </div>
                  <div className="form-group col-6">
                    <label className="required">SOP Revision</label>
                    <input required className="form-control" type="text" name="revision" onChange={this.handleInputChange} value={sys_test.revision}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Change Comment</label>
                    <textarea className="form-control" name="change_comment" onChange={this.handleInputChange} value={sys_test.change_comment}/>
                  </div>
                </div>
                {sys_test.id ?
                <div className="row">
                  <div className="form-group col-12">
                    <h5>Questions</h5>
                    {sys_question_list ? sys_question_list.map((question, q_index)=>{
                      let checked=false;
                      if (sys_test && sys_test.children && sys_test.children.find(su => su.id === question.id)){
                        checked=true;
                      } else {
                        checked=false;
                      }
                      return (
                        <div className="form-check" key={q_index}>
                          <input className="form-check-input" type="checkbox" name="children" onChange={this.handleInputChange} value={question.id} id={q_index} checked={checked}/>
                          <label className="form-check-label" htmlFor={q_index}>{question.name}</label>
                        </div>
                      );
                    }) : null}
                  </div>
                </div>
                : null}
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="test-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default TestQuickview;
