import React, { Component } from 'react';
import QuestionQuickview from './QuestionQuickview';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

@inject('userStore')
class Questions extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'all',
        sys_question:null
      }
  }

  componentDidMount(){
    this.loadQuestions();
  }

  loadQuestions = () => {
    connectionGet('questions', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          sys_question_list:res.data.sys_question_list
        })
      }
    })
    .catch((error)=>{console.log('error')});
  }

  addQuestion = (sys_question) => {
    if (sys_question.hash_id){
      connectionPut('questions/' + sys_question.hash_id, {sys_question:sys_question}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success('Question edited', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
          this.loadQuestions();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error)=>{console.log('error')});
    } else {
      connectionPost('questions', {sys_question:sys_question}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success('Question added', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
          });
          this.loadQuestions();
        } else {
          alert(res.data.message);
        }
      })
      .catch((error)=>{console.log('error')});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (sys_question) => {
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<QuestionQuickview addHandler={this.addQuestion} closeHandler={this.closeHandler} sys_question={sys_question}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }


  render() {
    const {searchName, sys_question_list} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>System Questions</h2>
              <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add Question</button>
              <hr/>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="10%">Question</th>
                  <th width="10%">Test Code</th>
                  <th width="30%">Question Text</th>
                  <th width="20%">Acceptance Criteria</th>
                  <th width="10%">Type</th>
                  <th width="5%">Sequence</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
          {sys_question_list ? sys_question_list.map((sys_question, index)=>
                <tr key={index}>
                  <td>{sys_question.name}</td>
                  <td>{sys_question.test_code}</td>
                  <td>{sys_question.question_text}</td>
                  <td dangerouslySetInnerHTML={{ __html: sys_question.acceptance_criteria}}/>
                  <td>{sys_question.question_type}</td>
                  <td>{sys_question.sequence}</td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(sys_question)}><i className="ti-pencil"></i></a>
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(sys_question)}><i className="ti-menu-alt"></i></a>
                  </td>
                </tr>
          ) : <tr><td colSpan="5">No Questions Found</td></tr>}
              </tbody>
            </table>
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default Questions;
