import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class DonorModalAP extends Component {
  // Accounts Payable
  constructor(props){
    super(props);
    this.state = {
      donor:null,
      donorStatusList:{'PENDING': ['PENDING','HOLD'], 'NEW DONOR':['TEST ONLY', 'HOLD'], 'QUALIFICATION IN PROCESS':['ACTIVE', 'TEST ONLY', 'HOLD'], 'QUALIFIED':['ACTIVE', 'TEST ONLY', 'HOLD'], 'TERMINATED':['TERMINATED']},
      donationStatusList:['PENDING','HOLD']
    }
  }

  componentDidMount(){
    this.setState({
      donor:this.props.donor
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.donor);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_obj[e.target.name] = value;
    if (e.target.name === 'donor_status'){
      var donationStatusList = this.state.donorStatusList[e.target.value];
      this.setState({donationStatusList:donationStatusList});
    }
    this.setState({donor:updated_obj});
  }
  render() {
    const {modalBackgroundClicked} = this.props;
    const {donor} = this.state;
    return (
      <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title">Accounts Payable</h4>
              <button type="button" className="close" onClick={modalBackgroundClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
          {donor ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Donation Date</th>
                  <th>Paid</th>
                  <th>Status</th>
                  <th>Weight</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>5/2/2019 12:00:00</td>
                  <td><span className="badge badge-danger">UNPAID</span></td>
                  <td>Unused</td>
                  <td>112.9</td>
                </tr>
                <tr>
                  <td>5/2/2019 12:00:00</td>
                  <td><span className="badge badge-danger">UNPAID</span></td>
                  <td>Inspection Complete</td>
                  <td>152.9</td>
                </tr>
                <tr>
                  <td>5/2/2019 12:00:00</td>
                  <td><span className="badge badge-danger">UNPAID</span></td>
                  <td>Mfg</td>
                  <td>312.9</td>
                </tr>
                <tr>
                  <td>5/2/2019 12:00:00</td>
                  <td><span className="badge badge-danger">UNPAID</span></td>
                  <td>Inspection Complete</td>
                  <td>532.9</td>
                </tr>
              </tbody>
            </table>
          :null}
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={modalBackgroundClicked}>
                Close
              </button>
              <button type="button" className="btn btn-primary">Save</button>
          </div>
      </React.Fragment>
    )
  }
}

export default DonorModalAP;
