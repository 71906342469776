import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Spinner} from 'reactstrap';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class DonationReport extends Component {
  // Accounts Payable
  constructor(props){
    super(props);
    this.state = {
      donor_list:[],
      loading:false
    }
  }

  componentDidMount(){
    this.loadDonors();
  }

  loadDonors = () => {
    this.setState({
      loading:true
    }, ()=>{
      connectionGet('donation-report', null, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            donor_list:res.data.donors
          })
        }
      })
      .catch((error)=>{console.log('error')});
    });
  }

  selectedWeight = (dhs_list) => {
    let selected_weight =  0;
    if (dhs_list){
      dhs_list.forEach((item, index) => {
        selected_weight += item.weight;
      });
    }
    return selected_weight;
  }

  estimateDose = (dhs_list) => {
    // check if there are pooled samples for the donor
    let batchDose = 0
    let sampleWeight = 55;
    let selected_weight = this.selectedWeight(dhs_list) - 73; // subtract weight of container
    if (selected_weight > 0 ){
      batchDose = (selected_weight/sampleWeight).toFixed(2)
    }
    return batchDose;
  }

  sampleSize = (dhs_list) => {
    let sampleSize = 0;
    let count = 0;
    dhs_list.forEach((dhs, i) => {
      if (dhs.weight && dhs.weight > 0){
        count++;
      }
    });
    if (count > 0){
      sampleSize = (33/count).toFixed(2);
    }
    return sampleSize;
  }

  render() {
    const {closeHandler} = this.props;
    const {donor_list, loading} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Ops Donation Report</h2>
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadDonors}><i className="fa fa-refresh"/> Refresh</button>
              <hr/>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          {loading ?
            <div className="card p-10">
              <div className="card-body">
                <h4><Spinner color="primary"/> Loading...</h4>
              </div>
            </div>
          :
          donor_list.length > 0 ?
            donor_list.map((donor_d, d_index)=>{
              let donor = donor_d.donor;
              let dhs_list = donor_d.dhs_list;
              return (
              <div key={d_index} className="card p-10">
                <h4 className="card-title">
                  {donor.rbx_donor_id}
                  <span className="badge badge-success text-uppercase no-radius ls-1 opacity-75 ml-3">{donor.sys_donor_status}</span>
                </h4>
                <div className="card-body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Donation Date</th>
                        <th>Lot #</th>
                        <th>Weight</th>
                        <th>Exp Dt</th>
                        <th>Disposition</th>
                        <th>DHS Status</th>
                        <th>Location</th>
                      </tr>
                    </thead>
                    <tbody>
                    {dhs_list.map((dhs, index)=>
                      <tr key={index}>
                        <td>{moment(dhs.donation_date).format('MM/DD/YYYY H:mm:ss a')}</td>
                        <td>{dhs.rbx_lot_number}{dhs.pooled_sample_is_test ? '*' : null}</td>
                        <td>{dhs.weight}</td>
                        <td>{moment(dhs.expiration_date).format('MM/DD/YYYY H:mm:ss a')}</td>
                        <td>{dhs.disposition ? dhs.disposition.sys_disposition : ''}</td>
                        <td>{dhs.sys_dhs_status}</td>
                        <td>Location Name</td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                  <footer className="card-footer flexbox">
                    <div>
                      Donation #: {dhs_list.length}
                    </div>
                    <div>
                      Total Weight: {this.selectedWeight(dhs_list)}
                    </div>
                    <div>
                      Pooled Sample Allocation: {this.sampleSize(dhs_list) > 0 ? this.sampleSize(dhs_list) + 'g' : 'NA'}
                    </div>
                    <div>
                       Estimated Dose <small>(Subtract 73g container, 55g per dose)</small>: <strong>{this.estimateDose(dhs_list)}</strong>
                    </div>
                  </footer>
                </div>
              </div>
            )})
            :
            <div className="card p-10">
              <div className="card-body">
                <h4>No Results Found</h4>
              </div>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }
}

export default DonationReport;


// <div className="form-group col-3">
//   <label>{recipe.unit} Number</label>
//   <input className="form-control col-4" type="number" name="planned_sub_size" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.planned_sub_size : 0} placeholder={recipe.unit}/>
// </div>
// <div className="form-group col-3">
//   <label>Item Number</label>
//   <input className="form-control" type="text" name="item_number" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.material_number : ''} placeholder='Item Number'/>
// </div>
// <div className="form-group col-3">
//   <label>Lot Number</label>
//   <input className="form-control" type="text" name="lot_number" onChange={(e) => this.handleInputChange(e, null)} value={ps ? ps.lot_number : ''} placeholder='Lot Number'/>
// </div>
// <div className="form-group col-3">
//   <label>Expiration Date</label>
//   <p className="form-control-plaintext">{ps.expiration_date ? ps.expiration_date : 'Expiration Set Automatically'}</p>
// </div>
