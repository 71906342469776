import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class DonorQuickview extends Component {
  constructor(props){
      super(props);
      this.state = {
        donorStatusList:{'PENDING': ['PENDING','HOLD'], 'NEW DONOR':['TEST ONLY', 'HOLD'], 'QUALIFICATION IN PROCESS':['ACTIVE', 'TEST ONLY', 'HOLD'], 'QUALIFIED':['ACTIVE', 'TEST ONLY', 'HOLD'], 'TERMINATED':['TERMINATED']},
        donationStatusList:['PENDING','HOLD']
      }
  }

  componentDidMount(){
    var donationStatusList = this.state.donorStatusList[this.props.donor.donor_status];
    this.setState({donationStatusList:donationStatusList});
  }

  handleInputChange = (e) => {
    var donationStatusList = this.state.donorStatusList[e.target.value];
    this.setState({donationStatusList:donationStatusList});
  }


  render() {
    const {closeHandler, donor} = this.props;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <img src={donor.img_url ? donor.img_url : null} className="avatar avatar-xxl avatar-bordered" />
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{donor.rbx_donor_id}</a></h4>
            </div>
          </div>
          <div className="quickview-block form-type-material">
            <div className="form-group do-float">
              <label>Donor Status</label>
              <select className="form-control" name="donor_status" value={donor.donor_status} onChange={this.handleInputChange}>
              {Object.keys(this.state.donorStatusList).map((key, index)=>
                <option key={index}>{key}</option>
              )}
              </select>
            </div>
            <div className="form-group do-float">
              <label>Donor Donating Status</label>
              <select className="form-control" name="donation_status" value={donor.donation_status}>
              {this.state.donationStatusList ? this.state.donationStatusList.map((status, index)=>
                <option key={index}>{status}</option>
              ) : null}
              </select>
            </div>
            <div className="card card-shadowed">
              <div className="card-title">Pathogen Test History</div>
              <div className="media-list media-list-divided media-list-hover">
                <div class="media media-single">
                  <span class="fs-30"><i class="fa fa-bug"></i></span>
                  <span class="title">13001-R12334A <span className="badge badge-danger ml-10">FAIL</span></span>
                  <time datetime="2018-07-14 20:00">5/1/2019</time>
                </div>
              </div>
            </div>
            <div className="card card-shadowed">
              <div className="card-title">DHS History</div>
              <div className="media-list media-list-divided media-list-hover">
                <div class="media media-single">
                  <span class="fs-30"><i class="fa fa-cube"></i></span>
                  <span class="title">13001-12334</span>
                  <time datetime="2018-07-14 20:00">5/1/2019</time>
                </div>
                <div class="media media-single">
                  <span class="fs-30"><i class="fa fa-cube"></i></span>
                  <span class="title">13001-12334</span>
                  <time datetime="2018-07-14 20:00">5/1/2019</time>
                </div>
                <div class="media media-single">
                  <span class="fs-30"><i class="fa fa-cube"></i></span>
                  <span class="title">13001-12334</span>
                  <time datetime="2018-07-14 20:00">5/1/2019</time>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-secondary"><i className="fa-fw fa fa-save"></i> Save Status</button>
            {donor.donor_status !== 'TERMINATED' ?
              <button className="btn btn-lg btn-outline btn-block btn-secondary"><i className="fa-fw fa fa-credit-card"></i> Print ID Card</button>
             : null}
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default DonorQuickview;
