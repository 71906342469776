import React, { Component } from 'react';
import {Route, Switch} from "react-router-dom";
import { inject } from 'mobx-react';
import SaDonors from '../admin/donors/Donors';
import Users from '../admin/users/Users';
import DonorGroups from '../admin/donor-group/DonorGroups';
import Status from '../admin/status/Status';
import Donors from '../donors/Donors';
import DonorPayments from '../donors/DonorPayments';
import DHS from '../dhs/DHS';
import DHSStatus from '../dhs/DHSStatus';
import DonationReport from '../dhs/DonationReport';
import PooledSamples from '../dhs/PooledSamples';
import Recipes from '../admin/bom/Recipes';
import Storage from '../admin/bom/Storage';
import Tests from '../admin/tst';
import Questions from '../admin/tst/Question';
import ManufacturingKits from '../manufacturing/Kits';
import PEGSaline from '../manufacturing/PEGSaline';
import BatchRecords from '../manufacturing/BatchRecords';
import DrugSubstance from '../manufacturing/DrugSubstance';
import Doses from '../manufacturing/Doses';
import COAPrint from '../donors/COA';
import KitPrint from '../donors/Kit';
import nav from './Constants';
import Permissions from '../admin/bom/Permissions';
import UserRoles from '../admin/bom/UserRoles';
import Audit from './Audit';
import Default from './Default';
import Error from './Error';
import DonorReports from '../reporting/DonorReports';
import ManufacturingReports from '../reporting/ManufacturingReports';
import ShippingReports from '../reporting/ShippingReports';
import OpsReports from '../reporting/OpsReports';
// import SubstanceLots from './SubstanceLots';
// import Batches from './Batches';
// import AttendanceLog from './AttendanceLog';
// import PlanningTool from './PlanningTool';
// import ProductionPlan from './ProductionPlan';


@inject('userStore')
class Routes extends Component {
  // permissioning object below assures the user cannot directly access an asset - it will be verified against the user permission object.  error and default are special cases that get approved for all users.  sa routes are only accessible by super administrators
  render() {
    const all_routes = {
      "default": <Route key={"default"} exact path="/" component={Default}/>,
      "system_admin.donors": <Route key={"system_admin.donors"} exact path="/admin/donors" component={SaDonors}/>,
      "system_admin.donor_groups": <Route key={"system_admin.donor_groups"} exact path="/admin/donor-groups" component={DonorGroups}/>,
      "system_admin.users": <Route key={"system_admin.users"} exact path="/admin/users" component={Users}/>,
      "donors.donors": <Route key={"donors.donors"} exact path="/donors" component={Donors}/>,
      "donors.donor_payments": <Route key={"donors.donor_payments"} exact path="/donor-payments" component={DonorPayments}/>,
      "dhs.dhs": <Route key={"dhs.dhs"} exact path="/dhs" component={DHS}/>,
      "dhs.dhs_status": <Route key={"dhs.dhs_status"} exact path="/dhs-status" component={DHSStatus}/>,
      "donation_report": <Route key={"donation_report"} exact path="/donation-report" component={DonationReport}/>,
      "dhs.pooled_samples": <Route key={"dhs.pooled_samples"} exact path="/dhs/pooled-samples" component={PooledSamples}/>        ,
      "manufacturing.peg_saline": <Route key={"manufacturing.peg_saline"} exact path="/manufacturing/peg-saline" component={PEGSaline}/>,
      "manufacturing.drug_substance": <Route key={"manufacturing.drug_substance"} exact path="/manufacturing/drug-substance" component={DrugSubstance}/>,
      "manufacturing.doses": <Route key={"manufacturing.doses"} exact path="/manufacturing/doses" component={Doses}/>,
      "coa": <Route key={"coa"} exact path="/coa" component={COAPrint}/>,
      "manufacturing.kit_print": <Route key={"manufacturing.kit_print"} exact path="/kit" component={KitPrint}/>,
      "manufacturing.kits": <Route key={"manufacturing.kits"} exact path="/manufacturing/kits" component={ManufacturingKits}/>,
      "sa.donor_status": <Route key={"sa.donor_status"} exact path="/admin/donor-status" render={(props) => <Status statusType={"Qualification"}/>}/>,
      "sa.donating_status": <Route key={"sa.donating_status"} exact path="/admin/donating-status" render={(props) => <Status statusType={"Donating"}/>}/>,
      "system_admin.dose_status": <Route key={"sa.dose_status"} exact path="/admin/dose-status" render={(props) => <Status statusType={"Dose"}/>}/>,
      "sa.dhs_status": <Route key={"sa.dhs_status"} exact path="/admin/dhs-status" render={(props) => <Status statusType={"DHS"}/>}/>,
      "sa.storage": <Route key={"sa.storage"} exact path="/sa/storage" component={Storage}/>,
      "sa.bom_recipes": <Route key={"sa.bom_recipes"} exact path="/sa/bom-recipes" component={Recipes}/>,
      "sa.tests": <Route key={"sa.tests"} exact path="/sa/tests" component={Tests}/>,
      "sa.questions": <Route key={"sa.questions"} exact path="/sa/questions" component={Questions}/>,
      "sa.permissions": <Route key={"sa.permissions"} exact path="/sa/permissions" component={Permissions}/>,
      "sa.user_roles": <Route key={"sa.user_roles"} exact path="/sa/user-roles" component={UserRoles}/>,
      "reporting.donors": <Route key={"reporting.donors"} exact path="/reports/donors" component={DonorReports}/>,
      "reporting.manufacturing": <Route key={"reporting.manufacturing"} exact path="/reports/manufacturing" component={ManufacturingReports}/>,
      "reporting.shipping": <Route key={"reporting.shipping"} exact path="/reports/shipping" component={ShippingReports}/>,
      "reporting.ops": <Route key={"reporting.ops"} exact path="/reports/ops" component={OpsReports}/>,
    }
    let routes = {};
    Object.keys(all_routes).map((key)=>{
      if (this.props.userStore.hasPermission(key, 1)){
        routes[key] = all_routes[key];
      } else if (this.props.userStore.is_admin === 1) {
        routes[key] = all_routes[key];
      }
    });
    return (
    	<Switch>
        <Route exact path="/audit/:className/:idValue/:primaryKey" component={Audit}/>
        {Object.keys(routes).map((key)=>
          routes[key]
        )}
        <Route render={(props) => <Error error={403}/>}/>
        <Route exact path="/donation-report" component={DonationReport}/>
      </Switch>
    )
  }
}

export default Routes;

// <Route exact path="/attendance-log" component={AttendanceLog}/>
// <Route exact path="/planning-tool" component={PlanningTool}/>
// <Route exact path="/production-plan" component={ProductionPlan}/>
// <Route exact path="/donor-human-stool" component={SubstanceLots}/>
// <Route exact path="/batches" component={Batches}/>
