import React, { Component } from 'react';
import UserRolesQuickview from './UserRolesQuickview';
import Modal from 'react-bootstrap4-modal';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../../utils/connection';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class UserRoles extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        quickviewVisible:false,
        quickviewComponent:null,
        statusFilter:'all',
        user_role:null,
        user_roles:[],
        user_rolesOriginal:[],
        searchName:'',
        sys_permission_list:[]
      }
  }

  componentDidMount(){
    this.loadUserRoles();
  }

  loadUserRoles = () => {
    connectionGet('roles', {}, {username: this.props.userStore.token})
    .then((res)=>{
      if (res.data.errors === 0){
        this.setState({
          user_roles:res.data.sys_user_role_list,
          user_rolesOriginal:res.data.sys_user_role_list,
          sys_permission_list:res.data.sys_permission_list
        })
      }
    })
    .catch((error)=>{toast.error(error.response.statusText, toastParams);});
  }

  addUserRole = (user_role) => {
    if (user_role.id){
      connectionPut('roles/' + user_role.id, {sys_user_role:user_role}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadUserRoles();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    } else {
      connectionPost('roles', {sys_user_role:user_role}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.loadUserRoles();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }

  closeHandler = () => {
    this.setState({
      quickviewVisible:!this.state.quickviewVisible,
      quickviewComponent:null
    })
  }

  openHandler = (user_role) => {
    this.setState({
      quickviewVisible:true,
      quickviewComponent:<UserRolesQuickview addHandler={this.addUserRole} closeHandler={this.closeHandler} user_role={user_role} sys_permission_list={this.state.sys_permission_list}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }


  render() {
    const {searchName, user_roles} = this.state;
    return (
      <React.Fragment>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>User Roles</h2>
              <button className="btn btn-sm btn-success" type="button" onClick={() => this.openHandler(null)}><i className="fa fa-plus"/> Add User Role</button>
              <hr/>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th width="10%">Name</th>
                  <th width="50%">Permissions</th>
                  <th width="40%">Assigned Users</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {user_roles ? user_roles.map((user_role, index)=>
                <tr key={index}>
                  <td>{user_role.name}</td>
                  <td>
                    <ul className="list-iconic pl-0 mb-0">
                    {user_role.children && user_role.children.length > 0 ?
                      user_role.children.map((children, children_index)=>
                      <li key={children_index}><i className="icon ion-flash"></i>[{children.permission}] - <strong>{children.level}</strong></li>
                      )
                    : null}
                    </ul>
                  </td>
                  <td>
                    <ul className="list-iconic pl-0 mb-0">
                    {user_role.user_list && user_role.user_list.length > 0 ?
                      user_role.user_list.map((user, user_index)=>
                      <li key={user_index}><i className="icon ti-user"></i>{user.user_name}</li>
                      )
                    : null}
                    </ul>
                  </td>
                  <td className="text-right table-actions">
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(user_role)}><i className="ti-pencil"></i></a>
                    <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(user_role)}><i className="ti-menu-alt"></i></a>
                  </td>
                </tr>
              ) : <tr><td colSpan="5">No UserRoles Found</td></tr>
              }
              </tbody>
            </table>
          </div>
          <br/>
        </div>
        <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
          {this.state.quickviewComponent}
        </div>
      </React.Fragment>
      );
    }
}
export default UserRoles;
