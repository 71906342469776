import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class QuestionQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      sys_question:{id:null, name:'', test_code:'', question_text:'', acceptance_criteria:'', question_type:'', sequence:0, acceptance_criteria_rule:'{"PASS":{"action":"prepopulate", "value":""}, "FAIL":{"action":"manual"}}'}
    }
  }

  componentDidMount(){
    if (this.props.sys_question !== null){
      this.setState({
        sys_question:this.props.sys_question,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.sys_question);
    this.setState({
      sys_question:{id:null, name:'', test_code:'', question_text:'', acceptance_criteria:'', question_type:'', sequence:0}
    });
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.sys_question);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_obj[e.target.name] = value;
    this.setState({sys_question:updated_obj},()=>{console.log(this.state.sys_question)});
    //
    // let updatedSelectedItem = Object.assign({}, this.state.selectedItem);
    // this.setState({selectedItem:updatedSelectedItem});
  }

  render() {
    if (!this.state.sys_question) return <div>Loading</div>;
    const {closeHandler} = this.props;
    const {sys_question} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!sys_question.id ? 'Add Question' : sys_question.name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="test-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Name</label>
                    <input required className="form-control" type="text" name="name" onChange={this.handleInputChange} value={sys_question.name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Test Code</label>
                    <input required className="form-control" type="text" name="test_code" onChange={this.handleInputChange} value={sys_question.test_code}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Question Text</label>
                    <input required className="form-control" type="text" name="question_text" onChange={this.handleInputChange} value={sys_question.question_text}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Acceptance Criteria</label>
                    <input required className="form-control" type="text" name="acceptance_criteria" onChange={this.handleInputChange} value={sys_question.acceptance_criteria}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Acceptance Criteria Rule</label>
                    <input required className="form-control" type="text" name="acceptance_criteria_rule" onChange={this.handleInputChange} value={sys_question.acceptance_criteria_rule}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Question type</label>
                    <select required className="form-control" name="question_type" onChange={this.handleInputChange} value={sys_question.question_type}>
                      <option value={'NONE'}>NONE</option>
                      <option value={'PASS/FAIL'}>PASS/FAIL</option>
                      <option value={'YES/NO'}>YES/NO</option>
                      <option value={'GROWTH'}>GROWTH</option>
                      <option value={'SCI'}>SCI</option>
                      <option value={'DATA'}>DATA</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Sequence</label>
                    <input required className="form-control" type="text" name="sequence" onChange={this.handleInputChange} value={sys_question.sequence}/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="test-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default QuestionQuickview;
