import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class StorageQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      storage:{id:null, name:'', sys_bom_storage_type_id:1, description:'', capacity:0, sequence:0, parent_id:null}
    }
  }

  componentDidMount(){
    console.log(this.props.storage);
    if (this.props.storage !== null){
      this.setState({
        storage:this.props.storage,
      })
    } else if (this.props.parent_id !== null){
      let storage_tmp = Object.assign({}, this.state.storage);
      storage_tmp.parent_id = this.props.parent_id;
      this.setState({
        storage:storage_tmp
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.storage);
  }

  handleSubmitAdd = () => {
    this.props.addHandler(this.state.storage);
    if (this.state.parent_id){
      this.setState({
        storage:{id:null, name:'', sys_bom_storage_type_id:this.state.sys_bom_storage_type_id, description:'', capacity:0, sequence:0, parent_id:this.state.parent_id}
      })
    } else {
      this.setState({
        storage:{id:null, name:'', sys_bom_storage_type_id:1, description:'', capacity:0, sequence:0, parent_id:null}
      })
    }
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.storage);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (e.target.name === 'children'){
      var child_value = parseInt(e.target.value);
      let manufacture_item = this.props.manufacture_item_list.find(su => su.id === child_value);
      if (e.target.checked && !updated_obj.children.includes(manufacture_item)){
        updated_obj.children.push(manufacture_item)
      } else {
        // var index = updated_obj.role_list.indexOf(su => su.sys_user_role_id === role_value);
        var index = updated_obj.children.map(function(e) { return e.id; }).indexOf(child_value);
        if (index !== -1) updated_obj.children.splice(index, 1);
      }
    } else {
      updated_obj[e.target.name] = value;
    }
    this.setState({storage:updated_obj});
  }

  render() {
    if (!this.state.storage) return <div>Loading</div>;
    const {closeHandler, sys_bom_storage_type_list} = this.props;
    let {storage} = this.state;
    console.log(storage)
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!storage.id ? 'Add Storage' : storage.name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="storage-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Name</label>
                    <input required className="form-control" type="text" name="name" onChange={this.handleInputChange} value={storage.name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label>Description</label>
                    <input className="form-control" type="text" name="description" onChange={this.handleInputChange} value={storage.description}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Storage Type</label>
                    <select required className="form-control" name="sys_bom_storage_type_id" onChange={this.handleInputChange} value={storage.sys_bom_storage_type_id}>
                    {sys_bom_storage_type_list.map((storage_type, storage_type_index)=>
                      <option key={storage_type_index} value={storage_type.id}>{storage_type.name}</option>
                    )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <label>Capactiy</label>
                    <input className="form-control" type="text" name="capacity" onChange={this.handleInputChange} value={storage.capacity}/>
                  </div>
                  <div className="form-group col-6">
                    <label>Sequence</label>
                    <input className="form-control" type="text" name="sequence" onChange={this.handleInputChange} value={storage.sequence}/>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="storage-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-success" onClick={this.handleSubmitAdd}><i className="fa fa-fw fa-save"></i> Save & Add New</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default StorageQuickview;
