import React, { Component } from 'react';
import moment from 'moment';

class DHSStatusDetail extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const {dhs, qc_in, qc_out, ops_receiving} = this.props;
    return (
      <div className="card b-1 hover-shadow-2 mb-20">
        <div className="card-body cursor-pointer pt-0 pb-0">
          <div className="media row bb-1 pb-0">
            <div className="media-body">
              <div className="mb-2">
                <span className="fs-24 pr-16">{dhs.rbx_lot_number}</span>
                <div className="ml-2 mr-2 d-inline">
                Donor Status: <span className="badge badge-success text-uppercase no-radius ls-1 opacity-75">{dhs.sys_donor_status}</span>
                </div>
                <div className=" mr-2 d-inline">
                DHS Status: <span className="badge badge-success text-uppercase no-radius ls-1 opacity-75">{dhs.sys_dhs_status}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card row">
            <div className="row no-gutters py-2">
              <div className="col-sm-6 col-lg-4">
                <div className="card pb-0 mb-0">
                  <h4 className="card-title">Ops Receiving</h4>
                  <div className="card-body br-1 border-light pb-0">
                    <div className="flexbox mb-1">
                      <span className="fa fa-sign-in fs-20"></span>
                      <span className="fs-14 fw-100">Time In:<br/><strong>{ops_receiving && ops_receiving.status_datetime ? moment(ops_receiving.status_datetime).format('YYYY-MM-DD hh:mm') : null}</strong></span>
                      <span className="fs-14 fw-100">Received within 24hr?<br/>{ops_receiving && ops_receiving.received === true ? <span className="badge badge-success">YES</span> : <span className="badge badge-danger">NO</span>}</span>
                    </div>
                    <div className="text-right mt-2">Performed by: {ops_receiving && ops_receiving.user ? ops_receiving.user.full_name : 'NA'}</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 hidden-down">
                <div className="card pb-0 mb-0">
                  <h4 className="card-title">QC Inspection</h4>
                  <div className="card-body br-1 border-light pb-0">
                    <div className="row">
                      <div className="col-6">
                        <div className="card-body p-0">
                          <div className="flexbox mb-1">
                            <span className="fa fa-sign-out fs-20"></span>
                            <span className="fs-14 fw-100">Time Out:<br/><strong>{qc_out && qc_out.status_datetime ? moment(qc_out.status_datetime).format('YYYY-MM-DD hh:mm') : null}</strong></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="card-body p-0">
                          <div className="flexbox mb-1">
                            <span className="fa fa-sign-in fs-20"></span>
                            <span className="fs-14 fw-100">Time In:<br/><strong>{qc_in && qc_in.status_datetime ? moment(qc_in.status_datetime).format('YYYY-MM-DD hh:mm') : null}</strong></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right mt-2">Performed by: {qc_in && qc_in.user ? qc_in.user.full_name : 'NA'}</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4 hidden-down">
                <div className="card pb-0 mb-0">
                  <h4 className="card-title">DHS Inventory Adjustment</h4>
                  <div className="card-body pb-0">
                    <div className="text-right"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default DHSStatusDetail;
