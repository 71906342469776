import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class DonorModalAdd extends Component {
  constructor(props){
    super(props);
    this.state = {
      donor:null,
      donorStatusList:{'PENDING': ['PENDING','HOLD'], 'NEW DONOR':['TEST ONLY', 'HOLD'], 'QUALIFICATION IN PROCESS':['ACTIVE', 'TEST ONLY', 'HOLD'], 'QUALIFIED':['ACTIVE', 'TEST ONLY', 'HOLD'], 'TERMINATED':['TERMINATED']},
      donationStatusList:['PENDING','HOLD']
    }
  }

  componentDidMount(){
    this.setState({
      donor:this.props.donor
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.donor);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    updated_obj[e.target.name] = value;
    if (e.target.name === 'donor_status'){
      var donationStatusList = this.state.donorStatusList[e.target.value];
      this.setState({donationStatusList:donationStatusList});
    }
    this.setState({donor:updated_obj});
  }
  render() {
    const {modalBackgroundClicked} = this.props;
    const {donor} = this.state;
    return (
      <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title">Add Donor</h4>
              <button type="button" className="close" onClick={modalBackgroundClicked} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body">
          {donor ?
            <React.Fragment>
            <form className="card form-type-combine">
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-6">
                    <label>Donor ID</label>
                    <input className="form-control" type="text" name="donor_id" onChange={this.handleInputChange} value={donor.donor_id}/>
                  </div>
                  <div className="form-group col-6">
                    <label>SSN</label>
                    <input className="form-control" type="text" name="ssn" onChange={this.handleInputChange} value={donor.ssn}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-6">
                    <label>Donor Status</label>
                    <select className="form-control" name="donor_status" onChange={this.handleInputChange}>
                    {Object.keys(this.state.donorStatusList).map((key, index)=>
                      <option key={index}>{key}</option>
                    )}

                      <option value="INITIAL SCAN">INITIAL SCAN</option>
                      <option value="INSPECTION COMPLETE">INSPECTION COMPLETE</option>
                    </select>
                  </div>
                  <div className="form-group col-6">
                    <label>Donor Donating Status</label>
                    <select className="form-control">
                    {this.state.donationStatusList ? this.state.donationStatusList.map((status, index)=>
                      <option key={index}>{status}</option>
                    ) : null}
                    </select>
                  </div>
                </div>
              </div>
            </form>
            </React.Fragment>
          :null}
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={modalBackgroundClicked}>
                Close
              </button>
              <button type="button" className="btn btn-primary">Save</button>
          </div>
      </React.Fragment>
    )
  }
}

export default DonorModalAdd;
//
//
// <form className="card form-type-combine">
//   <div className="form-groups-attached">
//     <div className="row">
//       <div className="form-group col-12">
//         <label>Batch Status</label>
//         <input className="form-control" type="text" name="status" onChange={this.handleInputChange} value={selectedDonor.status}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Rebiotix Item #</label>
//         <input className="form-control" type="text" name="rebiotix_number" onChange={this.handleInputChange} value={selectedDonor.rebiotix_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>PEG Saline #</label>
//         <input className="form-control" type="text" name="peg_saline_number" onChange={this.handleInputChange} value={selectedDonor.peg_saline_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Bag Lot #</label>
//         <input className="form-control" type="text" name="bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Filter Bag Lot #</label>
//         <input className="form-control" type="text" name="filter_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.filter_bag_lot_number}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Closure Bag Lot #</label>
//         <input className="form-control" type="text" name="closure_bag_lot_number" onChange={this.handleInputChange} value={selectedDonor.closure_bag_lot_number}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Doses Produced</label>
//         <input className="form-control" type="text" name="doses_produced" onChange={this.handleInputChange} value={selectedDonor.doses_produced}/>
//       </div>
//     </div>
//     <div className="row">
//       <div className="form-group col-6">
//         <label>Manufacture Date</label>
//         <input className="form-control" type="text" name="manufacture_date" onChange={this.handleInputChange} value={selectedDonor.manufacture_date}/>
//       </div>
//       <div className="form-group col-6">
//         <label>Expiration Date</label>
//         <input className="form-control" type="text" name="expiration_date" onChange={this.handleInputChange} value={selectedDonor.expiration_date}/>
//       </div>
//     </div>
//   </div>
// </form>
