import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import BarChart from './common/BarChart';
import HBarChart from './common/HBarChart';
import DonutChart from './common/DonutChart';
import LineChart from './common/LineChart';
import DataTable from './common/DataTable';
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import DatePicker from "react-datepicker";
import {Spinner} from 'reactstrap';
import {CSVLink, CSVDownload} from 'react-csv';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datepicker/dist/react-datepicker.css";

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class ShippingReports extends Component {
  constructor(props){
    super(props);
    this.state = {
      output:null,
      loading:false,
      filter: {
        report:'shipping.metrics_unblinded',
        start_date: new Date().setMonth(new Date().getMonth()-12),
        end_date: new Date()
      }
    }
  }

  componentDidMount(){
  }

  handleInputChange = (e) => {
    let tmp = Object.assign({}, this.state.filter);
    tmp.report = e.target.value;
    this.setState({
      filter:tmp
    })
  }

  handleDateChange = (date, type) => {
    let tmp = Object.assign({}, this.state.filter);
    tmp[type] = date;
    this.setState({
      filter:tmp
    });
  }

  fetchReports = () => {
    let tmp = Object.assign({}, this.state.filter);
    tmp.start_date = moment(this.state.filter.start_date).format('YYYY-MM-DD');
    tmp.end_date = moment(this.state.filter.end_date).format('YYYY-MM-DD');
    this.setState({
      loading:true,
      filter:tmp
    },()=>{
      connectionPost('shipping-reports', {filter:this.state.filter}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          // toast.success(res.data.message, toastParams);
          this.setState({
            loading:false,
            output:res.data.output
          })
        } else {
          alert(res.data.message);
          this.setState({
            loading:false
          })
        }
      })
      .catch((error)=>{console.log(error)});
    })
  }


  render() {
    const {results, loading, filter, output, graph_results} = this.state;
    return (
      <React.Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
          pauseOnHover={false}
        />
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Shipping Reports</h2>
              <div className="form-group">
                <div className="label">Report</div>
                <select className="form-control" value={filter.report} onChange={this.handleInputChange}>
                  <option value={'shipping.metrics_unblinded'}>Shipping Metrics Unblinded</option>
                  <option value={'shipping.rbx2660_inventory'}>RBX2660 Inventory</option>
                </select>
              </div>
              <div className="form-group">
                <div className="label">Start</div>
                <DatePicker
                  selected={filter.start_date ? new Date(filter.start_date) : null}
                  onChange={(date)=>this.handleDateChange(date, 'start_date')}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="form-group">
                <div className="label">End</div>
                <DatePicker
                  selected={filter.end_date ? new Date(filter.end_date) : null}
                  onChange={(date)=>this.handleDateChange(date, 'end_date')}
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
              <div className="btn-group-vertical">
                <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.fetchReports}><i className="fa fa-refresh"/> Get Results</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
           <div className="card card-body p-10">
            {loading ?
              <h4><Spinner color="primary"/> Loading...</h4>
            :
            results === null ?
              <h4>Please select a report and choose a date range.</h4>
            : output && output.length > 0 ?
              output.map((output_type, output_type_index)=>{
                if (output_type.type === 'stacked_bar'){
                  return (
                    <div key={output_type_index}>
                      <h2>{output_type.name}</h2>
                      <HBarChart data={output_type.data_set}/>
                    </div>
                  );
                }
                if (output_type.type === 'download'){
                  return (
                    <React.Fragment>
                      <CSVLink key={output_type_index} className="btn btn-primary pull-right" filename={filter.report + '.csv'} data={output_type.data_set} >Download {output_type.name}</CSVLink>
                      <DataTable data={output_type.data_set}/>
                    </React.Fragment>);
                }
                if (output_type.type === 'line'){
                  return (
                    <div key={output_type_index}>
                      <h2>{output_type.name}</h2>
                      <LineChart name={output_type.name} scales={output_type.data_set.scales} data={output_type.data_set}/>
                    </div>
                  );
                }
              })
            : <h4>Please select a report and choose a date range.</h4>
            }
          </div>
        </div>
      </React.Fragment>
      );
    }
}
export default ShippingReports;
