import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

class KitsModal extends Component {
  // Accounts Payable
  constructor(props){
    super(props);
    this.state = {
      batch_record:{batch_record_number:'', batch_size:0},
      kit:null,
      recipe:null,
      sys_bom_status_list:[],
      is_edit:false
    }
  }

  componentDidMount(){
    if (this.props.kit === null){
      this.setState({
        is_edit:false,
        kit:this.props.recipe,
        recipe:this.props.recipe,
        sys_bom_status_list:this.props.sys_bom_status_list
      })
    } else {
      this.setState({
        is_edit:true,
        kit:this.props.kit,
        recipe:this.props.recipe,
        sys_bom_status_list:this.props.sys_bom_status_list
      },()=>{
        let tmp_recipe = Object.assign({}, this.state.recipe);
        tmp_recipe.children = this.state.kit.children;
        this.setState({
          recipe:tmp_recipe
        })
      })
    }
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.kit === null){
      this.setState({
        is_edit:false,
        kit:nextProps.recipe,
        recipe:nextProps.recipe,
        sys_bom_status_list:nextProps.sys_bom_status_list
      })
    } else {
      this.setState({
        is_edit:true,
        kit:nextProps.kit,
        recipe:nextProps.recipe,
        sys_bom_status_list:nextProps.sys_bom_status_list
      },()=>{
        let tmp_recipe = Object.assign({}, this.state.recipe);
        tmp_recipe.children = this.state.kit.children;
        this.setState({
          recipe:tmp_recipe
        })
      })
    }
  }

  handleDateChange = (date, child) => {
    let updated_obj = Object.assign({}, this.state.kit);
    if (child){
      let updated_child = updated_obj.children.find(su => su.id === child.id);
      updated_child.expiration_date = date;
    } else {
      updated_obj.expiration_date = date;
    }
    this.setState({kit:updated_obj});
  }

  addHandler = () => {
    this.props.addHandler(this.state.kit, this.state.batch_record);
  }

  handleBatchInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.batch_record);
    const value = e.target.value;
    updated_obj[e.target.name] = value;
    this.setState({batch_record:updated_obj});
  }

  handleInputChange = (e, child) => {
    let updated_obj = Object.assign({}, this.state.kit);
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (!child){
      if (e.target.name === 'planned_sub_size'){
        if (value < 11){
          updated_obj[e.target.name] = parseInt(value);
          if (updated_obj.children){
            updated_obj.children.map((child, i) => {
              if (updated_obj && updated_obj.planned_sub_size && child.quantity_rule === 'MULTIPLY'){
                child.quantity = child.quantity_init * updated_obj.planned_sub_size;
              }
            })
          }
        } else {
          alert('Only 10 doses allowed per kit');
        }
      } else {
        updated_obj[e.target.name] = value;
      }
    } else {
      let updated_child = updated_obj.children.find(su => su.id === child.id);
      updated_child[e.target.name] = value;
    }
    this.setState({kit:updated_obj});
  }

  render() {
    if (!this.state.recipe) return <div>Loading</div>;
    const {closeHandler} = this.props;
    const {kit, recipe, batch_record, is_edit} = this.state;
    return (
      <React.Fragment>
          <div className="modal-header">
              <h4 className="modal-title">{recipe.name}</h4>
              <button type="button" className="close" onClick={closeHandler} aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
          </div>
          <div className="modal-body h-800px">
          <PerfectScrollbar>
            <div className="form-type-combine">
              <div className="bg-lighter p-20 b-1 mb-3">
                <div className={is_edit ? "d-none" : null}>
                  <h6 className="text-light fw-300">Batch Information</h6>
                  <div className="form-groups-attached">
                    <div className="row">
                      <div className="form-group col-3">
                        <label>Lot Number</label>
                        <input className="form-control col-12" type="text" name="batch_record_number" onChange={this.handleBatchInputChange} value={batch_record.batch_record_number} placeholder="Batch Record Number"/>
                      </div>
                      <div className="form-group col-3">
                        <label>Batch Size</label>
                        <input className="form-control" type="number" name="batch_size" onChange={this.handleBatchInputChange} value={batch_record.batch_size} placeholder='Batch Size'/>
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="text-light fw-300">Manufacturing Kit Information</h6>
                <div className="form-groups-attached">
                  <div className="row">
                    <div className="form-group col-4">
                      <label>Quantity of {recipe.unit}s in Kit</label>
                      <input className="form-control col-4" type="number" name="planned_sub_size" onChange={(e) => this.handleInputChange(e, null)} value={kit ? kit.planned_sub_size : 0} placeholder={recipe.unit + 's'}/>
                    </div>
                    <div className="form-group col-4">
                      <label>Item Number</label>
                      <input className="form-control" type="text" name="item_number" onChange={(e) => this.handleInputChange(e, null)} value={kit ? kit.material_number : ''} placeholder='Item Number'/>
                    </div>
                    <div className="form-group col-4">
                      <label>Expiration Date</label>
                      <p className="form-control-plaintext">{kit.expiration_date ? kit.expiration_date : 'Expiration Set Automatically'}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-lighr p-20 b-1">
            {recipe.children.map((child, child_index)=>
              <React.Fragment key={child_index}>
                <h6 className="text-light fw-300">{child.name}</h6>
                <div className="form-groups-attached">
                  <div className="row">
                    <div className="form-group col-3">
                      <label>Material Number</label>
                      <input className="form-control" name="material_number" type="text" value={child.material_number} onChange={(e) => this.handleInputChange(e, child)}/>
                    </div>
                    <div className={child.is_extended ? "form-group col-3" : "form-group col-3 bg-light"}>
                      <label>Expiration date</label>
                      <DatePicker
                        selected={child.expiration_date ? moment(child.expiration_date).toDate() : null}
                        onChange={(date)=>this.handleDateChange(date, child)}
                        className="form-control"
                        dateFormat="yyyy-MM-dd"
                        disabled={!child.is_extended}
                      />
                    </div>
                    <div className={child.is_extended ? "form-group col-3" : "form-group col-3 bg-light"}>
                      <label>Lot Number</label>
                      <input disabled={!child.is_extended} className="form-control" name="lot_number" value={child.lot_number} type="text" onChange={(e) => this.handleInputChange(e, child)}/>
                    </div>
                    <div className="form-group col-3">
                      <label>Quantity {child.quantity_rule === 'MULTIPLY' ? 'x ' + recipe.unit : null}</label>
                      <input className="form-control" name="quantity" type="number" value={child.quantity} onChange={(e) => this.handleInputChange(e, child)}/>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
              </div>
            </div>
            </PerfectScrollbar>
          </div>
          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeHandler}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={this.addHandler}>Save</button>
          </div>
      </React.Fragment>
    )
  }
}

export default KitsModal;
