import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';
import moment from 'moment';

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

Font.register({
  family: "Cousine",
  src: require('../../img/Cousine-Regular.ttf')
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    flexGrow:0,
    fontFamily : "Cousine"
  },
  pageArea:{
    flexDirection: 'row'
  },
  signSection: {
    paddingTop:40,
    paddingBottom: 10,
    flexGrow: 1,
    flexDirection: 'row',
    width:'100%',
    textAlign:'center'
  },
  checkSection: {
    textAlign:'center',
    marginBottom:10,
    flexDirection:'row',
    marginTop:0
  },
  section: {
    marginLeft: 10,
    marginRight:10,
    marginTop:10,
    paddingBottom: 0,
    flexGrow: 1,
    flexDirection: 'column'
  },
  p: {
    fontSize:10
  },
  heading: {
    fontSize:25,
    marginTop:25+'px'
  },
  subheading: {
    fontSize:20,
    marginTop:20+'px'
  },
  address: {
    fontSize:15,
    marginTop:35+'px'
  },
  address2: {
    fontSize:10,
    marginTop:5+'px'
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom:'10px'
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableRowHeading: {
    backgroundColor:'#e5e5e5',
    margin: "auto",
    flexDirection: "row"
  },
  tableColFooter1: {
    width: "70%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign:'left'
  },
  tableCol25: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol25E: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol20: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol15: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol15E: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol5: {
    width: "5%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol10: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableColEnd: {
    borderRightWidth:1
  },
  tableCell: {
    textAlign:"left",
    textTransform:'uppercase',
    padding: 2,
    marginTop: 5,
    fontSize: 10,
    fontFamily : "Cousine"
  }
});

@inject('userStore')
class DonationReport extends Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
  }

  selectedWeight = (dhs_list) => {
    let selected_weight =  0;
    if (dhs_list){
      dhs_list.forEach((item, index) => {
        selected_weight += item.weight;
      });
    }
    return selected_weight;
  }

  estimateDose = (dhs_list) => {
    // check if there are pooled samples for the donor
    let batchDose = 0
    let sampleWeight = 55;
    let selected_weight = this.selectedWeight(dhs_list) - 73; // subtract weight of container
    if (selected_weight > 0 ){
      batchDose = (selected_weight/sampleWeight).toFixed(2)
    }
    return batchDose;
  }

  sampleSize = (dhs_list) => {
    let sampleSize = 0;
    let count = 0;
    dhs_list.forEach((dhs, i) => {
      if (dhs.weight && dhs.weight > 0){
        count++;
      }
    });
    if (count > 0){
      sampleSize = (33/count).toFixed(2);
    }
    return sampleSize;
  }

  render() {
    const {donor_list} = this.props;
    console.log(donor_list)
    let donor_views = donor_list.map((donor_d, d_index)=>
    <View key={d_index} style={styles.section}>
      <Text style={styles.subheading}>{donor_d.donor.rbx_donor_id}</Text>
      <View style={styles.table}>
        <View style={styles.tableRowHeading}>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>Donation Dt</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>Lot #</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCell}>Weight</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>Exp Dt</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>Disposition</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>DHS Status</Text>
          </View>
          <View style={styles.tableCol15E}>
            <Text style={styles.tableCell}>Location</Text>
          </View>
        </View>
        {donor_d.dhs_list.map((dhs, index)=>
        <View key={index} style={styles.tableRow}>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>{moment(dhs.donation_date).format('MM/DD/YYYY')}</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>{dhs.rbx_lot_number}{dhs.pooled_sample_is_test ? '*' : null}</Text>
          </View>
          <View style={styles.tableCol10}>
            <Text style={styles.tableCell}>{dhs.weight}</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>{moment(dhs.expiration_date).format('MM/DD/YYYY')}</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>{dhs.disposition ? dhs.disposition.sys_disposition : ''}</Text>
          </View>
          <View style={styles.tableCol15}>
            <Text style={styles.tableCell}>{dhs.sys_dhs_status}</Text>
          </View>
          <View style={styles.tableCol15E}>
            <Text style={styles.tableCell}>Location Name</Text>
          </View>
        </View>
        )}
        <View style={styles.tableRowHeading}>
          <View style={styles.tableCol25}>
            <Text style={styles.tableCell}>Donation #: {donor_d.dhs_list.length}</Text>
          </View>
          <View style={styles.tableCol25}>
            <Text style={styles.tableCell}>Total Weight: {this.selectedWeight(donor_d.dhs_list)}</Text>
          </View>
          <View style={styles.tableCol25}>
            <Text style={styles.tableCell}>Pooled Sample Allocation: {this.sampleSize(donor_d.dhs_list) > 0 ? this.sampleSize(donor_d.dhs_list) + 'g' : 'NA'}</Text>
          </View>
          <View style={styles.tableCol25E}>
            <Text style={styles.tableCell}>Est. Dose: {this.estimateDose(donor_d.dhs_list)}</Text>
          </View>
        </View>
      </View>
    </View>
    );
    console.log(donor_views)
    return (
      <div style={{height:1000+'px'}}>
      <PDFViewer width={100+'%'} height={100+'%'}>
        <Document>
          <Page size="A4" style={styles.page} orientation="landscape">
            <View style={styles.pageArea}>
              <View style={styles.section}>
                <Text style={styles.heading}>Ops Donation Report</Text>
              </View>
            </View>
            {donor_views}
          </Page>
        </Document>
      </PDFViewer>
      </div>
      );
    }
}
export default DonationReport;
