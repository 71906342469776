import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';
import logo from './logo.svg';
import './App.css';
import BaseLayout from './components/common/BaseLayout';
import Login from './components/common/Login';
import IdleTimer from 'react-idle-timer';

@inject('userStore')
@observer
class App extends Component {
  constructor(props) {
   super(props)
   this.idleTimer = null
  }

  handleOnIdle = (event) => {
    this.props.userStore.logout();
    window.location.href = '/';
  }

  render(){
    if (this.props.userStore.token !== '') {
      return (
        <>
          <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              timeout={1000 * 60 * 15}
              onIdle={this.handleOnIdle}
              debounce={250}
            />
          <BaseLayout/>
        </>
      );
    } else {
      return (
        <Login/>
      )
    }
  }
}
// function App() {
//   //
//   // connectionGet('/users', {param:'1'}, null)
//   // .then((res)=>{console.log(res)})
//   // .catch((error)=>{console.log('error')});
//   //
//
//   // connectionPut('/users/1', {first_name:'Andrew2'}, null)
//   // .then((res)=>{console.log(res)})
//   // .catch((error)=>{console.log('error')});
//   //
//
//   return (
//     <BaseLayout/>
//   );
// }

export default App;
