import React, { Component } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

class UserRolesQuickview extends Component {
  constructor(props){
    super(props);
    this.state = {
      user_role:{id:null, name:'', description:'', children:[]}
    }
  }

  componentDidMount(){
    if (this.props.user_role !== null){
      this.setState({
        user_role:this.props.user_role,
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.addHandler(this.state.user_role);
  }

  handleSubmitAdd = () => {
    this.props.addHandler(this.state.user_role);
    this.setState({
      user_role:{id:null, name:'', description:'', children:[]}
    })
  }

  handleInputChange = (e) => {
    let updated_obj = Object.assign({}, this.state.user_role);
    const value = e.target.value;
    updated_obj[e.target.name] = value;
    this.setState({user_role:updated_obj});
  }

  handleChildInputChange = (e, user_role_permission) => {
    let updated_obj = Object.assign({}, this.state.user_role);
    let value = e.target.value;
    // attempt to find the object in the list of children
    let current_permission = updated_obj.children.find(su => su.sys_permission_id === user_role_permission.sys_permission_id);
    if (e.target.name === 'sys_permission_id'){
      if (e.target.checked && !current_permission){
        updated_obj.children.push(user_role_permission)
      } else {
        // var index = updated_obj.role_list.indexOf(su => su.sys_user_role_id === role_value);
        var index = updated_obj.children.map(function(e) { return e.sys_permission_id; }).indexOf(current_permission.sys_permission_id);
        if (index !== -1) updated_obj.children.splice(index, 1);
      }
    } else {
      current_permission[e.target.name] = value;
    }
    this.setState({user_role:updated_obj});
  }


  render() {
    if (!this.state.user_role) return <div>Loading</div>;
    const {closeHandler, sys_permission_list} = this.props;
    const {user_role} = this.state;
    return (
        <PerfectScrollbar>
        <div className="quickview-body">
          <div className="card card-inverse mb-0 cursor-pointer" onClick={closeHandler}>
            <div className="card-body text-center pb-10">
              <h4 className="mt-2 mb-0"><a className="hover-primary text-white" href="#">{!user_role.id ? 'Add Recipe' : user_role.name}</a></h4>
            </div>
          </div>
          <div className="quickview-block">
            <form className="card form-type-combine" id="user_role-form" data-provide="validation" noValidate={false} onSubmit={this.handleSubmit}>
              <div className="form-groups-attached">
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Name</label>
                    <input required className="form-control" type="text" name="name" onChange={this.handleInputChange} value={user_role.name}/>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-12">
                    <label className="required">Description</label>
                    <input required className="form-control" type="text" name="description" onChange={this.handleInputChange} value={user_role.description}/>
                  </div>
                </div>
                {user_role.id ?
                <React.Fragment>
                    {sys_permission_list ? sys_permission_list.map((sys_permission, man_index)=>{
                      let checked=false;
                      let user_role_permission = {id:null, sys_user_role_id:null, permission_level:'', sys_permission_id:sys_permission.id};
                      let exists = false;
                      if (user_role.children && user_role.children.length > 0){
                        exists =  user_role.children.find(su => su.sys_permission_id === sys_permission.id);
                        if (exists){
                          user_role_permission = exists;
                        }
                      }
                      if (exists){
                        checked = true;
                      } else {
                        checked = false;
                      }
                      return (
                        <div className="row" key={sys_permission.id}>
                          <div className="form-group col-8">
                            <div className="form-check" key={sys_permission.id}>
                              <input className="form-check-input" type="checkbox" name="sys_permission_id" onChange={(e)=>this.handleChildInputChange(e, user_role_permission)} value={sys_permission.id} id={sys_permission.id} checked={checked}/>
                              <label className="form-check-label" htmlFor={sys_permission.id}>{sys_permission.section} :: {sys_permission.sub_section}{sys_permission.task ? ' :: ' + sys_permission.task : null}</label>
                            </div>
                          </div>
                          <div className="form-group col-4">
                            <select className="form-control" name="permission_level" onChange={(e)=>this.handleChildInputChange(e, user_role_permission)} value={user_role_permission ? user_role_permission.permission_level : ''}>
                              <option value="">Select</option>
                              <option value="1">View</option>
                              <option value="2">Full</option>
                              <option value="3">Super</option>
                            </select>
                          </div>
                        </div>
                      );
                    }) : null}
                </React.Fragment>
                : null}
              </div>
            </form>
          </div>
        </div>
        <footer className="p-12 text-center">
          <div className="btn-group btn-block btn-group-vertical">
            <button className="btn btn-lg btn-outline btn-block btn-success" form="user_role-form" type="submit"><i className="fa fa-fw fa-save"></i> Save</button>
            <button className="btn btn-lg btn-outline btn-block btn-info" onClick={closeHandler}><i className="fa fa-fw fa-close"></i> Close</button>
          </div>
        </footer>
        </PerfectScrollbar>
    )
  }
}

export default UserRolesQuickview;
