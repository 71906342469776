import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import HeaderNavLink from './HeaderNavLink';
import Gravatar from 'react-gravatar';
import Navigation from './Navigation';
import Routes from './Routes';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

@inject('userStore')
@observer
class BaseLayout extends Component {
    constructor(props){
        super(props);
        this.state = {
            sidebarVisible:false,
            folded:false,
            settingsVisible:false
        }
    }

    toggleNavigation = () => {
        this.setState({
            sidebarVisible:!this.state.sidebarVisible
        });
    }

    toggleFold = () => {
        this.setState({
            folded:!this.state.folded
        });
    }

    render() {
      let year = new Date().getFullYear();
        if (this.state.sidebarVisible){
            document.body.classList.add('sidebar-open');
        } else {
            document.body.classList.remove('sidebar-open');
        }
        if (this.state.folded){
            document.body.classList.add('sidebar-folded');
        } else {
            document.body.classList.remove('sidebar-folded');
        }
        return (
            <React.Fragment>
            <aside className="sidebar sidebar-expand-lg sidebar-light sidebar-sm sidebar-color-info">
              <header className="sidebar-header bg-lightest">
                {this.state.folded ? <a className="logo-icon" href="/"><i className="fa fa-cog" style={{color:"#fd89d7"}}/></a> : null}
                <span className="logo">
                  <img src={require('../../img/rebiotix.logo2.png')} style={{height:40+'px'}}/>
                </span>
                <span className="sidebar-toggle-fold" onClick={this.toggleFold}></span>
              </header>

              <nav className="sidebar-navigation ps-container ps-theme-default" data-ps-id="fa07fbf6-40a3-6ba4-db2d-3c8b0bafd09b">
                <PerfectScrollbar>
                <ul className="menu menu-sm menu-bordery">
                  <Navigation/>
                </ul>
                </PerfectScrollbar>
                <div className="ps-scrollbar-x-rail" style={{left: 0+'px', bottom: 0+'px'}}>
                    <div className="ps-scrollbar-x" tabIndex="0" style={{left: 0+'px', width: 0+'px'}}></div>
                </div>
                <div className="ps-scrollbar-y-rail" style={{top: 0+'px', right: 2+'px'}}>
                    <div className="ps-scrollbar-y" tabIndex="0" style={{top: 0+'px', height: 0+'px'}}></div>
                </div>
              </nav>
            </aside>
            <header className="topbar">
              <div className="topbar-left">
                <span className="topbar-btn sidebar-toggler" onClick={this.toggleNavigation}><i>&#9776;</i></span>
                <a className="logo d-lg-none" href="/"><img src={require('../../img/rebiotix.logo2.png')} alt="Rebiotix"/></a>
              </div>

              <div className="topbar-right">
                <ul className="topbar-btns">
                  <li className="dropdown">
                    <span className="topbar-btn" data-toggle="dropdown"><Gravatar email={this.props.userStore.email} size={36} rating="pg" default="identicon" className="avatar" /></span>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="/account-settings"><i className="ti-settings"></i> Settings</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="/" onClick={()=>{this.props.userStore.logout();}}><i className="ti-power-off"></i> Logout</a>
                    </div>
                  </li>
                </ul>
                <span>{this.props.userStore.full_name}</span>
              </div>
            </header>
            {this.state.sidebarVisible ? <div className="app-backdrop backdrop-sidebar" onClick={this.toggleNavigation}/> : null}
            <main className="main-container">
              <Routes/>
                <footer className="site-footer">
                  <div className="row">
                    <div className="col-md-6">
                      <p className="text-center text-md-left">Copyright © {year} <a href="https://rebiotix.com">Rebiotix</a>. All rights reserved.</p>
                    </div>
                    <div className="col-md-6">
                      <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
                        <li className="nav-item">
                          DB Version: {this.props.userStore.db_version ? this.props.userStore.db_version : 'reauthenticate to view version'}
                        </li>
                      </ul>
                    </div>
                  </div>
                </footer>
            </main>
            </React.Fragment>
        );
    }
}

export default BaseLayout;
