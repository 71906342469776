import React, { Component } from 'react';
import Modal from 'react-bootstrap4-modal';
import KitsModal from './KitsModal';
import {Spinner} from 'reactstrap';
import DrugSubstanceQuickview from './DrugSubstanceQuickview'
import { inject } from 'mobx-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connectionGet, connectionPost, connectionPut } from '../../utils/connection';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

let toastParams = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: false,
};

@inject('userStore')
class Kits extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false,
        modalVisible:false,
        modalComponent:null,
        statusFilter:'all',
        recipe:null,
        kit:null,
        kit_list:[],
        kit_listOriginal:[],
        searchName:'',
        sys_bom_status_list:[],
        loading:true,
        quickviewVisible:false,
        sys_disposition_list:[],
        ds:null
      }
  }

  componentDidMount(){
    this.loadManufacturingKits();
  }

  loadManufacturingKits = () => {
    this.setState({
      loading:true
    },()=>{
      connectionGet('boms/1', {}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          this.setState({
            loading:false,
            recipe:res.data.recipe,
            kit_list:res.data.kit_list,
            kit_listOriginal:res.data.kit_list,
            sys_bom_status_list:res.data.sys_bom_status_list,
            sys_disposition_list:res.data.sys_disposition_list
          })
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    });
  }

  disposition = (kit) => {
    if (kit.product_hash_id){
      delete kit['expiration_date'];
      delete kit['manufacture_date'];
      delete kit['children'];
      delete kit['create_timestamp'];
      delete kit['last_modified'];
      connectionPut('boms/1/' + kit.product_hash_id, {bom:kit}, {username: this.props.userStore.token})
      .then((res)=>{
        if (res.data.errors === 0){
          toast.success(res.data.message, toastParams);
          this.closeQV();
          this.loadManufacturingKits();
        } else {
          toast.error(res.data.message, toastParams);
        }
      })
      .catch((error)=>{toast.error(error.response.statusText, toastParams);});
    }
  }


  addManufacturingKits = (kit, batch_record) => {
    if (kit.planned_sub_size > 0){
      if (kit.children){
        kit.children.map((child, i)=>{
          if (child.expiration_date){
            child.expiration_date = moment(child.expiration_date).format('YYYY-MM-DD')
          }
        })
      }
      if (kit.expiration_date){
        kit.expiration_date = moment(kit.expiration_date).format('YYYY-MM-DD')
      }
      if (kit.product_hash_id){
        connectionPut('boms/1/' + kit.product_hash_id, {bom:kit}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.setState({modalVisible:!this.state.modalVisible, modalComponent:null}, ()=>this.loadManufacturingKits());
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      } else {
        connectionPost('boms/1', {bom:kit, batch_record:batch_record}, {username: this.props.userStore.token})
        .then((res)=>{
          if (res.data.errors === 0){
            toast.success(res.data.message, toastParams);
            this.setState({modalVisible:!this.state.modalVisible, modalComponent:null}, ()=>this.loadManufacturingKits());
          } else {
            toast.error(res.data.message, toastParams);
          }
        })
        .catch((error)=>{toast.error(error.response.statusText, toastParams);});
      }
    } else {
      alert(kit.unit + ' Must be greater than 0');
    }
  }

  closeHandler = () => {
    this.setState({
      modalVisible:!this.state.modalVisible,
      modalComponent:null
    })
  }

  openHandler = (kit) => {
    this.setState({
      modalVisible:true,
      modalComponent:<KitsModal addHandler={this.addManufacturingKits} closeHandler={this.closeHandler} kit={kit} sys_bom_status_list={this.state.sys_bom_status_list} recipe={this.state.recipe} isEdit={true}/>
    })
  }

  openModal = () => {
    this.setState({
      modalVisible:true,
      modalComponent:<KitsModal addHandler={this.addManufacturingKits} closeHandler={this.closeHandler} kit={null} sys_bom_status_list={this.state.sys_bom_status_list} recipe={this.state.recipe} isEdit={false}/>
    })
  }

  toggleFold = () => {
    this.setState({
      folded:!this.state.folded
    });
  }

  handleInputChange = (e) => {
    this.setState({
      searchName:e.target.value
    })
  }

  openQV = (ds) => {
    this.setState({
      quickviewVisible:true,
      ds:ds
    })
  }

  closeQV = () => {
    this.setState({
      quickviewVisible:false,
      ds:null
    })
  }

  render() {
    const {searchName, kit_list, loading, sys_disposition_list, ds} = this.state;
    const columns = [{
      dataField: 'batch_lot_number',
      text: 'Lot#'
    }, {
      dataField: 'name',
      text: 'Name',
      sort: false
    }, {
      dataField: 'expiration_date',
      text: 'Exp Date',
      sort: true,
      formatter: (cellContent, row)=>{
        if (row.expiration_date){
          return (
            <span>{moment(row.expiration_date).format('DDMMMYYYY')}</span>
          )
        }
      }
    }, {
      dataField: 'planned_sub_size',
      text: 'Dose Qty'
    }, {
      dataField: 'batch_size',
      text: 'Batch Qty'
    }, {
      dataField: 'available_quantity',
      text: 'Available',
    }, {
      dataField: 'dummy',
      isDummyField: true,
      text: 'Kits',
      formatter: (cellContent, row) => {
        return (
          <table className="table table-sm">
            <thead>
              <th>Kit</th>
              <th>Disposition</th>
              <th>Actions</th>
            </thead>
            <tbody>
            {row.kit_list ? row.kit_list.map((kit, kit_index)=>
              <tr key={kit_index}>
                <td>{kit.lot_number}</td>
                <td>{kit.disposition ? <small><span className={kit.disposition.sys_disposition_id === 1 ? "badge badge-success" : "badge badge-danger"}>{kit.disposition.sys_disposition}</span></small> : null}
                </td>
                <td>
                  {this.props.userStore.hasPermission('manufacturing.kits', 2) ?
                  <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(kit)}><i className="ti-pencil"></i></a>
                  : null}
                  {this.props.userStore.hasPermission('manufacturing.kits', 2) ?
                  <a href="#" className="table-action hover-primary ml-10" onClick={()=>this.openQV(kit)}>QA</a>
                  : null}
                  {this.props.userStore.hasPermission('manufacturing.kits', 3) ?
                    <a className="table-action hover-primary ml-10" title="View Audit" href={"/audit/BOMProduct/" + row.id + "/bom_product_id" } target="_blank"><i className="ion-clipboard"></i></a>
                  :null}
                </td>
              </tr>
            ) : null}
            </tbody>
          </table>
        )
      }
    }];
    return (
      <React.Fragment>
        <Modal visible={this.state.modalVisible} onClickBackdrop={this.closeHandler} dialogClassName="modal-lg">
          {this.state.modalComponent ? this.state.modalComponent : <div></div>}
        </Modal>
        <aside className="aside aside-expand-md">
          <div className="aside-body">
            <form className="aside-block form-type-line">
              <h2>Manufacturing Kits</h2>
              <div className="btn-group-vertical">
              {this.props.userStore.hasPermission('manufacturing.kits', 2) ?
              <button className="btn btn-sm btn-success btn-block" type="button" onClick={this.openModal}><i className="fa fa-plus"/> Add Kit</button>
              : null}
              <button className="btn btn-sm btn-primary mt-10" type="button" onClick={this.loadManufacturingKits}><i className="fa fa-refresh"/> Refresh</button>
              </div>
            </form>
          </div>
          <button className="aside-toggler"></button>
        </aside>
        <div className="main-content">
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnVisibilityChange={false}
            draggable={false}
            pauseOnHover={false}
          />
          <div className="card p-10">
          {loading ?
            <div className="card-body">
              <h4><Spinner color="primary"/> Loading...</h4>
            </div>
          :
            <BootstrapTable keyField='batch_lot_number' data={kit_list} columns={columns} pagination={paginationFactory()} striped hover headerClasses="thead-light"/>
          }
          </div>
          <br/>
          <div className={this.state.quickviewVisible ? "quickview quickview-lg reveal" : "quickview quickview-lg"}>
            {this.state.quickviewVisible ?
              <DrugSubstanceQuickview addHandler={this.disposition} closeHandler={this.closeQV} ds={ds} sys_disposition_list={sys_disposition_list}/>
              :<div>No DS Loaded</div>
            }
          </div>
        </div>
      </React.Fragment>
      );
    }
}
export default Kits;


// <table className="table table-striped table-hover">
//   <thead>
//     <tr>
//       <th width="10%">Lot#</th>
//       <th width="10%">Donor Id</th>
//       <th width="15%">Donation Date</th>
//       <th width="15%">Expiration Date</th>
//       <th width="10%">Weight</th>
//       <th width="20%">Status</th>
//       <th width="15%">PS Assignment</th>
//       <th></th>
//     </tr>
//   </thead>
//   <tbody>
// {kit_list ? kit_list.map((kit, index)=>
//     <tr key={index}>
//       <td>{kit.rbx_lot_number}</td>
//       <td>{kit.rbx_donor_id}</td>
//       <td>{moment(kit.donation_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{moment(kit.expiration_date).utc().format('MM/DD/YYYY')}</td>
//       <td>{kit.weight}</td>
//       <td>{kit.status.sys_kit_status}</td>
//       <td>{kit.pooled_sample_number ? kit.pooled_sample_number : 'NA'}</td>
//       <td className="text-right table-actions">
//         <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(kit)}><i className="ti-pencil"></i></a>
//         <a className="table-action hover-primary" href="#" onClick={()=>this.openHandler(kit)}><i className="ti-menu-alt"></i></a>
//       </td>
//     </tr>
// ) : <tr><td colSpan="5">No ManufacturingKits Found</td></tr>}
//   </tbody>
// </table>

// ,{
//   dataField: 'lot_number',
//   text: 'Sublot #'
// }
