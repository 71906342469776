import React, { Component } from 'react';
import {Doughnut} from 'react-chartjs-2';

class DonutChart extends Component {
  render() {
      var lineChartData = {
        datasets: [{
          backgroundColor: [
                "#2ecc71",
                "#3498db",
                "#ef476f",
                "#9b59b6",
                "#06d6a0",
                "#95a5a6"
          ],
          data: this.props.data
        }],
        labels: this.props.label
      };
      var chartOptions = {
            responsive: true,
            hoverMode: 'index',
            stacked: false,
            title: {
              display: true,
              text: this.props.name
            }
        };
        if (this.props.hideTitle == true){
            chartOptions.title.display = false;
        }
    return (
    	<Doughnut data={lineChartData} options={chartOptions} />
    );
  }
}

export default DonutChart;
