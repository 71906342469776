import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image, Font } from '@react-pdf/renderer';

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

Font.register({
  family: "Cousine",
  src: require('../../img/Cousine-Regular.ttf')
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    flexGrow:0,
    fontFamily : "Cousine"
  },
  pageArea:{
    flexDirection: 'row'
  },
  signSection: {
    paddingTop:40,
    paddingBottom: 10,
    flexGrow: 1,
    flexDirection: 'row',
    width:'100%',
    textAlign:'center'
  },
  checkSection: {
    textAlign:'center',
    marginBottom:10,
    flexDirection:'row',
    marginTop:0
  },
  section: {
    marginLeft: 10,
    marginRight:10,
    marginTop:10,
    paddingBottom: 0,
    flexGrow: 1,
    flexDirection: 'column'
  },
  p: {
    fontSize:10
  },
  heading: {
    fontSize:25,
    marginTop:25+'px'
  },
  address: {
    fontSize:15,
    marginTop:35+'px'
  },
  address2: {
    fontSize:10,
    marginTop:5+'px'
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginBottom:'10px'
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableRowHeading: {
    backgroundColor:'#e5e5e5',
    margin: "auto",
    flexDirection: "row"
  },
  tableColFooter1: {
    width: "70%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign:'left'
  },
  tableCol1: {
    width: "25%",
    backgroundColor:'#e5e5e5',
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol2: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol01: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol03: {
    width: "32%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol02: {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol3: {
    width: "20%",
    borderStyle: "solid",
    backgroundColor:'#e5e5e5',
    borderWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol4: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol25: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol50:{
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth:0,
    borderTopWidth:0,
    borderRightWidth:1
  },
  tableCol8: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol11: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol18: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol201: {
    width: "15%",
    borderStyle: "solid",
    backgroundColor:'#e5e5e5',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol202: {
    width: "5%",
    borderStyle: "solid",
    backgroundColor:'#fff',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCol151: {
    width: "10%",
    borderStyle: "solid",
    backgroundColor:'#e5e5e5',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:0
  },
  tableCol152: {
    width: "5%",
    borderStyle: "solid",
    backgroundColor:'#fff',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderRightWidth:1
  },
  tableCellTitle: {
    textAlign:"right",
    textTransform:'uppercase',
    padding: 2,
    marginTop: 5,
    fontSize: 10
  },
  tableCell: {
    textAlign:"left",
    textTransform:'uppercase',
    padding: 2,
    marginTop: 5,
    fontSize: 10,
    fontFamily : "Cousine"
  },
  tableCellCenter: {
    textAlign:"center",
    textTransform:'uppercase',
    padding: 2,
    marginTop: 5,
    fontSize: 10
  },
  tableCellQuantity: {
    textAlign:"right",
    padding: 2,
    marginTop: 5,
    fontSize: 10
  },
  itemRow:{
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin:5
  },
  itemRowHeading:{
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin:5,
    fontWeight:'bold',
    borderBottom: 1+'pt'
  },
  item: {
    fontSize: 10+'pt'
  }
});

@inject('userStore')
class COAPrint extends Component {
  constructor(props){
      super(props);
      this.state = {
        folded:false
      }
  }

  componentDidMount(){
  }

  render() {
    return (
      <div style={{height:1000+'px'}}>
      <PDFViewer width={100+'%'} height={100+'%'}>
        <Document>
          <Page size="A4" style={styles.page} orientation="landscape">
            <View style={styles.pageArea}>
              <View style={styles.section}>
                <Image src={require('../../img/rebiotix.logo.png')} style={{marginTop:0+'px', marginLeft:0+'px', width:100+'px', height:50+'px'}}/>
              </View>
              <View style={styles.section}>
                <Text style={styles.heading}>Certificate of Analysis</Text>
              </View>
              <View style={styles.section}>
                <Text style={styles.address}>Rebiotix, Inc.</Text>
                <Text style={styles.address2}>2660 Patton Road Roseville, MN 55113</Text>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCellTitle}>Product:</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Microbiota Suspension</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCellTitle}>Batch Number:</Text>
                  </View>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>#####=B####</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                    <Text style={styles.tableCellTitle}>Date of Manufacture:</Text>
                  </View>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>DDMMMYY</Text>
                  </View>
                  <View style={styles.tableCol3}>
                    <Text style={styles.tableCellTitle}>Expiration Date:</Text>
                  </View>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>DDMMMYY</Text>
                  </View>
                </View>
                <View style={styles.tableRowHeading}>
                  <View style={styles.tableCol01}>
                    <Text style={styles.tableCell}>Test</Text>
                  </View>
                  <View style={styles.tableCol02}>
                    <Text style={styles.tableCell}>Test Method</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>Acceptance Criteria</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>Results</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol01}>
                    <Text style={styles.tableCell}>Bacteroides Species Growth Method</Text>
                  </View>
                  <View style={styles.tableCol02}>
                    <Text style={styles.tableCellCenter}>8018</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>Growth Observed</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>Growth Observed</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol01}>
                    <Text style={styles.tableCell}>Viable Baterial Count Method</Text>
                  </View>
                  <View style={styles.tableCol02}>
                    <Text style={styles.tableCellCenter}>8014</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>1.0</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>#.#</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol01}>
                    <Text style={styles.tableCell}>Diversity Method</Text>
                  </View>
                  <View style={styles.tableCol02}>
                    <Text style={styles.tableCellCenter}>8014</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>{"≤"} 3 colony</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}># Colony</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol01}>
                    <Text style={styles.tableCell}>Appearance Method</Text>
                  </View>
                  <View style={styles.tableCol02}>
                    <Text style={styles.tableCellCenter}>8045</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>Opaque Suspension</Text>
                  </View>
                  <View style={styles.tableCol03}>
                    <Text style={styles.tableCell}>Opaque Suspension</Text>
                  </View>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRowHeading}>
                  <View style={styles.tableCol50}>
                    <Text style={styles.tableCell}>Ongoing Blod Pathogen Testing</Text>
                  </View>
                  <View style={styles.tableCol201}>
                    <Text style={styles.tableCellTitle}>Specification:</Text>
                  </View>
                  <View style={styles.tableCol202}>
                    <Text style={styles.tableCell}>7302</Text>
                  </View>
                  <View style={styles.tableCol151}>
                    <Text style={styles.tableCellTitle}>Revision:</Text>
                  </View>
                  <View style={styles.tableCol152}>
                    <Text style={styles.tableCell}>###</Text>
                  </View>
                  <View style={styles.tableCol151}>
                    <Text style={styles.tableCellTitle}>Donor #:</Text>
                  </View>
                  <View style={styles.tableCol152}>
                    <Text style={styles.tableCell}>#####</Text>
                  </View>
                </View>
                <View style={styles.tableRowHeading}>
                  <View style={styles.tableCol18}>
                    <Text style={styles.tableCell}>Test Code</Text>
                  </View>
                  <View style={styles.tableCol8}>
                    <Text style={styles.tableCell}>Req#</Text>
                  </View>
                  <View style={styles.tableCol11}>
                    <Text style={styles.tableCell}>Collection Date</Text>
                  </View>
                  <View style={styles.tableCol11}>
                    <Text style={styles.tableCell}>End Date</Text>
                  </View>
                  <View style={styles.tableCol25}>
                    <Text style={styles.tableCell}>Acceptance Criteria</Text>
                  </View>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>Results</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol18}>
                    <Text style={styles.tableCell}>TREPT, HAABG, HBSAG, HCVAB, HIAGAB</Text>
                  </View>
                  <View style={styles.tableCol8}>
                    <Text style={styles.tableCell}>#####</Text>
                  </View>
                  <View style={styles.tableCol11}>
                    <Text style={styles.tableCell}>DDMMMYY</Text>
                  </View>
                  <View style={styles.tableCol11}>
                    <Text style={styles.tableCell}>DDMMMYY</Text>
                  </View>
                  <View style={styles.tableCol25}>
                    <Text style={styles.tableCell}>All tests meet</Text>
                  </View>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>All tests meet</Text>
                  </View>
                </View>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRowHeading}>
                  <View style={styles.tableCol50, {width:'40%', borderRightWidth:1, borderBottomWidth:1}}>
                    <Text style={styles.tableCell}>Donor Human Stool Pathogen Testing</Text>
                  </View>
                  <View style={styles.tableCol50, {width:'60%', borderBottomWidth:1}}>
                    <View style={styles.table, {marginBottom:0, width:'100%', borderBottomWidth:1, borderRightWidth:1}}>
                      <View style={styles.tableRowHeading}>
                        <View style={styles.tableCol201, {width:'25%'}}>
                          <Text style={styles.tableCellTitle}>Specification:</Text>
                        </View>
                        <View style={styles.tableCol202, {width:'25%', backgroundColor:'#fff', borderRightWidth:1}}>
                          <Text style={styles.tableCell}>7306</Text>
                        </View>
                        <View style={styles.tableCol151, {width:'20%'}}>
                          <Text style={styles.tableCellTitle}>Revision:</Text>
                        </View>
                        <View style={styles.tableCol152, {width:'30%', backgroundColor:'#fff'}}>
                          <Text style={styles.tableCell}>###</Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.table, {marginBottom:0, width:'100%', borderRightWidth:1}}>
                      <View style={styles.tableRowHeading}>
                        <View style={styles.tableCol201, {width:'25%'}}>
                          <Text style={styles.tableCellTitle}>Lot #:</Text>
                        </View>
                        <View style={styles.tableCol202, {width:'25%', backgroundColor:'#fff', borderRightWidth:1}}>
                          <Text style={styles.tableCell}>#####-#####</Text>
                        </View>
                        <View style={styles.tableCol151, {width:'20%'}}>
                          <Text style={styles.tableCellTitle}>Donation Date:</Text>
                        </View>
                        <View style={styles.tableCol152, {width:'30%', backgroundColor:'#fff'}}>
                          <Text style={styles.tableCell}>DDMMMYY</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRowHeading}>
                  <View style={styles.tableCol01, {width:'35%', borderBottomWidth:1, borderRightWidth:1}}>
                    <Text style={styles.tableCell}>Test Code</Text>
                  </View>
                  <View style={styles.tableCol02, {width:'15%', borderBottomWidth:1, borderRightWidth:1}}>
                    <Text style={styles.tableCell}>Pooled Sample #</Text>
                  </View>
                  <View style={styles.tableCol25}>
                    <Text style={styles.tableCell}>Acceptance Criteria</Text>
                  </View>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>Results</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol01, {width:'35%', borderBottomWidth:1, borderRightWidth:1}}>
                    <Text style={styles.tableCell}>STSP, CDBPCR. EPASS, VREC, CRYGI</Text>
                  </View>
                  <View style={styles.tableCol02, {width:'15%', borderBottomWidth:1, borderRightWidth:1}}>
                    <Text style={styles.tableCell}>#####-R####A</Text>
                  </View>
                  <View style={styles.tableCol25}>
                    <Text style={styles.tableCell}>All Tests</Text>
                  </View>
                  <View style={styles.tableCol4}>
                    <Text style={styles.tableCell}>All Tests</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.checkSection}>
              <View style={{width:'25%', flexDirection:'row-reverse', marginRight:5}}>
                <View style={{border:1, width:10, height:10, textAlign:'right'}}></View>
              </View>
              <Text style={styles.p, {textAlign:'left', width:'75%', fontSize:10}}>This batch meets the requirements as specified in applicable procedures and specifications</Text>
            </View>
            <View style={styles.checkSection, {textAlign:'center', marginBottom:10, flexDirection:'row'}}>
              <View style={{width:'25%', flexDirection:'row-reverse', marginRight:5}}>
                <View style={{border:1, width:10, height:10, textAlign:'right'}}></View>
              </View>
              <Text style={styles.p, {textAlign:'left', width:'75%', fontSize:10}}>This batch <Text style={{ fontSize: 11 }}>DOES NOT</Text> meet the requirements as specified in applicable procedures and specifications</Text>
            </View>
            <View style={styles.signSection}>
              <View style={{width:'50%'}}>
                <View style={{width:'70%', borderBottomWidth:1, marginLeft:60+'px'}}></View>
                <View><Text style={styles.p}>Prepared By/Date</Text></View>
              </View>
              <View style={{width:'50%'}}>
                <View style={{width:'70%', borderBottomWidth:1, marginLeft:60+'px'}}></View>
                <View><Text style={styles.p}>Quality Approval/Date</Text></View>
              </View>
            </View>
            <View style={styles.pageArea}>
              <View style={styles.section, {fontSize:8, width:'45%', marginLeft:10}}>
                <Text>Form 8012-3 Rev 002</Text>
                <Text>Effective Date: 22OCT19</Text>
              </View>
              <View style={styles.section, {width:'55%', textAlign:'left'}}>
                <Text style={{fontSize:12}}>Confidential</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
      </div>
      );
    }
}
export default COAPrint;
