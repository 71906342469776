import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import { inject, observer } from 'mobx-react';
import HeaderNavLink from './HeaderNavLink';
import nav from './Constants';
const HeaderNavLinkWithRouter = withRouter(HeaderNavLink);

@inject('userStore')
class Navigation extends Component {

  // this should be based on a logged in user's roles
  render() {
    return (
      <React.Fragment>
      {nav.map((link, link_index)=> {
          if (this.props.userStore.hasPermission(link.id, 1)){
            return (
              <React.Fragment key={link_index}>
              <li className="menu-category">{link.name}</li>
              <ul className="menu menu-sm menu-bordery mb-0">
              {link.links.length > 0 ? link.links.map((sub_link, sub_link_index)=> {
                if (this.props.userStore.hasPermission(sub_link.id, 1)){
                  return (<HeaderNavLinkWithRouter key={sub_link_index} to={sub_link.to} className="menu-link" icon={sub_link.icon} linkname={sub_link.name}/>)
                }
              }) : null}
              </ul>
              </React.Fragment>
            );
          }
      })}
      </React.Fragment>
    );
  }
}

export default Navigation;

// <HeaderNavLinkWithRouter to='/donors/attendance-log' className="menu-link" icon="fa fa-calendar-check-o" linkname="Attendance Log"/>
// <HeaderNavLinkWithRouter to='/dhs-status' className="menu-link" icon="fa fa-cube" linkname="Status Log"/>
// <HeaderNavLinkWithRouter to='/donation-report' className="menu-link" icon="fa fa-cube" linkname="Donation Report"/>
